import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { TableCustom } from "../component/table-custom/TableCustom";
import { TransitionInFo } from "./models/transitionModel";
import { TransitionColumn } from "./component/TransitionColumn";
import { TransitionDialog } from "./component/TransitionDialog";
type Props = {};

const Transition = (props: Props) => {
  const intl = useIntl();
  const transitionInfoArray: TransitionInFo[] = [
    {
      id: "1",
      fullName: "John Doe",
      code: "ABC123",
      donViCongTac: "Department A",
      viTriCongViec: "Position A",
      donViCongTacMoiId: "2",
      donViCongTacMoiText: "Department B",
      viTriCongTacMoiId: "3",
      viTriCongTacMoiText: "Position C",
      soQuyetDinh: "QD123",
      transferStatus: "Pending",
      fileName: "file1.pdf",
    },
    {
      id: "2",
      fullName: "Jane Smith",
      code: "DEF456",
      donViCongTac: "Department X",
      viTriCongViec: "Position Y",
      donViCongTacMoiId: "4",
      donViCongTacMoiText: "Department Z",
      viTriCongTacMoiId: "5",
      viTriCongTacMoiText: "Position W",
      soQuyetDinh: "QD789",
      transferStatus: "Approved",
      fileName: "file2.pdf",
    },
    // Add more elements as needed
  ];

  const [transition, setTransition] = useState<TransitionInFo>(
    {} as TransitionInFo
  );
  const [listTransition, setListTransition] =
    useState<TransitionInFo[]>(transitionInfoArray);
  const [shouldOpenTransitionDialog, setShouldOpenTransitionDialog] =
    useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const handleCloseDialog = () => {
    setShouldOpenTransitionDialog(false);
  };
  const handleOpenDialog = () => {
    setShouldOpenTransitionDialog(true);
  };
  return (
    <>
      <div className="profile-title">
        <span className="fs-3 fw-bold">
          {intl.formatMessage({ id: "GENERAL.TRANSFER.LIST" })}
          <i className="bi bi-three-dots-vertical"></i>
        </span>
        <button
          onClick={handleOpenDialog}
          className="btn btn-primary btn-sm btn-sm"
        >
          {intl.formatMessage({ id: "BTN.ADD" })}
        </button>
      </div>
      <TableCustom<TransitionInFo>
        data={listTransition}
        columns={TransitionColumn}
        compact={false}
        handleOpenInfoDialog={handleOpenDialog}
        // handleDelete={handleDeleteTransition}
      />
      {shouldOpenTransitionDialog && (
        <TransitionDialog
          handleOpenTransitionDialog={handleOpenDialog}
          handleCloseTransitionDialog={handleCloseDialog}
          show={shouldOpenTransitionDialog}
          transition={transition}
        ></TransitionDialog>
      )}
    </>
  );
};

export default Transition;
