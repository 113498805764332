import { IListHangMuc } from "./app/modules/pos/models/PosModels";

export const URL_MENU = {
  ORDER: "order",
  PRODUCT: "product",
  POS: "pos",
};

export const initialValue = 0;
export const CODE_SUCCESS = 200;
export const ERROR_MESSAGE = "Có lỗi xảy ra, vui lòng thử lại!";
export const DELETE_SUCCESS = "Xóa thành công!";

export const listLoaiHangHoa: IListHangMuc = [
  { productType: "GOODS-NG", productTypeName: "Nước giặt" },
  { productType: "GOODS-NX", productTypeName: "Nước xả" },
];

export const listLoaiDichVu: IListHangMuc = [
  { productType: "SERVICE-GU", productTypeName: "Giặt ướt" },
  { productType: "SERVICE-GK", productTypeName: "Giặt khô" },
  { productType: "SERVICE-GUTH", productTypeName: "Giặt ủi tổng hợp" },
  { productType: "SERVICE-GDB", productTypeName: "Giặt đồ baby" },
  { productType: "SERVICE-VADD", productTypeName: "Vest, Áo dài, Đầm" },
  { productType: "SERVICE-GTVGB", productTypeName: "Giày, Túi, Vali, Gấu bông" },
  { productType: "SERVICE-SQA", productTypeName: "Sửa quần áo" },
  { productType: "SERVICE-SGTX", productTypeName: "Sửa giày, túi xách" },
  { productType: "SERVICE-SMGSDN", productTypeName: "Sửa máy giặt, sấy, điện nước" },
  { productType: "SERVICE-VSSTD", productTypeName: "Vệ sinh sofa, Thảm, Đệm, ..." },
];

export const MESSAGE_SUCCESS = {
  general: "Thành công",
  addDonHang: "Tạo đơn hàng thành công",
  updateDonHang: "Cập nhật đơn hàng thành công",
  deleteDonHang: "Xóa đơn hàng thành công",
  addProductToCart: "Thêm sản phẩm thành công",
  addProductToCartWarning: "Sản phẩm đã có trong giỏ hàng",
  createCuaHangDaiLy: "Tạo thành công",
};

export const LOCAL_STORAGE_KEYS = {
  AUTHORITIES: "authorities",
  CURRENT_USER: "current_user",
  NAVIGATION_LOCAL: "navigation_local",
};

export const SESSION_STORAGE_KEYS = {
  AUTHORITIES: 'authorities',
  CURRENT_USER: 'current_user',
  NAVIGATION_LOCAL: 'navigation_local',
}

export const ROLES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_LAUNDRY: "ROLE_LAUNDRY",
  ROLE_AGENCY: "ROLE_AGENCY",
};

export const MILLISECONDS = 15000;
export const TIMEDELAY = 500;
