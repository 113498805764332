import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  formatDateHoaDon,
  formatPrice,
  totalPriceService,
} from "../../utils/FunctionUtils";
import { initialValue } from "../../../../constants";
import { JUSTIFY_CONTENT, STATUS_PAY, styles } from "../const/OrderConst";

type Props = {
  handleCloseDialog: () => void;
  donHang?: any;
};

const HoaDonDialog = (props: Props) => {
  const { handleCloseDialog, donHang } = props;
  const printRef = useRef(null);
  const isHidden = donHang?.lstProduct?.[0]?.attributeTypes === null;

  const totalPriceSanPham = donHang?.lstProduct?.reduce(
    (total: number, item: any) => {
      return total + item?.product?.totalPrice;
    },
    initialValue
  );

  const style = `
  @media print {
    @page {
      margin: 0px;
    }
  }`;

  const handleInPhieuHoaDon = () => {
    let content = document.getElementById("divcontents");
    let pri = (document.getElementById("ifmcontentstoprint") as any)
      .contentWindow;
    pri.document.open();

    pri.document.write("<style>" + style + "</style>");
    pri.document.write((content as any).innerHTML);

    pri.document.close();
    pri.focus();
    setTimeout(() => {
      pri.print();
    }, 500);
  };

  return (
    <Modal show={true} onHide={handleCloseDialog} centered >
      <div>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Hóa đơn
          </Modal.Title>
        </Modal.Header>

        <Modal.Body ref={printRef} style={styles.body}>
          <iframe
            id="ifmcontentstoprint"
            style={{
              height: "0px",
              width: "0px",
              position: "absolute",
            }}
          ></iframe>

          <div id="divcontents">
            <div className="fs-3 text-end"
              style={{
                ...styles.font_family,
                ...styles.fw_payment(donHang?.orderDto?.paymentName === STATUS_PAY.PAID.name ? STATUS_PAY.PAID.name : STATUS_PAY.UN_PAID.name),
                ...styles.fs_16,
                ...styles.mb_5,
                ...styles.text_right,
                ...styles.borderDottedBottom
              }}
            >
              <span style={{
                border: "2px solid",
                padding: "2px"
              }}>{donHang?.orderDto?.paymentName}</span>
            </div>

            <div className="mb-3 min-h-85px" style={{ minHeight: "70px", position: "relative" }}>
              <div className="order_logo" style={styles.logoGh}>
                <img
                  style={{ width: "100%" }}
                  src="/media/logos/logoGoodHere2.svg"
                  alt=""
                />
              </div>

              <div className="fw-bold" style={styles.detailCuaHang as any}>
                <div
                  className="fs-3"
                  style={{
                    ...styles.font_family,
                    ...styles.fw_bold,
                    ...styles.fs_16,
                    ...styles.mb_5,
                  }}
                >
                  {donHang?.lstProduct?.[0]?.product?.orgDto?.name}
                </div>
                <div
                  className="fs-5"
                  style={{
                    ...styles.font_family,
                    ...styles.mb_5,
                    ...styles.fs_13,
                  }}
                >
                  {donHang?.address}
                </div>
                <div
                  className="fs-5"
                  style={{
                    ...styles.font_family,
                    ...styles.mb_5,
                    ...styles.fs_13,
                  }}
                >
                  SĐT: {donHang?.phone}
                </div>
              </div>
            </div>

            <div style={styles.detailKhachHang as any}>
              <div
                className="fs-6"
                style={{
                  ...styles.font_family,
                  ...styles.fs_13,
                  ...styles.d_flex(JUSTIFY_CONTENT.BETWEEN),
                }}
              >
                <div>
                  <span>KH:</span> {donHang?.orderDto?.customerName}
                </div>
                <div style={{ ...styles.min_w_130, ...styles.text_left }}>
                  SĐT: {donHang?.orderDto?.phoneNumber}
                </div>
              </div>

              <div
                className="fs-6"
                style={{
                  ...styles.font_family,
                  ...styles.fs_13,
                  ...styles.d_flex(JUSTIFY_CONTENT.BETWEEN),
                }}
              >
                <div>
                  <span>Mã đơn: {donHang?.orderDto?.code}</span>
                </div>
                <div style={{ ...styles.min_w_130, ...styles.text_left }}>
                  {formatDateHoaDon(donHang?.orderDto?.createDate)}
                </div>
              </div>
            </div>

            <div style={styles.rowTable}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr
                    className="fs-6"
                    style={{
                      ...styles.font_family,
                      ...styles.mb_5,
                      ...styles.fs_10,
                      ...styles.border_bottom_ddd,
                      ...styles.py_6,
                    }}
                  >
                    <th className="text-center" style={{ ...styles.py_6 }}>
                      STT
                    </th>
                    <th style={{ ...styles.ps_15, ...styles.text_left }}>
                      MENU DỊCH VỤ
                    </th>
                    {isHidden && (
                      <th className="text-center" style={{ ...styles.py_6 }}>
                        SL
                      </th>
                    )}
                    <th className="text-center" style={{ ...styles.py_6 }}>
                      GIÁ
                    </th>
                  </tr>
                </thead>

                <tbody className="fs-5" style={styles.fs_13}>
                  {donHang?.lstProduct
                    ? donHang?.lstProduct.map((product: any, index: number) => {
                      return (
                        <tr key={index} style={styles.font_family}>
                          <td
                            className="text-center py-2"
                            style={{ ...styles.text_center, ...styles.py_3 }}
                          >
                            {index + 1}
                          </td>
                          <td className="py-2">
                            <div
                              style={{ ...styles.ps_15, ...styles.text_left }}
                            >
                              {product?.product?.name}{" "}
                              <div style={styles.fs_12}>
                                {product?.attributeTypes?.length > 0
                                  ? product?.attributeTypes?.map(
                                    (item: any, index: number) => {
                                      return item?.attributes?.map(
                                        (itemAtt: any, index: number) => {
                                          return (
                                            <div>
                                              {itemAtt?.quantity
                                                ? itemAtt?.quantity +
                                                " " +
                                                itemAtt?.unit
                                                : itemAtt?.name}
                                              :{" "}
                                              <span>
                                                {formatPrice(
                                                  itemAtt?.totalPrice
                                                )}
                                                {" đ"}
                                              </span>
                                            </div>
                                          );
                                        }
                                      );
                                    }
                                  )
                                  : ""}
                              </div>
                            </div>
                          </td>
                          {isHidden && (
                            <td className="py-2" style={styles.text_center}>
                              {product?.product?.quantity}
                            </td>
                          )}
                          <td
                            className="text-end px-2 py-2"
                            style={styles.text_right}
                          >
                            <span>
                              {formatPrice(
                                product?.attributeTypes?.reduce(
                                  (accumulator: any, currentItem: any) => {
                                    const prices = currentItem?.attributes?.map(
                                      (item: any) => item?.totalPrice
                                    );
                                    return (
                                      accumulator +
                                      prices?.reduce(
                                        (total: number, item: any) => {
                                          return total + +item;
                                        },
                                        initialValue
                                      )
                                    );
                                  },
                                  initialValue
                                ) || product?.product?.totalPrice
                              )}
                              {" đ"}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                    : ""}
                </tbody>
              </table>
            </div>

            <div style={styles.borderDottedBottom}>
              <div
                className="mb-1 text-end fs-5"
                style={{
                  ...styles.d_flex(JUSTIFY_CONTENT.END),
                  ...styles.fs_13,
                  ...styles.mb_3,
                  ...styles.font_family,
                }}
              >
                <div>
                  <span>Chiết khấu:</span>
                </div>
                <div style={{ ...styles.min_w_130, ...styles.text_right }}>
                  - 0 đ
                </div>
              </div>

              <div
                className="mb-1 text-end fs-5"
                style={{
                  ...styles.d_flex(JUSTIFY_CONTENT.END),
                  ...styles.fs_13,
                  ...styles.mb_12,
                  ...styles.font_family,
                }}
              >
                <div>
                  <span>Mã giảm giá:</span>
                </div>
                <div style={{ ...styles.min_w_130, ...styles.text_right }}>
                  - 0 đ
                </div>
              </div>

              <div
                className="mb-2 text-end"
                style={{
                  ...styles.d_flex(JUSTIFY_CONTENT.END),
                  ...styles.fs_15,
                  ...styles.fw_bold,
                  ...styles.font_family,
                  ...styles.alignContent_center,
                  ...styles.mb_3
                }}
              >
                <div className="fw-semibold fs-4">Tổng thanh toán:</div>
                <div
                  className="fw-semibold fs-4"
                  style={{
                    ...styles.min_w_130,
                    ...styles.text_right,
                    ...styles.fs_17,
                  }}
                >
                  {formatPrice(
                    totalPriceService(donHang?.lstProduct) || totalPriceSanPham
                  )}{" "}
                  đ
                </div>
              </div>

            </div>

            <div className="text-center" style={{ ...styles.borderDottedBottom, ...styles.text_center }}>
              <span
                className="mb-4 fs-5"
                style={{
                  ...styles.fs_13,
                  ...styles.fw_bold,
                  ...styles.font_family,
                }}
              >
                CẢM ƠN QUÝ KHÁCH VÀ HẸN GẶP LẠI
              </span>
            </div>
          </div>
        </Modal.Body>
      </div>

      <Modal.Footer className="py-2">
        <Button size="sm" variant="secondary" onClick={handleCloseDialog}>
          Hủy
        </Button>
        <Button size="sm" variant="primary" onClick={handleInPhieuHoaDon}>
          In phiếu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HoaDonDialog;
