import React, { useContext } from 'react'
import DanhSachSanPhamContext from '../utils/DanhSachSanPhamContext'
import CustomIconButton from '../../component/custom-icon-button/CustomIconButton'

type Props = {
    rowData: any
}

const ActionTable = (props: Props) => {
    const {handleOpenSanPhamDialog, handleOpenConfirmDialog} = useContext(DanhSachSanPhamContext);
  return (
    <div className='d-flex justify-content-center'>
          <CustomIconButton variant='edit' onClick={() => handleOpenSanPhamDialog ? handleOpenSanPhamDialog(props?.rowData) : {}} />
          <CustomIconButton variant='delete' onClick={() => handleOpenConfirmDialog ? handleOpenConfirmDialog(props?.rowData) : {}} />
    </div>
  )
}

export default ActionTable