import { OptionType, statusType } from "../models/IResign";

export const listStatusResign: OptionType[] = [
    {
        value: '1',
        title: "Đang thực hiện"
    },
    {
        value: '2',
        title: "Đã hoàn thành"
    }
]