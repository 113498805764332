import "./CustomIconButton.scss";

type Props = {
  onClick: () => void;
  variant: "delete" | "edit" | "visibility" | "print" | "approve";
};

const CustomIconButton = ({ onClick, variant }: Props) => {
  const renderIcon = (variant: string) => {
    if (variant === "delete") {
      return <i className="fa-solid fa-trash"></i>
    }
    if (variant === "visibility") {
      return <i className="bi bi-eye-fill"></i>
    }
    if (variant === "edit") {
      return <i className="fa-solid fa-pen"></i>
    }
    if (variant === "print") {
      return <i className="fa-solid fa-print"></i>
    }
    if (variant === "approve") {
      return <span>XÁC NHẬN</span>
    }
  };
  return (
    <div className={variant} onClick={onClick}>
      {
        renderIcon(variant)
      }
    </div>
  );
};

export default CustomIconButton;
