import { AuthModel, ResponseModel } from "./_models";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { toast } from "react-toastify";
import { RESPONSE_STATUS_CODE } from "./_consts";
import { localStorageServices } from "../../utils/LocalStorage";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const getAuth = (): AuthModel | undefined => {
  if (!sessionStorage) {
    return;
  }

  const lsValue: string | null = sessionStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!sessionStorage) {
    return;
  }

  try {
    // const lsValue = JSON.stringify(auth);
    localStorageServices.setSessionItem(AUTH_LOCAL_STORAGE_KEY, auth);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!sessionStorage) {
    return;
  }

  try {
    localStorageServices.removeSessionItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

const handleRequest = (
  requestConfig: AxiosRequestConfig
): AxiosRequestConfig => {
  const { headers = {} } = requestConfig;

  const auth = getAuth();
  if (auth && auth.access_token) {
    headers.Authorization = `Bearer ${auth.access_token}`;
  }

  return requestConfig;
};

const handleResponse = (responseConfig: AxiosResponse<ResponseModel>) => {
  const { data } = responseConfig;

  switch (data.code) {
    case RESPONSE_STATUS_CODE.SUCCESS:
      break;
    case RESPONSE_STATUS_CODE.CREATED:
    case RESPONSE_STATUS_CODE.NO_CONTENT:
      toast.success(data.message);
      break;
    default:
      break;
  }

  return responseConfig;
};

const handleError = (
  error: AxiosError<ResponseModel>
): Promise<AxiosError<ResponseModel>> => {
  const { isAxiosError, response } = error;

  if (isAxiosError) {
    switch (response?.data.code) {
      case RESPONSE_STATUS_CODE.UNAUTHORIZED:
        break;
      case RESPONSE_STATUS_CODE.BAD_REQUEST:
      case RESPONSE_STATUS_CODE.FORBIDDEN:
      case RESPONSE_STATUS_CODE.NOT_FOUND:
      case RESPONSE_STATUS_CODE.METHOD_NOT_ALLOWED:
      case RESPONSE_STATUS_CODE.CONFLICT:
      case RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR:
      case RESPONSE_STATUS_CODE.BAD_GATEWAY:
        // toast.error(response.data.message);
        break;
      default:
        break;
    }
  }

  return Promise.reject(error);
};

export function setupAxios(axios: any) {
  axios.defaults.timeout = 15000;
  axios.defaults.headers.common = {
    Accept: "application/json",
  };
  axios.interceptors.request.use(handleRequest, handleError);
  axios.interceptors.response.use(handleResponse, handleError);
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
