export interface IssueInfo {
    createdBy?: string | null;
    createDate?: number[] | null;
    modifiedBy?: string | null;
    modifyDate?: number[] | null;
    id?: string | null;
    tenSuCo?: string | null,
    loaiSuCo?: OptionType | null,
    ngayXayRa?: string | null,
    giaTriThietHai?: string | null,
    donViLienQuan?: string | null,
    trangThai?: OptionType | null,
    noiXayRa?: OptionType | null,
    nguyenNhan?: OptionType | null,
    moTa?: string | null
}

export const initIssue: IssueInfo = {
    id: '',
    tenSuCo: '',
    loaiSuCo: { value: "", title: "" },
    ngayXayRa: '',
    giaTriThietHai: '',
    donViLienQuan: '',
    trangThai: { value: "", title: "" },
    noiXayRa: { value: "", title: "" },
    nguyenNhan: { value: "", title: "" },
    moTa: ''
}

export interface OptionType {
    id?: number;
    title: string;
    value: string;
}

export interface IssueEmployees {
    id: string;
    hoVaTen?: string | null;
    maNhanVien?: string | null;
    donViCongTac?: string | null;
    viTriCongViec?: string | null;
    hinhThucXuLy?: OptionType | null;
    giaTriPhaiBoiThuong?: string | null;
    giaTriDuocBoiThuong?: string | null;
    tepDinhKem?: string | null;
    trangThai?: OptionType | null
}

export const initEmployee: IssueEmployees = {
    id: "",
    hoVaTen: "",
    maNhanVien: "",
    donViCongTac: "",
    viTriCongViec: "",
    hinhThucXuLy: { value: "", title: "" },
    giaTriPhaiBoiThuong: "",
    giaTriDuocBoiThuong: "",
    tepDinhKem: "",
    trangThai: null,
}