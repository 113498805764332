import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../../component/table-custom/columns/TableCustomCell";
import { useIntl } from "react-intl";
import { IListJoin } from "../model/WelfareModel";
function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}
const ListJoinColumn: ReadonlyArray<Column<IListJoin>> = [
  {
    Header: (props) => (
      <TableCustomHeader<IListJoin>
        tableProps={props}
        title={useCustomIntl("FULL.NAME")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "FULL.NAME",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].hoVaTen} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IListJoin>
        tableProps={props}
        title={useCustomIntl("APPLICABLE.UNIT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPLICABLE.UNIT",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].donViApDung} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IListJoin>
        tableProps={props}
        title={useCustomIntl("JOB.POSITION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "JOB.POSITION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].viTriCongViec} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IListJoin>
        tableProps={props}
        title={useCustomIntl("EMPLOYEE.CONTRIBUTION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "EMPLOYEE.CONTRIBUTION",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props.data[props.row.index].soTienNhanVienDong} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<IListJoin>
        tableProps={props}
        title={useCustomIntl("COMPANY.SUPPORT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "COMPANY.SUPPORT",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props.data[props.row.index].soTienCongTyHoTro} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<IListJoin>
        tableProps={props}
        title={useCustomIntl("STATUS")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "STATUS",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].trangThai} />,
  },
];

export { ListJoinColumn };
