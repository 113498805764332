export const SUCCESS_CODE = 200;

export const TYPE_OF_CILENT = ["Khách lẻ", "Khách hàng online"];

export const STATUS_SUCCESS = ["Hoàn thành", "Mới tạo/Tiệm giặt đã xác nhận"];
export const STATUS_PROCESSING = "Đang xử lý";
export const STATUS_PAY = {
  PAID: { id: "1", name: "Đã thanh toán" },
  UN_PAID: { id: "2", name: "Chưa thanh toán" }
};

export const LIST_STATUS_DON_HANG = [
  {
    id: 1,
    name: "cho-xac-nhan",
  },
  {
    id: 2,
    name: "da-xac-nhan",
  },
  {
    id: 3,
    name: "dang-xu-ly",
  },
  {
    id: 4,
    name: "da-huy",
  },
  {
    id: 5,
    name: "hoan-thanh",
  },
  {
    id: 6,
    name: "loi",
  },
];

export const LIST_TYPE_POS = ["dai-ly", "tiem-giat"];

export const TYPE_ORDER = {
  ONLINE: {
    id: 1,
    value: "online",
  },
  OFFLINE: {
    id: 0,
    value: "offline",
  },
};

export const JUSTIFY_CONTENT = {
  BETWEEN: "between",
  CENTER: "center",
  END: "end",
};


export const styles = {
  body: {
    padding: "30px 50px",
    width: "500px",
  },

  borderCode: {
    borderBottom: "1px dotted black",
    borderTop: "1px dotted black",
    position: "relative",
    zIndex: "1000"
  },

  borderHeader: {
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
  },

  borderDottedBottom: {
    borderBottom: "1px dotted #000",
    padding: "8px 0px"
  },

  borderSolidBottom: {
    borderBottom: "1px solid #ddd",
  },

  rowTable: {
    height: "auto",
    borderBottom: "1px dotted #000",
    padding: "0px 0px 5px 0px"
  },

  logoGh: {
    position: "absolute" as any,
    top: "8px",
    width: "50px"
  },

  detailCuaHang: {
    position: "relative",
    zIndex: "1",
    paddingLeft: "70px",
    textAlign: "center"
  },

  font_family: {
    fontFamily: "sans-serif",
  },

  detailKhachHang: {
    borderBottom: "1px dotted black",
    borderTop: "1px dotted black",
    position: "relative",
    padding: "5px 0px"
  },

  d_flex: (type: string) => {
    return {
      display: "flex",
      justifyContent: type === "end" ? "end" : "space-between"
    }
  },

  alignContent_center: {
    alignContent: "center"
  },

  border_bottom_ddd: {
    borderBottom: "1px solid #ddd"
  },

  text_left: {
    textAlign: "left" as any
  },

  text_right: {
    textAlign: "right" as any
  },

  text_center: {
    textAlign: "center" as any
  },

  fs_10: {
    fontSize: "10px"
  },

  fs_12: {
    fontSize: "12px"
  },

  fs_16: {
    fontSize: "16px"
  },

  fs_13: {
    fontSize: "13px"
  },

  fs_15: {
    fontSize: "15px"
  },

  fs_17: {
    fontSize: "17px"
  },

  fw_bold: {
    fontWeight: "bold"
  },

  fw_payment: (paymentName: string) => {
    return {
      fontWeight: paymentName === STATUS_PAY.PAID.name ? "bold" : ""
    }
  },

  py_3: {
    padding: "3px 0px"
  },

  py_5: {
    padding: "5px 0px"
  },

  py_6: {
    padding: "6px 0px"
  },

  ps_15: {
    paddingLeft: "15px"
  },

  mb_5: {
    marginBottom: "5px"
  },

  mb_3: {
    marginBottom: "3px"
  },

  mb_12: {
    marginBottom: "12px"
  },

  min_w_130: {
    minWidth: "130px"
  }

};