import { Column } from 'react-table';
import { TableCustomHeader } from '../../component/table-custom/columns/TableCustomHeader';
import { TableCustomCell } from '../../component/table-custom/columns/TableCustomCell';
import { AppreciativeInFo } from '../model/appreciativeModel';
import { covertDateToString, useCustomIntl } from '../../utils/FunctionUtils';

const AppreciativeColumn: ReadonlyArray<Column<AppreciativeInFo>> = [
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.DONVIAPDUNGTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.DONVIAPDUNGTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].donViApDungText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.DATE')}
        className='text-center text-light min-w-150px '
      />
    ),
    id: 'APPRECIATIVE.DATE',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={covertDateToString(props.data[props.row.index].date) || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.LOAIKHENTHUONGTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.LOAIKHENTHUONGTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].loaiKhenThuongText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.SOQUYETDINH')}
        className='text-center text-light min-w-100px '
      />
    ),
    id: 'APPRECIATIVE.SOQUYETDINH',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].soQuyetDinh || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.NGAYQUYETDINH')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.NGAYQUYETDINH',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={covertDateToString(props.data[props.row.index].ngayQuyetDinh) || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.NGUOIQUYETDINHTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.NGUOIQUYETDINHTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].nguoiQuyetDinhText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.CHUCDANHNGUOIQUYETDINH')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.CHUCDANHNGUOIQUYETDINH',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].chucDanhNguoiQuyetDinh || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.HINHTHUCKHENTHUONGTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.HINHTHUCKHENTHUONGTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].hinhThucKhenThuongText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.TONGGIATRI')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.TONGGIATRI',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].tongGiaTri || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.DOITUONGKHENTHUONGTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.DOITUONGKHENTHUONGTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].doiTuongKhenThuongText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.CHITIETDOITUONGTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.CHITIETDOITUONGTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].chiTietDoiTuongText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.CAPKHENTHUONGTEXT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'APPRECIATIVE.CAPKHENTHUONGTEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].capKhenThuongText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.LYDO')}
        className='text-center text-light min-w-500px '
      />
    ),
    id: 'APPRECIATIVE.LYDO',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].lyDo || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<AppreciativeInFo>
        tableProps={props}
        title={useCustomIntl('APPRECIATIVE.TRANGTHAITEXT')}
        className='text-center text-light min-w-100px '
      />
    ),
    id: 'APPRECIATIVE.TRANGTHAITEXT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].trangThaiText || '-'} />
      );
    }
  },
];

export { AppreciativeColumn };
