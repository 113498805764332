import React, { ReactNode, useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import CustomRadio from '../../component/CustomRadio'
import { useFormik } from 'formik'
import * as yup from 'yup'
import CustomIconButton from '../../component/custom-icon-button/CustomIconButton'
import { formatPrice } from '../../utils/FunctionUtils'
import { ATTRIBUTE_TYPE_NAME, DEFAULT_UNIT, LIST_UNIT, LIST_UNITS_UNCOUNTABLE } from '../constants'
import { Autocomplete } from '../../component/Autocomplete'
import { getListHuongXaVai } from '../services/DanhSachSanPhamService'
import { toast } from 'react-toastify'

type Props = {
    formikDichVu: any
    subTypesIndex: number
    data: any
    onChangeDichVu: (attributes: any) => void
    onChangePrice: (price: any) => void
}


const TuyChonDichVu = (props: Props) => {
    const isThietLapGia = props?.data?.typeName === ATTRIBUTE_TYPE_NAME.THIET_LAP_GIA;
    const isDichVuPhuThem = props?.data?.typeName === ATTRIBUTE_TYPE_NAME.DICH_VU_PHU_THEM;
    const isHuongXaVai = props?.data?.typeName === ATTRIBUTE_TYPE_NAME.HUONG_XA_VAI
    let isUncountableUnit: boolean;

    const initialValues = {
        // name: '',
        minPrice: null,
        price: null,
        unit: DEFAULT_UNIT,
        listTuyChonDichVu: props?.data?.attributes as any[],
        dichVuPhuThem: null as any,
        huongXaVai: null as any
    }

    const validationSchema = yup.object({
        // name: yup.string().trim().nullable().when('isNotKhungGia', {
        //     is: () => !isThietLapGia && !isHuongXaVai,
        //     then: yup.string().trim().nullable().required("Trường này là bắt buộc"),
        // }),
        minPrice: yup.number().nullable().when('isThietLapGiaAndIsUncountableUnit', {
            is: () => isThietLapGia && isUncountableUnit,
            then: yup.number().nullable().required("Trường này là bắt buộc"),
        }),
        price: yup.number().nullable().required("Trường này là bắt buộc"),
        dichVuPhuThem: yup.object().nullable().when('isDichVuPhuThem', {
            is: () => isDichVuPhuThem,
            then: yup.object().nullable().required("Trường này là bắt buộc"),
        }),
        huongXaVai: yup.object().nullable().when('isHuongXaVai', {
            is: () => isHuongXaVai,
            then: yup.object().nullable().required("Trường này là bắt buộc"),
        }),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            let nameThietLapGia = values?.unit;
            let nameDichVuPhuThem = values?.dichVuPhuThem?.name;
            let nameHuongXaVai = values?.huongXaVai?.name;

            const submitData: {
                name: string | null
                price: number | null
                unit: string | null
                minPrice?: number | null
                deleted: boolean | null
            } = {
                name: isThietLapGia
                    ? nameThietLapGia
                    : (isDichVuPhuThem
                        ? nameDichVuPhuThem
                        : nameHuongXaVai),
                price: values?.price,
                unit: values?.unit,
                deleted: false
            }
            if (isUncountableUnit) {
                submitData.minPrice = values?.minPrice;
                props?.onChangePrice(values?.minPrice);
            } else {
                props?.onChangePrice(values?.price);
            }
            handleAddTuyChon(submitData);
            resetForm({
                values: {
                    ...values,
                    price: null,
                    minPrice: null,
                    // name: '',
                    huongXaVai: null
                }
            })
        }
    });

    isUncountableUnit = LIST_UNITS_UNCOUNTABLE?.some(item => item === formik.values?.unit);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('unit', event.target.value);
    };


    const handleAddTuyChon = (data: any) => {
        if (isThietLapGia && formik.values.listTuyChonDichVu?.filter((item: any) => !item?.deleted).length > 0) {
            toast.warning("Đã có đơn vị được thiết lập giá");
            return;
        }
        let newArr = formik.values.listTuyChonDichVu;
        newArr.push(data);
        formik.setFieldValue('listTuyChonDichVu', newArr);
    }

    const handleRemoveTuyChon = (index: number) => {
        let newArr = formik.values.listTuyChonDichVu;
        // newArr.splice(index, 1);
        newArr[index] = {
            ...newArr[index],
            deleted: true
        }
        formik.setFieldValue('listTuyChonDichVu', newArr);
    }

    useEffect(() => {
        props?.onChangeDichVu(formik.values?.listTuyChonDichVu);
    }, [formik.values?.listTuyChonDichVu])

    return (<>
        <Form.Label className='mb-3'>{props?.data?.typeName}</Form.Label>
        {isThietLapGia && <Row>
            <Col xs={6}>
                <Row className='mb-3'>
                    <Col className='d-flex'>
                        {LIST_UNIT?.map((item, index) => {
                            return (
                                <CustomRadio
                                    label={item?.title}
                                    selectedOption={formik.values?.unit}
                                    onChange={handleOptionChange}
                                    className='mx-2'
                                    value={item?.value}
                                />
                            )
                        })}
                    </Col>
                </Row>
            </Col>

        </Row>}
        <Row>
            <Col xs={6}>
                {isThietLapGia ? <>
                    <Row>
                        <Col xs={11}>
                            <Form.Group className="mb-3 d-flex">
                                <Col xs={3}>
                                    <Form.Label className='mt-2 mx-2'>Giá</Form.Label>
                                </Col>
                                <Col xs>
                                    <InputGroup>
                                        <Form.Control type='number' size='lg'
                                            name='price'
                                            value={formik.values?.price ?? (formik.values?.price === 0 ? 0 : '')}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.price && Boolean(formik.errors.price)}
                                        />
                                        <InputGroup.Text id="basic-addon2" className='rounded-end'>
                                            <h5 className='mb-1'>/</h5>
                                            <h5 className='mb-0'>{formik.values?.unit}</h5>
                                        </InputGroup.Text>
                                        <Form.Control.Feedback type="invalid"
                                            className='mt-0'
                                        >{formik.errors.price}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    {isUncountableUnit && <Row>
                        <Col xs={11}>
                            <Form.Group className="mb-3 d-flex">
                                <Col xs={3}>
                                    <Form.Label className='mt-2 mx-2'>Giá tối thiểu</Form.Label>
                                </Col>
                                <Col xs>
                                    <Form.Control type='number' size='lg'
                                        name='minPrice'
                                        value={formik.values?.minPrice ?? (formik.values?.minPrice === 0 ? 0 : '')}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.minPrice && Boolean(formik.errors.minPrice)}
                                    />
                                    <Form.Control.Feedback type="invalid"
                                        className='mt-0'
                                    >{formik.errors.minPrice}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={11}><p className='text-end'>
                            <em>(Ghi chú: Đồ quá ít (VD: 1,2kg hay 2kg) khách vẫn phải thanh toán theo giá tối thiểu)</em>
                        </p></Col>
                    </Row>}
                </> : <>
                    {isHuongXaVai ? <Row>
                        <Col xs={11}>
                            <Form.Group className="mb-3 d-flex">
                                <Col xs={1}>
                                    <Form.Label className='mt-2 ms-2'>Tên</Form.Label>
                                </Col>
                                <Col xs>
                                    <Autocomplete
                                        name='huongXaVai'
                                        options={[]}
                                        searchFunction={getListHuongXaVai}
                                        searchObject={{isHXV: true}}
                                        value={formik.values.huongXaVai}
                                        onChange={(value) => formik.setFieldValue('huongXaVai', value)}
                                        placeholder='Chọn loại hương xả vải...'
                                        errors={formik.errors.huongXaVai}
                                        touched={formik.touched.huongXaVai}
                                    />
                                    {formik.touched.huongXaVai && formik.errors.huongXaVai && (
                                        <div className="invalid-feedback">{formik.errors.huongXaVai as ReactNode}</div>
                                    )}
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row> : <Row>
                        <Col xs={11}>
                            <Form.Group className="mb-3 d-flex">
                                <Col xs={1}>
                                    <Form.Label className='mt-2 ms-2'>Tên</Form.Label>
                                </Col>
                                <Col xs>
                                    <Autocomplete
                                        name='dichVuPhuThem'
                                        options={[]}
                                        searchFunction={getListHuongXaVai}
                                        searchObject={{isHXV: false}}
                                        value={formik.values.dichVuPhuThem}
                                        onChange={(value) => formik.setFieldValue('dichVuPhuThem', value)}
                                        placeholder='Chọn loại dịch vụ phụ...'
                                        errors={formik.errors.dichVuPhuThem}
                                        touched={formik.touched.dichVuPhuThem}
                                    />
                                    {formik.touched.dichVuPhuThem && formik.errors.dichVuPhuThem && (
                                        <div className="invalid-feedback">{formik.errors.dichVuPhuThem as ReactNode}</div>
                                    )}
                                </Col>
                                {/* <Col xs>
                                    <Form.Control type='text' size='lg'
                                        name='name'
                                        value={formik.values?.name ?? ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.name && Boolean(formik.errors.name)}
                                    />
                                    <Form.Control.Feedback type="invalid"
                                        className='mt-0'
                                    >{formik.errors.name}</Form.Control.Feedback>
                                </Col> */}
                            </Form.Group>
                        </Col>
                    </Row>}
                    <Row>
                        <Col xs={11}>
                            <Form.Group className="mb-3 d-flex">
                                <Col xs={1}>
                                    <Form.Label className='mt-2 ms-2'>Giá</Form.Label>
                                </Col>
                                <Col xs>
                                    <Form.Control type='number' size='lg'
                                        name='price'
                                        value={formik.values?.price ?? (formik.values?.price === 0 ? 0 : '')}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.price && Boolean(formik.errors.price)}
                                    />
                                    <Form.Control.Feedback type="invalid"
                                        className='mt-0'
                                    >{formik.errors.price}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </>}

                <Row className='mb-4 justify-content-end'>
                    <Col xs={3} className='ms-2'> <Button size='sm' variant='success'
                        onClick={() => formik.handleSubmit()}
                    >Thêm</Button></Col>
                </Row>
            </Col>
            <Col xs>
                <Table responsive>
                    <thead className="border">
                        <tr className='bg-secondary text-light'>
                            {!isThietLapGia && <th className="text-center">Phân loại</th>}
                            {isThietLapGia && <th className="text-center w-100px">Đơn vị tính</th>}
                            <th className="text-center">Giá</th>
                            {isThietLapGia && <th className="text-center">Giá tối thiểu</th>}
                            <th className="text-center w-100px">Thao tác</th>
                        </tr>
                    </thead>

                    <tbody className="border">
                        {formik.values.listTuyChonDichVu?.filter((item: any) => !item?.deleted).length > 0 ? (
                            formik.values.listTuyChonDichVu
                                // ?.filter((item: any) => !item?.deleted)
                                ?.map((item: any, index) => {
                                    if (!item?.deleted) {
                                        return <tr key={index}>
                                            {!isThietLapGia && <td className='text-center'>
                                                <span className='text-success fw-bold'>{item?.name ?? ''}</span>
                                            </td>}
                                            {isThietLapGia && <td className='text-center'>{item?.unit ?? ''}</td>}
                                            <td className='text-end pe-3 lead'>{formatPrice(item?.price) + ' đ'}</td>
                                            {isThietLapGia && <td className='text-end pe-3 lead'>{item?.minPrice ? formatPrice(item?.minPrice) + ' đ' : '---'}</td>}
                                            <td className='text-center'>
                                                <CustomIconButton variant='delete' onClick={() => handleRemoveTuyChon(index)} />
                                            </td>
                                        </tr>;
                                    }
                                    return <></>
                                })
                        ) : (
                            <tr className="border-0 text-center">
                                <td colSpan={4}>Không có tùy chọn nào</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

            </Col>
        </Row>
    </>)
}

export default TuyChonDichVu