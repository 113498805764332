import clsx from "clsx";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ConfirmDialog } from "../../../projects/components/ConfirmDialog";
import { ChangeColumnDialog } from "./ChangeColumnDialog";
interface TableCustomToolbarProps {
  selectedRows: any;
  allColumns: any;
  data?: {};
  handleUnSelectedRows: () => void;
  setShouldOpenFilterSearch: React.Dispatch<React.SetStateAction<boolean>>;
  shouldOpenFilterSearch: boolean;
  handleDelete: ((...params: any) => Promise<void>) | undefined
}
const TableCustomToolbar = (props: TableCustomToolbarProps) => {
  const intl = useIntl();
  const {
    selectedRows,
    allColumns,
    handleUnSelectedRows,
    setShouldOpenFilterSearch,
    shouldOpenFilterSearch,
    handleDelete
  } = props;
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] = useState(false)
  const [shouldOpenChangeColumnDialog, setShouldOpenChangeColumnDialog] = useState<boolean>(false);
  const handleDeleteClick = async () => {
    let listDeleteID: string[] = []
    selectedRows.forEach((item: any) => {
      return listDeleteID.push(String(item.original.id))
    }, [])
    if(handleDelete){
      handleDelete(listDeleteID)
      handleUnSelectedRows()
    }
    setShouldOpenConfirmDeleteDialog(false)
  }
  return (
    <div className="table-toolbar rounded-top p-3">
      <Row>
        <Col xs={10} className="d-flex gap-4 align-items-center">
          <span>
            {intl.formatMessage({ id: "SELECTED" })}: <strong>{selectedRows.length}</strong>
          </span>
          {selectedRows.length > 0 && (
            <>
              <span className="fw-bold text-warning cursor-pointer" onClick={handleUnSelectedRows}>
                {intl.formatMessage({ id: "UNSELECTED" })}
              </span>
              <div className="delete-box cursor-pointer" onClick={(e) => setShouldOpenConfirmDeleteDialog(true)}>
                <i className="bi bi-trash fs-4 text-danger px-4"></i>
                <span className="fw-bold text-danger">
                  {intl.formatMessage({ id: "DELETE" })}
                </span>
              </div>
            </>
          )}
        </Col>
        <Col xs={2} className="flex-end d-flex gap-4">
          <i
            className={clsx("bi bi-funnel toolbar-icon fs-4", {
              "filter-open": shouldOpenFilterSearch,
            })}
            onClick={() => {
              setShouldOpenFilterSearch((prevState: boolean) => !prevState);
            }}
          ></i>
          <i
            className={clsx("bi bi-gear toolbar-icon fs-4", {
              "filter-open": shouldOpenChangeColumnDialog,
            })}
            onClick={() => {
              setShouldOpenChangeColumnDialog(true);
            }}
          ></i>
        </Col>
      </Row>
      {shouldOpenChangeColumnDialog && (
        <ChangeColumnDialog
          allColumns={allColumns}
          handleClose={() => {
            setShouldOpenChangeColumnDialog(false);
          }}
        />
      )}

      {shouldOpenConfirmDeleteDialog && <ConfirmDialog
        show={shouldOpenConfirmDeleteDialog}
        title={intl.formatMessage({ id: 'DIALOG.CONFIRM.DELETE.TITLE' })}
        message={intl.formatMessage({ id: 'DIALOG.CONFIRM.DELETE.MESSAGE' })}
        yes={intl.formatMessage({ id: 'BTN.CONFIRM' })}
        onYesClick={() => handleDeleteClick()}
        cancel={intl.formatMessage({ id: 'BTN.CANCEL' })}
        onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
      />}
    </div>
  );
};

export { TableCustomToolbar };