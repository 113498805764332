import { useState } from "react";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { formatPrice } from "../../../utils/FunctionUtils";
import { useAppDispatch } from "../../../../hook";
import {
  decreaseQuantity,
  deleteCartItem,
  increaseQuantity,
} from "../../reducer/PosSlice";
import ConfirmDialog from "../../../component/ConfirmDialog";
import { IProduct } from "../../models/PosModels";

type Props = {
  sanPham: IProduct;
};

const CartSanPham = (props: Props) => {
  const { sanPham } = props;
  const dispatch = useAppDispatch();
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState(false);

  const handleOpenConfirmDialog = () => {
    setShouldOpenConfirmDialog(true);
  };

  const handleDeleteCartItem = () => {
    dispatch(deleteCartItem(sanPham));
    setShouldOpenConfirmDialog(false);
  };

  return (
    <tr>
      <td className="ps-2">{sanPham?.name}</td>
      <td className="text-center">
        <div className="d-flex justify-content-center">
          <button className="border" onClick={() => dispatch(decreaseQuantity(sanPham))}>-</button>
          <input
            className="w-35px border text-center"
            style={{ outline: "none" }}
            value={sanPham?.quantity}
          />
          <button className="border" onClick={() => dispatch(increaseQuantity(sanPham))}>+</button>
        </div>
      </td>
      <td className="text-end min-w-100px">{formatPrice(sanPham?.price)} ₫</td>
      <td className="text-center pe-2">
        <CustomIconButton variant="delete" onClick={handleOpenConfirmDialog} />
      </td>
      {shouldOpenConfirmDialog && (
        <ConfirmDialog
          view={shouldOpenConfirmDialog}
          onYesClick={handleDeleteCartItem}
          onCancelClick={() => setShouldOpenConfirmDialog(false)}
          Description="Bạn có chắc chắn muốn xóa không?"
        />
      )}
    </tr>
  );
};

export default CartSanPham;
