import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import Appreciative from "../modules/appreciative/Appreciative";
import { Resign } from "../modules/resign/Resign";
import Transition from "../modules/transition/Transition";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import Issue from "../modules/issue/Issue";
import { AppointmentList } from "../modules/appointment-list/AppointmentList";
import { DismissedList } from "../modules/dismissed-list/DismissedList";
import { WelfareList } from "../modules/welfare-list/services/WelfareList";
import { URL_MENU } from "../../constants";
import Order from "../modules/don-hang/Order";
import DanhMucCuaHangDaiLy from "../modules/danh-sach-cua-hang/DanhMucCuaHangDaiLy";
import DanhSachSanPham from "../modules/danh-sach-san-pham/DanhSachSanPham";
import { PRODUCT_TYPE } from "../modules/danh-sach-san-pham/constants";
import Pos from "../modules/pos/Pos";
import DetailDonHang from "../modules/don-hang/components/DetailDonHang";
import { ORG_REGISTRATION_STATUS } from "../modules/danh-sach-cua-hang/const/DanhMucCuaHangDaiLyConstants";
const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"));
    
  return (
    <Routes>
      {/* <Route index element={<Navigate to="/" />} /> */}
      {/* <Route path="/*" element={<MasterLayout />} /> */}
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dasboard" />} />
        {/* Pages */}
        <Route path="/*" element={<DashboardWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route path={URL_MENU.PRODUCT}>
          <Route path="hang-hoa" element={<DanhSachSanPham type={PRODUCT_TYPE.HANG_HOA} />} />
          <Route path="dich-vu" element={<DanhSachSanPham type={PRODUCT_TYPE.DICH_VU} />} />
        </Route>
        <Route path={"pos/:typePos"} element={<Pos />} />
        <Route path={"pos/:type/don-hang"} element={<Order />} />
        <Route path={"don-hang/:type/detail"} element={<DetailDonHang />} />
        <Route path={"cua-hang/dai-ly"}>
          <Route path="danh-sach" element={<DanhMucCuaHangDaiLy type={ORG_REGISTRATION_STATUS.REGISTERED} />} />
          <Route path="moi-dang-ky" element={<DanhMucCuaHangDaiLy type={ORG_REGISTRATION_STATUS.UNREGISTERED} />} />
        </Route>
        <Route path={"don-hang/:type"} element={<Order />} />
        <Route path="resign" element={<Resign />} />
        <Route path="transition" element={<Transition />} />
        <Route path="appreciative" element={<Appreciative />} />
        <Route path="issue" element={<Issue />} />
        <Route path="appointment" element={<AppointmentList />} />
        <Route path="dismissed" element={<DismissedList />} />
        <Route path="welfare" element={<WelfareList />} />

        {/* Lazy Modules */}
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
