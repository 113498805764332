import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import '../../../styles/index.scss';
import { Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { IssueEmployees, initEmployee } from '../../models/IIssue';
import { formHandlingOpt, statusEmployeeOpt } from '../../const/IssueConst';
import { InputCustom } from '../../../component/input-field/InputCustom';
import * as Yup from 'yup';
import { inputTypeList } from '../../../constant';
interface Props {
    employee?: IssueEmployees | null,
    shouldOpenEmployeeDialog: boolean,
    handleOpenEmployeeDialog: () => void,
    handleCloseEmployeeDialog: () => void,
}

export const EmployeeDialog = (props: Props) => {
    const intl = useIntl();
    const { shouldOpenEmployeeDialog, handleOpenEmployeeDialog, handleCloseEmployeeDialog, employee } = props;
    const employeeValidationSchema = Yup.object().shape({
        hoVaTen: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        trangThai: Yup.mixed()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
    })

    return (
        <Modal
            show={shouldOpenEmployeeDialog}
            size='lg'
            aria- labelledby="example-custom-modal-styling-title"
            onHide={handleCloseEmployeeDialog}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title"
                    className='heading-5'
                >
                    {intl.formatMessage({ id: 'ISSUE.LIST_EMPLOYEES' })}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={!!employee ? employee : initEmployee}
                onSubmit={(values, formikHelpers) => { }}
                validationSchema={employeeValidationSchema}
            >
                {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                    <>
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Col>
                                    <Row className='px-8 pt-8'>
                                        <Col xs={6}>
                                            <InputCustom
                                                required={true}
                                                title='GENERAL.FULLNAME'
                                                name='hoVaTen'
                                                values={!!values.hoVaTen ? values.hoVaTen : ""}
                                                type={inputTypeList.text}
                                                handleChange={handleChange}
                                                errors={errors.hoVaTen}
                                            ></InputCustom>
                                        </Col>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='INPUT.CODE'
                                                name='maNhanVien'
                                                values={!!values.maNhanVien ? values.maNhanVien : ""}
                                                type={inputTypeList.text}
                                                handleChange={handleChange}
                                            ></InputCustom>
                                        </Col>
                                    </Row>
                                    <Row className='px-8 pt-8'>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='GENERAL.CURRENT_ORGANIZATION'
                                                name='donViCongTac'
                                                values={!!values.donViCongTac ? values.donViCongTac : ""}
                                                type={inputTypeList.text}
                                                handleChange={handleChange}
                                            ></InputCustom>
                                        </Col>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='GENERAL.EMPLOYEE.POSITION'
                                                name='viTriCongViec'
                                                values={!!values.viTriCongViec ? values.viTriCongViec : ""}
                                                type={inputTypeList.text}
                                                handleChange={handleChange}
                                            ></InputCustom>
                                        </Col>
                                    </Row>
                                    <Row className='px-8 pt-8'>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='ISSUE.FORM_HANDLING'
                                                name='hinhThucXuLy'
                                                values={!!values.hinhThucXuLy ? values.hinhThucXuLy : ""}
                                                type={inputTypeList.select}
                                                errors={errors.hinhThucXuLy}
                                                options={formHandlingOpt}
                                                optionLabel="title"
                                                handleChange={(selectedOptions: any) => {
                                                    setFieldValue(
                                                        'hinhThucXuLy',
                                                        selectedOptions
                                                    )
                                                }}
                                                optionValue='value'
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='ISSUE.COMPENSATION_VALUE'
                                                name='giaTriPhaiBoiThuong'
                                                values={!!values.giaTriPhaiBoiThuong ? values.giaTriPhaiBoiThuong : ""}
                                                type={inputTypeList.text}
                                                handleChange={handleChange}
                                            ></InputCustom>
                                        </Col>
                                    </Row>
                                    <Row className='px-8 pt-8'>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='ISSUE.COMPENSATED_VALUE'
                                                name='giaTriDuocBoiThuong'
                                                values={!!values.giaTriDuocBoiThuong ? values.giaTriDuocBoiThuong : ""}
                                                type={inputTypeList.text}
                                                handleChange={handleChange}
                                            ></InputCustom>
                                        </Col>
                                        <Col xs={6}>
                                            <InputCustom
                                                title='GENERAL.ATTACHMENT'
                                                name='tepDinhKem'
                                                values={!!values.tepDinhKem ? values.tepDinhKem : ""}
                                                type={inputTypeList.file}
                                                handleChange={handleChange}
                                            ></InputCustom>
                                        </Col>
                                    </Row>
                                    <Row className='px-8 pt-8'>
                                        <Col xs={6}>
                                            <InputCustom
                                                required={true}
                                                title='ISSUE.STATUS'
                                                name='trangThai'
                                                values={!!values.trangThai ? values.trangThai : ""}
                                                type={inputTypeList.select}
                                                errors={errors.trangThai ? errors.trangThai : ""}
                                                options={statusEmployeeOpt}
                                                optionLabel="title"
                                                optionValue="value"
                                                handleChange={(selectedOptions: any) => {
                                                    setFieldValue(
                                                        'trangThai',
                                                        selectedOptions
                                                    )
                                                }}
                                            />
                                        </Col>

                                    </Row>
                                </Col>
                            </Modal.Body>
                            <Modal.Footer className="flex-center">
                                <Button
                                    variant="outline-secondary"
                                    className='button-secondary btn-sm'
                                    onClick={() => handleCloseEmployeeDialog()}
                                >
                                    {intl.formatMessage({ id: 'BTN.CANCEL' })}
                                </Button>
                                <Button
                                    variant="outline-primary btn-sm"
                                    className='button-low-primary'
                                    type='submit'
                                >
                                    {intl.formatMessage({ id: 'BTN.ADDANDSAVE' })}
                                </Button>
                                <Button
                                    variant="primary"
                                    className='button-primary btn-sm'
                                    type='submit'>
                                    {intl.formatMessage({ id: 'BTN.SAVE' })}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>

        </Modal >
    );
};