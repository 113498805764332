import { useState } from "react";
import { Form } from "react-bootstrap";

type Props = {
    placeholder?: string;
    handleOnSubmit: (data: string) => void
}

const InputSearch = ({ placeholder = "Tìm kiếm", handleOnSubmit }: Props) => {
    const [value, setValue] = useState<string>("")

    const handleChang = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }
    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleOnSubmit(value);
        }}
            className="text-end pe-4 position-relative">
            <input
                className="input-search"
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={handleChang} />
            <button type="submit" className="input-search-icon"><i className="fa-solid fa-magnifying-glass"></i></button>
        </Form>
    )
}

export default InputSearch