import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { getDetailProduct } from "../../services/DanhSachSanPhamService";
import { ISanPham, SanPham } from "../../models/ISanPham";
import { formatPrice } from "../../../utils/FunctionUtils";
import {
  CODE_SUCCESS,
  ERROR_MESSAGE,
  MESSAGE_SUCCESS,
} from "../../../../../constants";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { addToCart } from "../../../pos/reducer/PosSlice";
import "../../DanhSachSanPham.scss";
import { ATTRIBUTE_TYPE_NAME, DEFAULT_IMG_URL, PRODUCT_TYPE, SMALLEST_QUANTITY } from "../../constants";
import CustomRadio from "../../../component/CustomRadio";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomCheckbox from "../../../component/CustomCheckBox";
import { setPageLoading } from "../../../component/page-loading/PageLoadingReducer";
import PageLoading from "../../../component/page-loading/PageLoading";
import { handleSortListAttributeType } from "../../utils/FunctionUtils";

type Props = {
  dataSanPham?: SanPham & ISanPham;
  handleClose: () => void;
  isView?: boolean;
};

const ThongTinSanPhamDialog = ({ dataSanPham, handleClose, isView }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.pageLoading.loading);
  const cartListReducer = useAppSelector((state) => state.pos.cartList);
  const [thongTinSanPham, setThongTinSanPham] = useState<ISanPham>({
    product: { code: "", name: "", description: "", quantity: 0, price: 0 },
    attributeTypes: [],
  });
  const [listThuocTinh, setListThuocTinh] = useState<any>();
  const isHangHoa = thongTinSanPham?.product?.productType?.includes(
    PRODUCT_TYPE.HANG_HOA
  );
  const [quantity, setQuantity] = useState(1);
  const totalPriceSanPham = thongTinSanPham?.product?.price * quantity;
  // const [dichVuPhuThemOption, setDichVuPhuThemOption] = React.useState("");
  // const [huongXaVaiOption, setHuongXaVaiOption] = React.useState("");
  const [listAtrribute, setListAtrribute] = React.useState<any[]>(Array.from({ length: 3 }, () => []));

  const formik = useFormik({
    initialValues: {
      thietLapGiaQuantity: null,
    },
    validationSchema: yup.object({
      thietLapGiaQuantity: yup.number().nullable().when('isDichVu', {
        is: () => !isHangHoa,
        then: yup.number().nullable()
          .required("Trường này là bắt buộc")
          .min(SMALLEST_QUANTITY, "Giá trị không hợp lệ")
      })
    }),
    onSubmit: (values) => {
      handleAddProductToCart();
    }
  })

  useEffect(() => {
    dataSanPham?.id && handleGetThongTinSanPham(dataSanPham?.id);
  }, [dataSanPham?.id]);

  const getTotalServicesPrice = () => {
    let giaDichVu = 0;
    listAtrribute?.forEach((attributes: any[]) => {
      let attributePrice: number = attributes ? attributes?.reduce((acc: any, attribute: any) => acc + (attribute?.price ? +attribute?.price : 0), 0) : 0;
      giaDichVu += attributePrice;
    });
    return giaDichVu;
  };

  const getServicePrice = () => {
    const priceAttribute = listThuocTinh?.find((item: any) => item?.isPriceBracket)?.attributes?.[0];
    if (priceAttribute?.minPrice || priceAttribute?.price) {
      return <>{!priceAttribute?.minPrice
        ? <p>{formatPrice(priceAttribute?.price) + " đ/" + priceAttribute?.unit}</p>
        : <>
          <div>{formatPrice(priceAttribute?.price) + " đ/" + priceAttribute?.unit}</div>
          <em className="fs-5">(Tối thiểu {formatPrice(priceAttribute?.minPrice) + "đ"} nếu dưới {(priceAttribute?.minPrice / priceAttribute?.price).toFixed(1)}{priceAttribute?.unit})</em>
        </>
      }</>
    }
    return ''
  };
  const handleGetThongTinSanPham = async (id: string) => {
    dispatch(setPageLoading(true));
    try {
      const result = await getDetailProduct(id);
      if (result?.data?.code === CODE_SUCCESS) {
        setThongTinSanPham({
          ...thongTinSanPham,
          product: result?.data?.data?.product,
        });
        const attributeTypes = result?.data?.data?.attributeTypes;
        if (attributeTypes) {
          let newArr: any[] = [];
          handleSortListAttributeType(attributeTypes);
          attributeTypes?.forEach((pItem: any) => {
            let temp = pItem?.attributes?.map((item: any) => {
              return {
                ...item,
                isSelected: false
              }
            });
            newArr.push({
              ...pItem,
              attributes: temp,
            });
          })
          setListThuocTinh(newArr);
        }
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const handleChangThietLapGiaQuantity = (e: React.ChangeEvent<HTMLInputElement>, data: any, index: number) => {
    const checkQuantity = +e.target.value >= 0 ? e.target.value : 0

    formik.setFieldValue('thietLapGiaQuantity', checkQuantity);
    let newArr = [...listAtrribute];
    let thietLapGiaAttribute = data?.attributes?.[0];

    newArr[index] = [{
      ...thietLapGiaAttribute,
      quantity: Number(checkQuantity),
      price: thietLapGiaAttribute?.minPrice
        ? Math.max((Number(checkQuantity) * thietLapGiaAttribute?.price), Number(thietLapGiaAttribute?.minPrice))
        : Number(checkQuantity) * thietLapGiaAttribute?.price
    }];

    setListAtrribute(newArr);
  };

  const handleChangeQuantityInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setThongTinSanPham({
      ...thongTinSanPham,
      product: { ...thongTinSanPham.product, quantity: +e.target.value },
    });
  };

  const handleChangQuantityButton = (name: string) => {
    if (name === "increase") {
      setQuantity((quantity) => (quantity += 1));
    } else {
      if (quantity > 1) {
        setQuantity((quantity) => (quantity -= 1));
      }
    }
  };

  const handleAddProductToCart = () => {
    const productIndex = cartListReducer.findIndex(
      (item) => item?.product?.id === thongTinSanPham?.product?.id
    );

    if (productIndex !== -1) {
      toast.warning(MESSAGE_SUCCESS.addProductToCartWarning);
    } else {
      let attributeTypes: any[] = [];
      listAtrribute.forEach((pItem: any) => {
        if (pItem?.length > 0) {
          let typeId: string = '';
          let attributes = pItem?.map((item: any) => {
            typeId = item?.typeId;
            return {
              ...item,
              // totalPrice: item.price
            }
          })

          let result = {
            attributes,
            id: typeId,
          };
          attributeTypes.push(result);
        }
      });

      const data = {
        product: {
          ...thongTinSanPham.product,
          quantity: quantity,
          price: totalPriceSanPham,
          subTotal: thongTinSanPham?.product?.price + getTotalServicesPrice(),
          totalPrice: totalPriceSanPham
        },
        attributeTypes,
      };
      dispatch(addToCart(data));
      handleClose();
    }
  };

  const handleChangeListAttribute = (
    index: number,
    data: any
  ) => {
    let newArr = [...listAtrribute];
    newArr[index] = data;
    setListAtrribute(newArr);
  };

  return (
    <Modal show={true} size="lg" onHide={handleClose} centered>
      <Modal.Body>
        <Container>
          <Row className="mt-4">
            <Col xs={4} className="me-2">
              <Image
                style={{ objectFit: "cover" }}
                className="img-fluid"
                src={
                  thongTinSanPham?.product?.logoUrl
                    ? thongTinSanPham?.product?.logoUrl
                    : DEFAULT_IMG_URL
                }
                rounded
                alt="image"
              />
            </Col>
            <Col xs>
              <Row className="min-h-50px">
                <h3 className="pb-4 text-success">
                  {thongTinSanPham?.product?.name ?? ""}
                </h3>
              </Row>
              <Row className="align-items-end mt-3">
                <Col xs={2}>
                  <h5 className="opacity-75">Loại</h5>
                </Col>
                <Col xs>
                  <p className="fs-2 text-info mb-0">
                    {thongTinSanPham?.product?.productTypeName ?? ""}
                  </p>
                </Col>
              </Row>
              <Row className="align-items-start mt-3">
                <Col xs={2}>
                  <h5 className="opacity-75 mt-2">
                    {isHangHoa ? "Đơn giá" : "Giá d.vụ"}
                  </h5>
                </Col>
                <Col xs className="fs-3">
                  {isHangHoa ? (
                    <p className="fs-2 text-dark mb-0">
                      {thongTinSanPham?.product?.price
                        ? formatPrice(thongTinSanPham?.product?.price) + "đ"
                        : ""}
                    </p>
                  ) : <p className="text-dark mb-0">
                    {getServicePrice()}
                  </p>}
                </Col>
              </Row>
              {isHangHoa && (
                <Row className="mt-5 d-flex justify-content-space-between align-items-center">
                  <Col xs={3}>
                    <h5 className="opacity-75 mb-0">Số lượng</h5>
                  </Col>
                  <Col xs>
                    {isView ? (
                      <p className="h2 mb-0 mx-1">
                        {thongTinSanPham?.product?.quantity ?? ""}
                      </p>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <button
                          className="border"
                          onClick={() => handleChangQuantityButton("decrease")}
                        >
                          -
                        </button>
                        <input
                          className="w-35px border text-center"
                          style={{ outline: "none" }}
                          value={quantity}
                          onChange={handleChangeQuantityInput}
                        />
                        <button
                          className="border"
                          onClick={() => handleChangQuantityButton("increase")}
                        >
                          +
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row className="mt-5">
            <h5 className="opacity-75">Mô tả</h5>
            <p className="lead">
              {thongTinSanPham?.product?.description ?? ""}
            </p>
          </Row>

          {listThuocTinh?.map((pItem: any, pIndex: number) => {
            const attributesTypeName = {
              isThietLapGia: pItem?.name === ATTRIBUTE_TYPE_NAME.THIET_LAP_GIA,
              isDichVuPhuThem: pItem?.name === ATTRIBUTE_TYPE_NAME.DICH_VU_PHU_THEM,
              isHuongXaVai: pItem?.name === ATTRIBUTE_TYPE_NAME.HUONG_XA_VAI,
            }
            return <>
              {pItem?.attributes?.length > 0 && <Row className="mt-3 mb-4">
                {!attributesTypeName.isThietLapGia && <h5 className="opacity-75">{pItem?.name}</h5>}
                {attributesTypeName.isThietLapGia
                  ? <>
                    {!isView && <Col xs={4} className="me-2">
                      <InputGroup>
                        <Form.Control
                          type="number"
                          size="lg"
                          value={formik.values.thietLapGiaQuantity === 0 ? 0 : formik.values.thietLapGiaQuantity || ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangThietLapGiaQuantity(e, pItem, pIndex)}
                          isInvalid={formik.touched.thietLapGiaQuantity && Boolean(formik.errors.thietLapGiaQuantity)}
                        />
                        <InputGroup.Text className='rounded-end'>{pItem?.attributes?.[0]?.unit}</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">{formik.errors.thietLapGiaQuantity}</Form.Control.Feedback>
                      </InputGroup>
                    </Col>}
                  </>
                  : <>
                    {attributesTypeName.isDichVuPhuThem ? <div className="d-flex gap-2">
                      {pItem?.attributes?.map((item: any, index: number) => {
                        return (
                          <CustomCheckbox
                            label={item?.name}
                            onChange={(e) => {
                              let newArr: any[] = [...listThuocTinh];
                              newArr[pIndex].attributes[index] = {
                                ...newArr[pIndex].attributes[index],
                                isSelected: e.target.checked
                              };
                              setListThuocTinh(newArr);
                              const dataAttributes = newArr[pIndex].attributes?.filter((attribute: any) => attribute?.isSelected);
                              handleChangeListAttribute(pIndex, dataAttributes)
                            }}
                            className="mx-2"
                          />
                        );
                      })}
                    </div>
                      : <div className="d-flex gap-2">
                        {pItem?.attributes?.map((item: any, index: number) => {
                          return (
                            <CustomCheckbox
                              label={item?.name}
                              // checked={index % 2 === 0 ? false : true}
                              onChange={(e) => {
                                let newArr: any[] = [...listThuocTinh];
                                newArr[pIndex].attributes[index] = {
                                  ...newArr[pIndex].attributes[index],
                                  isSelected: e.target.checked
                                };
                                setListThuocTinh(newArr);
                                const dataAttributes = newArr[pIndex].attributes?.filter((attribute: any) => attribute?.isSelected);
                                handleChangeListAttribute(pIndex, dataAttributes)
                              }}
                              className="mx-2"
                            />
                          );
                        })}
                      </div>}
                  </>}
              </Row>}
            </>
          })}

          {!isView && (
            <Row className="mt-5">
              <Col xs="2">
                <h4>Tổng:</h4>
              </Col>
              <Col>
                <h4>
                  {formatPrice(
                    listThuocTinh?.length > 0
                      ? getTotalServicesPrice()
                      : totalPriceSanPham
                  )}{" "}
                  đ
                </h4>
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer className="p-4">
        <Button size="sm" variant="secondary" onClick={handleClose}>
          Đóng
        </Button>
        {!isView && (
          <Button size="sm" onClick={() => formik.handleSubmit()}>
            Thêm vào giỏ hàng
          </Button>
        )}
      </Modal.Footer>
      {loading && <PageLoading />}
    </Modal>
  );
};

export default ThongTinSanPhamDialog;
