import { ICompainon, IListJoin, IWelfare } from "../model/WelfareModel";
export const  phucLoiArray: IWelfare[] = [
  {
    tenPhucLoi: "Phúc lợi 1",
    nhomPhucLoi: "Nhóm A",
    donViApDung: "Đơn vị A",
    ngayBatDau: "01/01/2023",
    ngayKetThuc: "31/01/2023",
    diaDiemToChuc: "Địa điểm A",
    mucDichToChuc: "Mục đích A",
    hinhThucThucHien: "Hình thức A",
    soNguoiDuKien: "100",
    trangThai: "Đang diễn ra",
  },
  {
    tenPhucLoi: "Phúc lợi 2",
    nhomPhucLoi: "Nhóm B",
    donViApDung: "Đơn vị B",
    ngayBatDau: "01/02/2023",
    ngayKetThuc: "28/02/2023",
    diaDiemToChuc: "Địa điểm B",
    mucDichToChuc: "Mục đích B",
    hinhThucThucHien: "Hình thức B",
    soNguoiDuKien: "50",
    trangThai: "Đã kết thúc",
  },
  {
    tenPhucLoi: "Phúc lợi 3",
    nhomPhucLoi: "Nhóm C",
    donViApDung: "Đơn vị C",
    ngayBatDau: "01/03/2023",
    ngayKetThuc: "31/03/2023",
    diaDiemToChuc: "Địa điểm C",
    mucDichToChuc: "Mục đích C",
    hinhThucThucHien: "Hình thức C",
    soNguoiDuKien: "200",
    trangThai: "Đang diễn ra",
  },
];
export const thamGiaArray: IListJoin[] = [
  {
    hoVaTen: "Nguyễn Văn A",
    maNhanVien: "NV001",
    donViApDung: "Phòng Nhân sự",
    viTriCongViec: "Quản lý nhân sự",
    soTienNhanVienDong: "1000000",
    soTienCongTyHoTro: "500000",
    trangThai: "Đang làm việc",
  },
  {
    hoVaTen: "Trần Thị B",
    maNhanVien: "NV002",
    donViApDung: "Phòng Kế toán",
    viTriCongViec: "Kế toán viên",
    soTienNhanVienDong: "800000",
    soTienCongTyHoTro: "400000",
    trangThai: "Đang làm việc",
  },
  {
    hoVaTen: "Lê Văn C",
    maNhanVien: "NV003",
    donViApDung: "Phòng Kỹ thuật",
    viTriCongViec: "Kỹ sư",
    soTienNhanVienDong: "1200000",
    soTienCongTyHoTro: "600000",
    trangThai: "Đang làm việc",
  },
];
export const dikemArray:ICompainon[]=[
  {
    hoVaTen:"Le Ba Long",
    quanHe:"Chau trai"
  },
  {
    hoVaTen:"Le Ba Long 2",
    quanHe:"Chau trai 2"
  }
]