import { Formik } from 'formik';
import { FC, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { GroupButton } from '../../component/GroupButton';
import {
    ResignInfo,
    initResign
} from '../models/IResign';
import { TextFieldCustom } from './TextFieldCustom';
import { listStatusResign } from '../const/ResignConst';
interface IProps {
    view: boolean;
    handleOpenUpdateDialog: () => void;
    handleCloseDialog: () => void;
    handleCloseUpdateDialog: () => void;
    resignInfo: ResignInfo;
    employeeId: string;
    mutateResign: () => Promise<void>
}
const AddNewResign: FC<IProps> = (props) => {
    const {
        handleCloseDialog,
        view,
        handleOpenUpdateDialog,
        resignInfo,
        handleCloseUpdateDialog,
    } = props;

    const intl = useIntl();

    const [shouldOpenAddContract, setShouldOpenAddContract] = useState<boolean>(true);

    const contractValidationSchema = Yup.object().shape({
        tenNguoiLaoDong: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
            .max(255, intl.formatMessage({ id: 'VALIDATION.MAX255' })).nullable(),
        employeeCode: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
            .max(255, intl.formatMessage({ id: 'VALIDATION.MAX255' })).nullable(),
    });

    return (
        <>
            {shouldOpenAddContract && (
                <Formik
                    initialValues={
                        resignInfo.id ? resignInfo : initResign
                    }
                    validationSchema={contractValidationSchema}
                    onSubmit={async (values) => {
                        console.log(values);
                        // Xử lý submit form
                    }}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Col>
                                <Row className='contract-header'>
                                    <Col xs={12}>
                                        <div className='profile-title'>
                                            {view && (
                                                <>
                                                    <GroupButton
                                                        type='btn-back'
                                                        handleClose={
                                                            handleCloseDialog
                                                        }
                                                    />
                                                    <GroupButton
                                                        type='btn-edit'
                                                        handleSaveEdit={
                                                            handleOpenUpdateDialog
                                                        }
                                                    />
                                                </>
                                            )}
                                            {!resignInfo.employeeCode && !view && (
                                                <>
                                                    <span className='fs-3 fw-bold'>
                                                        {intl.formatMessage({
                                                            id: 'CONTRACT.ADD'
                                                        })}
                                                        <i className='bi bi-three-dots-vertical'></i>
                                                    </span>
                                                    <GroupButton
                                                        type='btn-save'
                                                        handleClose={
                                                            handleCloseDialog
                                                        }
                                                    />
                                                </>
                                            )}
                                            {resignInfo.employeeCode && !view && (
                                                <>
                                                    <GroupButton
                                                        type='btn-back'
                                                        handleClose={
                                                            handleCloseUpdateDialog
                                                        }
                                                    />
                                                    <GroupButton
                                                        type='btn-save'
                                                        handleClose={
                                                            handleCloseUpdateDialog
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-4 bg-light contract-info pb-12'>
                                    <Col xs={12}>
                                        <span className='fs-3 fw-bold'>
                                            {intl.formatMessage({
                                                id: 'INFO.MAIN'
                                            })}
                                        </span>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    required={true}
                                                    name='employeeCode'
                                                    title='RESIGN.EMPLOYEECODE'
                                                    type='text'
                                                    readOnly={view}
                                                    values={
                                                        values.employeeCode || ''
                                                    }
                                                    errors={
                                                        errors.employeeCode
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    required={true}
                                                    name='tenNguoiLaoDong'
                                                    title='GENERAL.FULLNAME'
                                                    type='text'
                                                    readOnly={view}
                                                    values={
                                                        values.tenNguoiLaoDong ? values.tenNguoiLaoDong : ''
                                                    }
                                                    errors={errors.tenNguoiLaoDong}
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    name='donViCongTac'
                                                    title='GENERAL.CURRENT_ORGANIZATION'
                                                    type='text'
                                                    readOnly={view}
                                                    values={
                                                        values.donViCongTac || ''
                                                    }
                                                    errors={
                                                        errors.donViCongTac
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    name='viTriCongViecText'
                                                    title='GENERAL.EMPLOYEE.POSITION'
                                                    type='text'
                                                    readOnly={view}
                                                    values={
                                                        values.viTriCongViecText || ''
                                                    }
                                                    errors={
                                                        errors.viTriCongViecText
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    name='lyDoNghiViec'
                                                    title='RESIGN.REASON'
                                                    type='text'
                                                    readOnly={view}
                                                    values={
                                                        values.lyDoNghiViec || ''
                                                    }
                                                    errors={
                                                        errors.lyDoNghiViec
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    name='ngayNghiViec'
                                                    title='RESIGN.DATE'
                                                    type='date'
                                                    readOnly={view}
                                                    values={
                                                        values.ngayNghiViec || ''
                                                    }
                                                    errors={
                                                        errors.ngayNghiViec
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    name='soQuyetDinh'
                                                    title='INPUT.WORKPROCESS.DECISION.NUMBER'
                                                    type='text'
                                                    readOnly={view}
                                                    values={
                                                        values.soQuyetDinh || ''
                                                    }
                                                    errors={
                                                        errors.soQuyetDinh
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <TextFieldCustom
                                                    required={true}
                                                    name='trangThai'
                                                    title='RESIGN.STATUS'
                                                    type='select'
                                                    readOnly={view}
                                                    values={
                                                        values.trangThai || ''
                                                    }
                                                    errors={
                                                        errors.trangThai
                                                    }
                                                    optionsByAPI={
                                                        listStatusResign
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>
                                            <TextFieldCustom
                                                name='fileName'
                                                title='GENERAL.INFO.ATTACHMENTS'
                                                type='file'
                                                readOnly={view}
                                                values={
                                                    values.fileName || ''
                                                }
                                                errors={
                                                    errors.fileName
                                                }
                                                handleChange={handleChange}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
export { AddNewResign };