export interface ResignInfo {
    createdBy?: string | null;
    createDate?: number[] | null;
    modifiedBy?: string | null;
    modifyDate?: number[] | null;
    id?: string | null;
    donViCongTac?: string | null; 
    employeeId?: string | null;
    viTriCongViecId: string | null;
    viTriCongViecText?: string | null;
    viTriCongViecMoiText?: string | null;
    donViCongTacMoi?: string | null;
    tenNguoiLaoDong: string | null;
    soQuyetDinh?: string| null;
    trangThai?: string | null;
    employeeCode: string | null;
    lyDoNghiViec?: string | null;
    ngayNghiViec?: string | null;
    fileId?: string;
    fileName?: string
}

export const initResign: ResignInfo = {
    id: '',
    donViCongTac:'',
    employeeId: '',
    viTriCongViecId: '',
    viTriCongViecText: '',
    donViCongTacMoi: '',
    tenNguoiLaoDong: '',
    employeeCode: ''
};

export interface OptionType {
    id?: number;
    title: string;
    value: string;
}

export interface statusType {
    id: number;
    name: string
}
