import { Column } from 'react-table';
import { TableCustomHeader } from '../../component/table-custom/columns/TableCustomHeader';
import { TableCustomCell } from '../../component/table-custom/columns/TableCustomCell';
import { TransitionInFo } from '../models/transitionModel';
import { useCustomIntl } from '../../utils/FunctionUtils';

const TransitionColumn: ReadonlyArray<Column<TransitionInFo>> = [
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('PROFILE.CODE')}
        className='text-center text-light min-w-100px '
      />
    ),
    id: 'PROFILE.CODE',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].code ||'-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('PROFILE.NAME')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'PROFILE.NAME',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].fullName ||'-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('INPUT.WORKPROCESS.UNIT')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'INPUT.WORKPROCESS.UNIT',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].donViCongTac ||'-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('INPUT.WORKPROCESS.ROLE')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'INPUT.WORKPROCESS.ROLE',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].viTriCongViec ||'-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('INPUT.WORKPROCESS.UNIT.NEW')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'INPUT.WORKPROCESS.UNIT.NEW',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].donViCongTacMoiText ||'-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('INPUT.WORKPROCESS.ROLE.NEW')}
        className='text-center text-light min-w-175px '
      />
    ),
    id: 'INPUT.WORKPROCESS.ROLE.NEW',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].viTriCongTacMoiText || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('INPUT.WORKPROCESS.DECISION.NUMBER')}
        className='text-center text-light min-w-100px '
      />
    ),
    id: 'INPUT.WORKPROCESS.DECISION.NUMBER',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].soQuyetDinh || '-'} />
      );
    }
  },
  {
    Header: (props) => (
      <TableCustomHeader<TransitionInFo>
        tableProps={props}
        title={useCustomIntl('GENERAL.STATUS')}
        className='text-center text-light min-w-100px '
      />
    ),
    id: 'GENERAL.STATUS',
    Cell: ({ ...props }) => {
      return (
        <TableCustomCell data={props.data[props.row.index].transferStatus || '-'} />
      );
    }
  },
];

export { TransitionColumn };
