import { FC, useState } from "react";
import { TableCustom } from "../component/table-custom/TableCustom";
import { IDismissed } from "./model/IDismissed";
import { dismissed } from "./const/DismissedConst";
import { DismissedColumn } from "./components/DismissedColumn";
import { useIntl } from "react-intl";
import { DismissedDialog } from "./components/DismissedDialog";
const DismissedList: FC = () => {
  const intl = useIntl();
  const [dismissedList, setDismissedList]=useState<IDismissed[]>(dismissed)
  const [dismissedInfo,setDismissedInfo] = useState<IDismissed>({}as IDismissed)
  const [openDismissedDialog, setOpenDismissedDialog] = useState<boolean>();
    const handleOpenAppointmentDialog = (info: any) => {
      setOpenDismissedDialog(true);
      setDismissedInfo(info.original);
    };
    const handleCLoseAppointmentDialog = () => {
      setOpenDismissedDialog(false);
      setDismissedInfo({} as IDismissed);
    };
  return (
    <>
      <div className="profile-title">
        <span className="fs-3 fw-bold">
          {intl.formatMessage({ id: "GENERAL.DISMISSED.LIST" })}
          <i className="bi bi-three-dots-vertical"></i>
        </span>
        <button
          className="btn btn-primary btn-sm btn-sm"
          onClick={() => {
            setOpenDismissedDialog(true);
          }}
        >
          {intl.formatMessage({ id: "BTN.ADD" })}
        </button>
      </div>
      <TableCustom<IDismissed>
        columns={DismissedColumn}
        data={dismissedList}
        compact={false}
        handleOpenInfoDialog={handleOpenAppointmentDialog}
      />
      {openDismissedDialog && (
        <DismissedDialog dismissedInfo={dismissedInfo} handleCLose={handleCLoseAppointmentDialog} />
      )}
    </>
  );
};
export { DismissedList }
