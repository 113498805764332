import { Column } from 'react-table';
import { TableCustomHeader } from '../../../component/table-custom/columns/TableCustomHeader';
import { TableCustomCell } from '../../../component/table-custom/columns/TableCustomCell';
import { SanPham } from '../../models/ISanPham';
import { formatPrice } from '../../../utils/FunctionUtils';
import DonVi from '../DonVi';
import ClickableCell from '../../../component/ClickableCell';
import ActionTable from '../ActionTable';

const DichVuColumnAdmin: ReadonlyArray<Column<SanPham>> = [
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={"STT"}
                className='text-center min-w-50px '
            />
        ),
        id: 'stt',
        Cell: ({ ...props }) => {
            let stt = props.row.index + 1;
            return (
                <TableCustomCell
                    className='text-center'
                    data={stt ?? '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={"Tên dịch vụ"}
                className='min-w-200px'
            />
        ),
        id: 'tendv',
        Cell: ({ ...props }) => {
            let name = props.data[props.row.index].name;
            return (
                <TableCustomCell
                    className={!name ? 'text-center' : ''}
                    data={name ? <ClickableCell data={name} rowData={props.data[props.row.index]} /> : '-'}
                    action={true}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={"Loại"}
                className='min-w-150px'
            />
        ),
        id: 'loaidv',
        Cell: ({ ...props }) => {
            let type = props.data[props.row.index].productTypeName;
            return (
                <TableCustomCell
                    className='text-success'
                    data={type ?? '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={'Mô tả'}
                className='min-w-200px'
            />
        ),
        id: 'mota',
        Cell: ({ ...props }) => {
            let description = props.data[props.row.index].description;
            return (
                <TableCustomCell
                    className={`${!description ? 'text-center' : ''} hidden-row`}
                    data={description ?? '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={'Thuộc đơn vị'}
                className='min-w-200px'
            />
        ),
        id: 'donvi',
        Cell: ({ ...props }) => {
            let orgId = props.data[props.row.index].orgId;
            return (
                <TableCustomCell
                    className='text-success'
                    data={orgId ? <DonVi orgId={orgId as string}/> : '-'}
                />
            );
        }
    },
];

const DichVuColumnTiemGiat: ReadonlyArray<Column<SanPham>> = [
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={"STT"}
                className='text-center min-w-50px '
            />
        ),
        id: 'stt',
        Cell: ({ ...props }) => {
            let stt = props.row.index + 1;
            return (
                <TableCustomCell
                    className='text-center'
                    data={stt ?? '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={"Tên dịch vụ"}
                className='min-w-200px'
            />
        ),
        id: 'tendv',
        Cell: ({ ...props }) => {
            let name = props.data[props.row.index].name;
            return (
                <TableCustomCell
                    className={!name ? 'text-center' : ''}
                    data={name ? <ClickableCell data={name} rowData={props.data[props.row.index]} /> : '-'}
                    action={true}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={"Loại"}
                className='min-w-150px'
            />
        ),
        id: 'loaidv',
        Cell: ({ ...props }) => {
            let type = props.data[props.row.index].productTypeName;
            return (
                <TableCustomCell
                    className='text-success'
                    data={type ?? '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={'Mô tả'}
                className='min-w-200px'
            />
        ),
        id: 'mota',
        Cell: ({ ...props }) => {
            let description = props.data[props.row.index].description;
            return (
                <TableCustomCell
                    className={`${!description ? 'text-center' : ''} hidden-row`}
                    data={description ?? '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<SanPham>
                tableProps={props}
                title={'Thao tác'}
                className='text-center min-w-100px'
            />
        ),
        id: 'action',
        Cell: ({ ...props }) => {
            return (
                <TableCustomCell
                    className='text-center'
                    data={<ActionTable
                        rowData={props.data[props.row.index]}
                    />}
                />
            );
        }
    },
];

export { DichVuColumnAdmin, DichVuColumnTiemGiat };
