import { Card, Col, Container, Row } from "react-bootstrap";
import { formatPrice } from "../../utils/FunctionUtils";
import {
  ISanPham,
  SanPham as SanPhamModel,
} from "../../danh-sach-san-pham/models/ISanPham";
import { useParams } from "react-router-dom";
import { DEFAULT_IMG_URL, PRODUCT_TYPE } from "../../danh-sach-san-pham/constants";

type Props = {
  item: ISanPham & SanPhamModel;
  handleOpenInfoProduct: (item: ISanPham & SanPhamModel) => void;
};

const SanPham = (props: Props) => {
  const { item, handleOpenInfoProduct } = props;
  const { typePos } = useParams();

  const isHangHoa = item?.productType?.includes(
    PRODUCT_TYPE.HANG_HOA
  );

  const renderPriceService = () => {
    return item?.price as number > (item?.pricePerUnit as number)
      ? <>
        <div>{formatPrice(item?.pricePerUnit) + " đ/" + item?.unit}</div>
        <em className="fs-5 fw-normal">(Tối thiểu {formatPrice(item?.price) + "đ"} nếu dưới {(item?.price as number / (item?.pricePerUnit as number)).toFixed(1)}{item?.unit})</em>
      </>
      : <div>{formatPrice(item?.pricePerUnit) + " đ/" + item?.unit}</div>
  }

  return (
    <div
      onClick={() => handleOpenInfoProduct(item as ISanPham & SanPhamModel)}
      className=" border w-150px h-auto text-center p-0 rounded-4 m-3 shadow-sm"
      style={{ cursor: "pointer" }}
    >
      <Row>
        <Col>
          <Card.Img
            style={{
              borderRadius: "1rem 1rem 0rem 0rem",
              borderBottom: "1px solid #ddd",
              height: "calc(150px/0.916)",
              objectFit: "cover"
            }}
            variant="top"
            src={
              item?.logoUrl || DEFAULT_IMG_URL
              // "https://down-vn.img.susercontent.com/file/b40aff467383fa7d8650859f512fa846"
            }
          />
        </Col>
      </Row>

      <Row className="my-3">
        <Card.Title className="mb-2 hidden-row px-3">{item?.name}</Card.Title>
        {/* {typePos === "dai-ly" && ( */}
        <Card.Text className="fw-semibold fs-5">
          {isHangHoa ? formatPrice(item?.price as number) + "₫" : renderPriceService()}
        </Card.Text>
        {/* )} */}
      </Row>
    </div>
  );
};

export default SanPham;
