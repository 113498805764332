/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useAuth } from "../core/Auth";
import { UserModelLogin } from "../core/_models";
import { localStorageServices } from "../../utils/LocalStorage";
import { getMenuByUser } from "../services/AuthServices";
import { CODE_SUCCESS, LOCAL_STORAGE_KEYS, ROLES } from "../../../../constants";
const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

interface MyJwtPayload extends JwtPayload {
  authorities: string[];
}

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [loginUser, setLoginUser] = useState<{
    username: string;
    password: string;
  }>({ username: "", password: "" });

  function getPermissions(authorities: { [key: string]: boolean }) {
    let permissions = {
      isRoleAdmin: false,
      isRoleAgency: false,
      isRoleLaundry: false,
    };

    switch (true) {
      case authorities?.[ROLES.ROLE_ADMIN]:
        return {
          ...permissions,
          isRoleAdmin: true,
        }
      case authorities?.[ROLES.ROLE_AGENCY]:
        return {
          ...permissions,
          isRoleAgency: true,
        }
      case authorities?.[ROLES.ROLE_LAUNDRY]:
        return {
          ...permissions,
          isRoleLaundry: true,
        }
      default:
        return permissions
    }
  }

  const formik = useFormik({
    initialValues: loginUser,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.username, values.password);
        saveAuth(auth);
        const dataUser = jwt_decode(auth.access_token) as {
          user: UserModelLogin;
        };
        if (dataUser.user.authorities) {
          const permissionObj: { [key: string]: boolean } = {};
          for (const permission of dataUser.user.authorities) {
            permissionObj[permission] = true;
          }
          localStorageServices.setSessionItem(LOCAL_STORAGE_KEYS.AUTHORITIES,getPermissions(permissionObj));
          localStorage.setItem(LOCAL_STORAGE_KEYS.AUTHORITIES, JSON.stringify(permissionObj));
        }

        const result = await getMenuByUser();
        if (result?.status === CODE_SUCCESS) {
          localStorageServices.setSessionItem(LOCAL_STORAGE_KEYS.NAVIGATION_LOCAL, result?.data);
        }

        localStorageServices.setSessionItem(LOCAL_STORAGE_KEYS.CURRENT_USER, dataUser.user);
        setCurrentUser(dataUser.user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("Thông tin đăng nhập chưa chính xác");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-20">
        {/* <h1 className='text-gray-500 fw-semibold'>Goodhere</h1> */}
        <img
          className="w-100px mb-5"
          src="https://goodhere.vn/storage/app/public/business/2022-10-19-634ffe37bef86.png"
          alt="logo"
        />
        <h2 className="text-dark fw-bolder mb-3">Đăng nhập</h2>
      </div>
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">
          Tên đăng nhập
        </label>
        <input
          {...formik.getFieldProps("username")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.username && formik.errors.username },
            {
              "is-valid": formik.touched.username && !formik.errors.username,
            }
          )}
          value={loginUser.username}
          type="email"
          name="email"
          autoComplete="off"
          onChange={(e) => {
            formik.setFieldValue("username", e.target.value);
            setLoginUser({ ...loginUser, username: e.target.value });
          }}
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.username}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6">Mật khẩu</label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
          value={loginUser.password}
          onChange={(e) => {
            formik.setFieldValue("password", e.target.value);
            setLoginUser({ ...loginUser, password: e.target.value });
          }}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />
        <Link to="/auth/forgot-password" className="link-primary">
          Quên mật khẩu?
        </Link>
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Tiếp tục</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Vui lòng đợi...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <div className="text-gray-500 text-center fw-semibold fs-6">
        Chưa có tài khoản?{" "}
        <Link to="/auth/registration" className="link-primary">
          Đăng ký
        </Link>
      </div>
    </form>
  );
}
