import "./StatusColor.scss";

type Props = {
  children: any;
  type?: "success" | "pending" | "cancel" | "name";
};

const StatusColor = (props: Props) => {
  const { children, type } = props;
  return (
    <span className={`${type ? "bullet-" + type : "default"}`}>
      {type !== "name" && type && (
        <span>
          <i className="fa-solid fa-circle"></i>
        </span>
      )}{" "}
      {children}
    </span>
  );
};

export default StatusColor;
