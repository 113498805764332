import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TableCustom } from "../component/table-custom/TableCustom";
import "./Order.scss";
import {
  getOrderById,
  getListDonHang,
  deleteDonHang,
  updateDonHang,
} from "./services/DonHangServices";
import { toast } from "react-toastify";
import { IOrder, ISearchDonHang } from "./models/OrderModels";
import { TableCustomHeader } from "../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../component/table-custom/columns/TableCustomCell";
import {
  LIST_STATUS_DON_HANG,
  LIST_TYPE_POS,
  STATUS_PAY,
  STATUS_PROCESSING,
  STATUS_SUCCESS,
  TYPE_OF_CILENT,
  TYPE_ORDER,
} from "./const/OrderConst";
import { Column } from "react-table";
import HoaDonDialog from "./components/HoaDonDialog";
import {
  CODE_SUCCESS,
  ERROR_MESSAGE,
  LOCAL_STORAGE_KEYS,
  MESSAGE_SUCCESS,
  SESSION_STORAGE_KEYS,
} from "../../../constants";
import StatusColor from "../component/status-color/StatusColor";
import { formatExportDate, formatPrice } from "../utils/FunctionUtils";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import CustomIconButton from "../component/custom-icon-button/CustomIconButton";
import { useAppDispatch, useAppSelector } from "../../hook";
import { detailDonHang } from "./reducer/DonHangSlice";
import { localStorageServices } from "../utils/LocalStorage";
import { ROLES } from "../pos/const/PosConst";
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TOTAL_PAGES,
} from "../category-personnel/const/PersonnelConst";
import { TablePagination } from "../component/pagination-custom/TablePagination";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../utils/PageUtils";
import PageLoading from "../component/page-loading/PageLoading";
import { setPageLoading } from "../component/page-loading/PageLoadingReducer";
import { resetCartList } from "../pos/reducer/PosSlice";
import ConfirmDialog from "../component/ConfirmDialog";
import InputSearch from "../component/InputSearch";

function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}

const Order: FC = () => {
  const dispatch = useAppDispatch();
  const { type } = useParams();
  const [listDonHang, setListDonHang] = useState<IOrder[]>([]);
  const [donHang, setDonHang] = useState<any>({});
  const [idDonHang, setIdDonHang] = useState<string | number>()
  const [shouldOpenDialogHoaDon, setShouldOpenDialogHoaDon] =
    useState<boolean>(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const currentUser = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.CURRENT_USER
  );
  const permissions = localStorageServices.getSessionItem(SESSION_STORAGE_KEYS.AUTHORITIES)

  const [keywordSearch, setKeywordSearch] = useState<string | null>(null)
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowPerPage, setRowPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES);
  const [totalElements, setTotalElements] =
    useState<number>(DEFAULT_TOTAL_PAGES);
  const statusDonHang = LIST_STATUS_DON_HANG.find((item) => item.name === type);
  const loading = useAppSelector((state) => state.pageLoading.loading);
  const isDisplayRoleTiemGiat =
    LIST_TYPE_POS.includes(type as string)
    &&
    (permissions.isRoleLaundry
      ? permissions.isRoleLaundry
      :
      permissions.isRoleAgency)


  useEffect(() => {
    type && setPageIndex(DEFAULT_PAGE_INDEX);
  }, [type]);

  useEffect(() => {
    handleGetListDonHang();
  }, [statusDonHang, pageIndex, rowPerPage, reloadData, keywordSearch]);

  const handleSearch = (data: string | null) => {
    setKeywordSearch(data)
  }

  const handleGetListDonHang = async () => {
    let object: ISearchDonHang = {
      code: keywordSearch,
      pageIndex: pageIndex,
      pageSize: rowPerPage,
      statusId: statusDonHang?.id ? statusDonHang?.id : "",
      orgId: !currentUser?.roles?.includes(ROLES.ADMIN) ? currentUser?.orgId : "",
      type: !LIST_TYPE_POS.includes(type as string) ? TYPE_ORDER.ONLINE.id : TYPE_ORDER.OFFLINE.id,
    };
    dispatch(setPageLoading(true));
    try {
      const result = await getListDonHang(object);
      if (result?.data?.code === CODE_SUCCESS) {
        setListDonHang(result?.data?.data?.content);
        setTotalElements(result?.data?.data?.totalElements);
        setTotalPages(result?.data?.data?.totalPages);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const handleShouldOpenDialogHoaDon = (rowData: any) => {
    setShouldOpenDialogHoaDon(true);
    handleGetDonHangById(rowData?.id);
  };

  const handleGetDonHangById = async (id: string) => {
    try {
      const result = await getOrderById(id);
      if (result?.data?.code === CODE_SUCCESS) {
        dispatch(detailDonHang(result?.data?.data));
        dispatch(resetCartList(result?.data?.data?.lstProduct))
        setDonHang(result?.data?.data);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleCloseDialogHoaDon = () => {
    setShouldOpenDialogHoaDon(false);
  };

  const handleOpenDetailSanPham = (rowData: any) => {
    handleGetDonHangById(rowData?.id);
  };

  const handleShouldOpenConfirmDialog = (rowData: any) => {
    setShouldOpenConfirmDialog(true);
    setIdDonHang(rowData?.id);
    handleGetDonHangById(rowData?.id)
  }

  const handleDeleteDonHang = async () => {
    const object = {
      id: idDonHang,
      isDeleted: true,
    }
    try {
      const result = await deleteDonHang(object);
      if (result?.data?.code === CODE_SUCCESS) {
        handleCloseConfirmDialog();
        toast.success(MESSAGE_SUCCESS.deleteDonHang);
        setReloadData(!reloadData);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  }

  const handleCloseConfirmDialog = () => {
    setShouldOpenConfirmDialog(false);
  }

  const OrderColumns: ReadonlyArray<Column<IOrder>> = [
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title="STT"
          className="text-center"
        />
      ),
      id: "stt",
      Cell: ({ ...props }) => {
        return (
          <TableCustomCell className="text-center" data={props.row.index + 1} />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={"Mã đơn hàng"}
          className="text-center min-w-100px"
        />
      ),
      id: "STORE.ORDER",
      Cell: ({ ...props }) => {
        let code = props.data[props.row.index].code;
        return (
          <TableCustomCell
            className="text-center text-success"
            data={!!code ? code : "-"}
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={useCustomIntl("STORE.DAY_OF_CREATION")}
          className="text-center min-w-100px"
        />
      ),
      id: "STORE.DAY_OF_CREATION",
      Cell: ({ ...props }) => {
        let createDate = props.data[props.row.index].createDate;
        return (
          <TableCustomCell
            className="text-center"
            data={createDate ? formatExportDate(createDate) : "-"}
            action={true}
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={"KHÁCH HÀNG"}
          className="text-center min-w-150px"
        />
      ),
      id: "customerName",
      Cell: ({ ...props }) => {
        return (
          <TableCustomCell
            data={
              props.data[props.row.index].customerName ?
                <StatusColor
                  children={props.data[props.row.index].customerName}
                />
                : <div className="d-flex justify-content-center">-</div>
            }
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={"Số điện thoại"}
          className="text-center min-w-100px"
        />
      ),
      id: "phoneNumber",
      Cell: ({ ...props }) => {
        return (
          <TableCustomCell
            className="text-center"
            data={
              props.data[props.row.index].phoneNumber
                ? props.data[props.row.index].phoneNumber
                : "-"
            }
          />
        );
      },
    },

    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={useCustomIntl("STORE.BILL_STATUS")}
          className="text-center min-w-175px"
        />
      ),
      id: "STORE.BILL_STATUS",
      Cell: ({ ...props }) => {
        let paymentName = props.data[props.row.index].paymentName;
        return (
          <TableCustomCell
            className="text-center"
            data={paymentName ?
              <StatusColor
                type={`${paymentName === STATUS_PAY.PAID.name ? "success" : "cancel"}`}
                children={paymentName}
              />
              : "-"
            }
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={useCustomIntl("STORE.PRICE")}
          className="text-center min-w-100px"
        />
      ),
      id: "STORE.PRICE",
      Cell: ({ ...props }) => {
        let price = props.data[props.row.index].price;
        return (
          <TableCustomCell
            className="text-center"
            data={!!price ? formatPrice(+price) + " đ" : "-"}
          />
        );
      },
    },

    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={useCustomIntl("STORE.STATUS")}
          className="text-center min-w-150px"
        />
      ),
      id: "STORE.STATUS",
      Cell: ({ ...props }) => {
        let status = props.data[props.row.index].status;
        return (
          <TableCustomCell
            className="text-center"
            data={
              status ? <StatusColor
                type={`${STATUS_SUCCESS.includes(status)
                  ? "success"
                  : status === STATUS_PROCESSING
                    ? "pending"
                    : "cancel"
                  }`}
                children={status}
              /> : "-"
            }
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<IOrder>
          tableProps={props}
          title={"THAO TÁC"}
          className="text-center min-w-75px"
        />
      ),
      id: "action",
      Cell: ({ ...props }) => {
        return (
          <TableCustomCell
            className="text-center"
            data={
              <div className="d-flex justify-content-center align-items-center gap-2">
                <Link
                  to={
                    type !== undefined
                      ? `/don-hang/${type}/detail`
                      : "/don-hang/pos/detail"
                  }
                >
                  <CustomIconButton
                    variant="visibility"
                    onClick={() => handleOpenDetailSanPham(props.row.original)}
                  />
                </Link>

                <CustomIconButton variant="print" onClick={() =>
                  handleShouldOpenDialogHoaDon(props.row.original)}
                />

                {(permissions.isRoleAdmin || isDisplayRoleTiemGiat) && <CustomIconButton variant="delete" onClick={() =>
                  handleShouldOpenConfirmDialog(props.row.original)}
                />}
              </div>
            }
          />
        );
      },
    },
  ];

  return (
    <div className={`danh-muc-container ${loading ? 'position-relative' : ''}`}>
      <InputSearch handleOnSubmit={handleSearch} placeholder="Tìm kiếm theo mã đơn hàng" />
      <TableCustom<IOrder>
        data={listDonHang}
        columns={OrderColumns}
        compact={false}
        minHeight={550}
        maxHeight={550}
      />
      <TablePagination
        handlePagesChange={handlePagesChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPage={rowPerPage}
        rowsForPage={rowsForPage}
        page={pageIndex}
        setPage={setPageIndex}
        setRowsPerPage={setRowPerPage}
        totalPages={totalPages}
        totalElements={totalElements}
      />
      {shouldOpenDialogHoaDon && (
        <HoaDonDialog
          handleCloseDialog={handleCloseDialogHoaDon}
          donHang={donHang}
        />
      )}
      {loading && <PageLoading />}

      {shouldOpenConfirmDialog &&
        <ConfirmDialog
          Description="Bạn có chắc chắn muốn xóa không?"
          view={shouldOpenConfirmDialog}
          onYesClick={handleDeleteDonHang}
          onCancelClick={handleCloseConfirmDialog}
        />}
    </div>
  );
};
export default Order;
