import { OptionType } from "../models/IIssue";

export const issueTypeOpt: OptionType[] = [
    {
        value: '1',
        title: "Vi phạm kỷ luật"
    },
    {
        value: '2',
        title: "Tai nạn lao động"
    }
]

export const issuePlaceOpt: OptionType[] = [
    {
        value: '1',
        title: "Văn bản"
    },
    {
        value: '2',
        title: "Lời"
    }
]

export const issueReasonOpt: OptionType[] = [
    {
        value: "1", 
        title: "Không tuân thủ quy định về ATLĐ"
    },
    {
        value: "2",
        title: "Không tuân thủ quy tắc bảo mật thông tin"
    },
    {
        value: "3",
        title: "Không tuân thủ nội quy nơi làm việc"
    }
]

export const issueStatusOpt: OptionType[] = [
    {
        value: "1",
        title: "Chưa xử lý"
    },
    {
        value: "2",
        title: "Đang xử lý"
    },
    {
        value: "3",
        title: "Đã xử lý"
    }
]

export const statusEmployeeOpt: OptionType[] = [
    {
        value: "1",
        title: "Chưa thực hiện",
    },
    {
        value: "2",
        title: "Đang thực hiện",
    },
    {
        value: "3",
        title: "Đã hoàn thành",
    }
]

export const formHandlingOpt: OptionType[] = [
    {
        value: "1",
        title: "Cảnh cáo"
    },
    {
        value: "2",
        title: "Đình chỉ công tác"
    },
    {
        value: "3",
        title: "Chuyển công tác"
    },
    {
        value: "4",
        title: "Cho nghỉ việc"
    },
    {
        value: "5",
        title: "Bồi thường bằng tiền mặt"
    },
    {
        value: "6",
        title: "Bồi thường bằng hiện vật"
    }
]