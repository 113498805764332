import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import Select, { GetOptionLabel } from "react-select";

export const KEY = {
    ENTER: "Enter",
    SPACE: "Space",
}

interface AutoCompleteProps {
    options: any[];
    isClearable?: boolean;
    getOptionLabel?: (option: any) => string;
    searchFunction?: (searchObject: object) => Promise<any> | null | 0 | undefined;
    searchObject?: any | undefined;
    onChange?: (selectedOption: any) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onClick?: () => void;
    className?: string;
    name?: string;
    id?: string;
    key?: string;
    noOptionsMessage?: (obj: { inputValue: string }) => React.ReactNode;
    backspaceRemovesValue?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    minMenuHeight?: number;
    maxMenuHeight?: number;
    placeholder?: string;
    setSelectedVal?: any
    value?: any;
    valueField?: string;
    dependencies?: any[];
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
    errors?: any;
    touched?: any;
}

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: state.isDisabled ? '#eff2f5' : '#fff',
        borderColor: '#E4E6EF',
        borderRadius: "0.475rem",
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#369DC3',
        },
    }),
    valueContainer: (provided: any) => ({
        ...provided,
    }),
    input: (provided: any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
    }),
    option: (provided: any, state: any) => {
        return ({
            ...provided,
            color: state.isFocused ? "white" : "#5E6278",
            backgroundColor: state.isFocused ? '#2d2d43' : "white",
            '&:hover': {
                background: '#2d2d43',
                color: "#fff",
            },
            zIndex: state.isSelected ? 9999 : 'auto',
        })
    },
};

export const Autocomplete: FC<AutoCompleteProps> = (props: AutoCompleteProps) => {
    const { options, onChange, searchFunction, searchObject, valueField } = props;
    const [optionList, setOptionList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (KEY.SPACE === event.code && !(event.target as HTMLInputElement).value) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        let active = true;
        const fetchData = async () => {
            if (options?.length > 0 && !props.searchFunction) {
                setOptionList(options);
            } else if (!isLoading) {
                setIsLoading(true);
                try {
                    if (props.searchObject !== undefined) {

                        const res = await props.searchFunction?.(props.searchObject);
                        if (active && res) {
                            res?.data?.data?.content
                                ? setOptionList(res?.data?.data?.content)
                                : setOptionList(res?.data?.data)
                            setIsLoading(false);
                        }
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
        };

        (isFocus && optionList?.length === 0) && fetchData();

        return () => {
            active = false;
            setIsLoading(false)
        };
    }, [options, searchFunction, searchObject, isFocus, props.value]);

    useEffect(() => {
        setOptionList([])
        setSelectedValue(null)
    }, props?.dependencies || []);

    useEffect(() => {
        getValue();
    }, [props.value]);

    const handleChange = (selectedOption: any) => {
        setSelectedValue(selectedOption);
        onChange?.(selectedOption);
    };

    const getValue = () => {
        if (props?.value) {
            setSelectedValue(props?.value)
        }
        else {
            setSelectedValue(null);
        }

    };

    return (
        <Select
            isClearable={props?.isClearable !== undefined ? props?.isClearable : true}
            getOptionLabel={(option: GetOptionLabel<any> | string) =>
                props.getOptionLabel
                    ? props.getOptionLabel(option)
                    : (option as GetOptionLabel<any>).name
            }
            getOptionValue={(option: any) => option}
            onKeyDown={(event) => handleKeyDown(event)}
            backspaceRemovesValue={props?.backspaceRemovesValue}
            isSearchable={props?.isSearchable !== undefined ? props?.isSearchable : true}
            options={optionList}
            noOptionsMessage={({ inputValue }) => `Không tìm thấy lựa chọn cho "${inputValue}"`}
            // className={props?.className ? props?.className : "w-100"}
            name={props?.name}
            value={selectedValue}
            id={props?.id}
            key={props?.key}
            closeMenuOnSelect={props?.closeMenuOnSelect}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            isDisabled={props?.isDisabled}
            isLoading={isLoading}
            styles={customStyles}
            minMenuHeight={props?.minMenuHeight || 250}
            maxMenuHeight={props?.maxMenuHeight}
            placeholder={<p className="custom-placeholder spaces m-0">{props?.placeholder || "Chọn..."}</p>}
            onChange={handleChange}
            isMulti={props?.isMulti}
            menuPlacement="auto"
            className={clsx(props?.className, (props?.errors && props?.touched) && "ac-is-invalid")}
        />
    );
};
