import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { IssueInfo } from "./models/IIssue";
import { TableCustom } from "../component/table-custom/TableCustom";
import { IssueColumn } from "./components/IssueColumn";
import { AddNewIssue } from "./components/AddNewIssue";
const Issue: FC = () => {
  const intl = useIntl();
  document.title = `${intl.formatMessage({
    id: "GENERAL.APP.NAME",
  })} | ${intl.formatMessage({ id: "GENERAL.ISSUE" })}`;
  const [issueInfo, setIssueInfo] = useState<IssueInfo>({});
  const [viewInfoIssue, setViewInfoIssue] = useState(false);
  const [shouldOpenAddNewIssue, setShouldOpenAddNewIssue] = useState(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [issues, setIssues] = useState<IssueInfo[]>([
    {
      tenSuCo: "sự cố 1",
      loaiSuCo: { value: "1", title: "Vi phạm kỷ luật" },
      ngayXayRa: "20/05/2023",
      giaTriThietHai: "100000000",
      donViLienQuan: "quản trị nhân sự",
      trangThai: { value: "2", title: "Đang xử lý" },
      noiXayRa: { value: "1", title: "Văn bản" },
      nguyenNhan: {
        value: "2",
        title: "Không tuân thủ quy tắc bảo mật thông tin",
      },
      moTa: "thiệt hại nhiều",
    },
    {
      tenSuCo: "sự cố 2",
      loaiSuCo: { value: "2", title: "Tai nạn lao động" },
      ngayXayRa: "15/06/2023",
      giaTriThietHai: "200000000",
      donViLienQuan: "quản trị vật liệu xây dựng",
      trangThai: { value: "3", title: "Đã xử lý" },
      noiXayRa: { value: "2", title: "Lời" },
      nguyenNhan: { value: "3", title: "Không tuân thủ nội quy nơi làm việc" },
      moTa: "thiệt hại ít",
    },
  ]);

  const handleOpenInfoDialog = (row: any) => {
    setViewInfoIssue(true);
    setShouldOpenAddNewIssue(true);
    setIssueInfo(row.original);
  };

  const handleOpenAddNewIssue = () => {
    setShouldOpenAddNewIssue(true);
  };

  const handleCloseDialog = () => {
    setShouldOpenAddNewIssue(false);
    setViewInfoIssue(false);
    setIssueInfo({} as IssueInfo);
  };

  const handleOpenUpdateDialog = () => {
    setViewInfoIssue(false);
  };
  const handleCloseUpdateDialog = () => {
    setViewInfoIssue(true);
  };
  return (
    <>
      {!shouldOpenAddNewIssue && (
        <>
          <div className="profile-title">
            <span className="fs-3 fw-bold">
              {intl.formatMessage({ id: "ISSUE.ALL" })}
              <i className="bi bi-three-dots-vertical"></i>
            </span>
            <button
              onClick={handleOpenAddNewIssue}
              className="btn btn-primary btn-sm btn-sm"
            >
              {intl.formatMessage({ id: "BTN.ADD" })}
            </button>
          </div>
          {issues.length > 0 && (
            <TableCustom<IssueInfo>
              data={issues}
              columns={IssueColumn}
              compact={false}
              handleOpenInfoDialog={handleOpenInfoDialog}
            />
          )}
        </>
      )}
      {shouldOpenAddNewIssue && (
        <AddNewIssue
          view={viewInfoIssue}
          issueInfo={issueInfo}
          handleCloseDialog={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
        />
      )}
    </>
  );
};

export default Issue;
