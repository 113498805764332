import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getListSanPham = (searchObject: any) => {
  const config = {
    params: {
      pageIndex: searchObject.pageIndex,
      pageSize: searchObject.pageSize,
      orgId: searchObject?.orgId,
      productType: searchObject?.productType,
      name: searchObject?.name,
    },
  };
  return axios.get(API_URL + "/product/search", config);
};


 
