import clsx from 'clsx';
import { FC } from 'react';
import '../table.scss';
type Props = {
    data: string | null | number | undefined | JSX.Element;
    id?: string;
    action?: boolean;
    className?: string
};

const TableCustomCell: FC<Props> = ({ data, action, className }) => {
    return (
        <div
            className={clsx(`text-system ${className}`, {
                'action-cell': action
            })}
        >
            {data}
        </div>
    );
};

export { TableCustomCell };