import {configureStore} from '@reduxjs/toolkit'
import usersReducer from '../modules/users/reducer/reducer'
import PosSlice from '../modules/pos/reducer/PosSlice'
import DonHangSlice from '../modules/don-hang/reducer/DonHangSlice'
import PageLoadingReducer from '../modules/component/page-loading/PageLoadingReducer'

const store = configureStore({
  reducer: {
    users: usersReducer,
    pos: PosSlice,
    donHang: DonHangSlice,
    pageLoading: PageLoadingReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
