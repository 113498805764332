import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { danhSachKhenThuongInfo } from '../model/appreciativeModel';
import { checkTypeOf } from '../../utils/FunctionUtils';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import SelectField from '../../component/input-field/SelectField';
import TextField from '../../component/input-field/TextField';

interface Props {
  shouldOpenEmployeeAppreciative: boolean,
  handleOpenEmployeeAppreciative: () => void,
  handleCloseEmployeeAppreciative: () => void;
  employeeAppreciative: danhSachKhenThuongInfo;
  handleChangeEmployeeAppreciative: (data: danhSachKhenThuongInfo)=> void
}

export const EmployeeAppreciativeDialog = (props: Props) => {
  const intl = useIntl();
  let defaultDatEmployeeAppreciative: danhSachKhenThuongInfo = {
    id: "",
    code:"",
    nameId: "",
    nameText: "",
    donViCongTacId: "",
    donViCongTacText: "",
    viTriCongViecId: "",
    viTriCongViecText: "",
    giaTriKhenThuong: 0,
    lyDo: "",
    fileId: "",
    trangThaiId: "",
    trangThaiText: "",
  };
  const { shouldOpenEmployeeAppreciative, handleOpenEmployeeAppreciative, handleCloseEmployeeAppreciative, employeeAppreciative, handleChangeEmployeeAppreciative } = props;
  const [isView, setIsView] = useState<boolean>(false)
  const [listSpecialized, setListSpecialized] = useState<any>([]);
  const [listPlaceTraining, setListPlaceTraining] = useState<any>([]);
  useEffect(() => {
  }, []);
  const handleChangeSelect = (name: string, value: string) => {

  };
  const handleSubmit = async (close: boolean) => {
    if (checkTypeOf(employeeAppreciative.id) === "String" && employeeAppreciative.id !== '') {
      try {
        if (employeeAppreciative.id) {

          toast.success("Sửa thành công");
        } else {

          toast.success("Thêm mới thành công!");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.warning("Hãy nhập mục Thông tin chung/ Thông tin cơ bản sau đó lưu để thực hiện được hành động này !");
    }

    handleChangeEmployeeAppreciative(defaultDatEmployeeAppreciative);
    if (close) {
      await handleCloseEmployeeAppreciative();
    }
  };

  return (
    <Modal
      show={shouldOpenEmployeeAppreciative}
      size='lg'
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseEmployeeAppreciative}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title"
          className='heading-5'
        >
          {intl.formatMessage({ id: 'GENERAL.APPRECIATIVE.LIST' })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={defaultDatEmployeeAppreciative}
        onSubmit={(values, formikHelpers) => handleSubmit(true)}
      >
        <Modal.Body>
          <div className="block-content">
            <div className="content-two-col">
              <div className='item-content'>
                <SelectField
                  label='PROFILE.NAME'
                  name='id'
                  value={employeeAppreciative.id}
                  Option={listSpecialized}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <TextField
                  label='PROFILE.CODE'
                  name='code'
                  value={employeeAppreciative.code}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeEmployeeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <TextField
                  label='INPUT.WORKPROCESS.UNIT'
                  name='donViCongTacText'
                  value={employeeAppreciative.donViCongTacText}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeEmployeeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <TextField
                  label='INPUT.WORKPROCESS.ROLE'
                  name='viTriCongViecText'
                  value={employeeAppreciative.viTriCongViecText}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeEmployeeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <TextField
                  label='ALLOWANCE.VALUE.BRAVO'
                  name='giaTriKhenThuong'
                  value={employeeAppreciative.giaTriKhenThuong}
                  type='number'
                  isView={isView}
                  handleChange={handleChangeEmployeeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.LYDO'
                  name='lyDo'
                  value={employeeAppreciative.lyDo}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeEmployeeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.LYDO'
                  name='lyDo'
                  value={employeeAppreciative.fileId}
                  type='file'
                  isView={isView}
                  handleChange={handleChangeEmployeeAppreciative}
                ></TextField>
              </div>
              <div className='item-content'>
                <SelectField
                  label='GENERAL.STATUS'
                  name='trangThaiId'
                  value={employeeAppreciative.trangThaiId}
                  Option={listSpecialized}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Formik>
      <Modal.Footer className="flex-center">
        <Button variant="outline-secondary" className='button-secondary btn-sm' onClick={() => handleCloseEmployeeAppreciative()} > {intl.formatMessage({ id: 'BTN.CANCEL' })}</Button>
        <Button variant="outline-primary btn-sm" className='button-low-primary' onClick={() => {
          handleSubmit(false);
        }} > {intl.formatMessage({ id: 'BTN.ADDANDSAVE' })}</Button>
        <Button variant="primary" className='button-primary btn-sm' onClick={() => {
          handleSubmit(true);
        }}> {intl.formatMessage({ id: 'BTN.SAVE' })}</Button>
      </Modal.Footer>
    </Modal>
  );
};