/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { navigation } from "../../../../../app/navigation";
import { localStorageServices } from "../../../../../app/modules/utils/LocalStorage";
import { useEffect, useState } from "react";
import { getTotalElementsStatusByType } from "../../../../../app/modules/don-hang/services/DonHangServices";
import {
  CODE_SUCCESS,
  ERROR_MESSAGE,
  LOCAL_STORAGE_KEYS,
  MILLISECONDS,
  ROLES,
} from "../../../../../constants";
import { toast } from "react-toastify";
import { TYPE_ORDER } from "../../../../../app/modules/don-hang/const/OrderConst";

export type IElement = {
  sum: number;
  status: string;
  statusId: string;
};

const SidebarMenuMain = () => {
  const navigationLocal = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.NAVIGATION_LOCAL
  );

  const currentUser = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.CURRENT_USER
  );
  const [listTotalElementOfStatus, setListTotalElementOfStatus] = useState<
    IElement[]
  >([]);

  useEffect(() => {
    handleGetTotalElementsStatusByType();
    const updateStatusCounter = setInterval(() => {
      handleGetTotalElementsStatusByType();
    }, MILLISECONDS);

    return () => {
      clearInterval(updateStatusCounter);
    };
  }, []);

  const handleGetTotalElementsStatusByType = async () => {
    const objectSearch = {
      type: TYPE_ORDER.ONLINE.id,
      orgId:
        !currentUser?.roles?.includes(ROLES.ROLE_ADMIN) ? currentUser?.orgId : "",
    };
    try {
      const result = await getTotalElementsStatusByType(objectSearch);
      if (result?.data?.code === CODE_SUCCESS) {
        setListTotalElementOfStatus(result?.data?.data);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderNavigation = () => {
    let newNavigation = [];
    if (navigationLocal?.length > 0) {
      newNavigation = navigationLocal;
    } else {
      newNavigation = navigation;
    }
    return newNavigation.map((item: any) => {
      if (item?.children?.length === 0) {
        return (
          <SidebarMenuItem
            to={item?.path}
            icon={item?.icon}
            title={item?.description}
            fontIcon={item?.fontIcon}
          />
        );
      } else {
        return (
          <SidebarMenuItemWithSub
            to={item?.path}
            icon={item?.icon}
            title={item?.description}
            fontIcon={item?.fontIcon}
          >
            {item?.children?.map(
              (child: {
                path: string;
                description: string;
                fontIcon: string;
                icon: string;
              }) => {
                const element = listTotalElementOfStatus.find(
                  (item: IElement) => item?.status === child?.description
                );
                return (
                  <SidebarMenuItem
                    totalElement={element ? element?.sum : undefined}
                    style={element ? element.statusId : ""}
                    to={child?.path}
                    title={child?.description}
                    hasBullet={true}
                  />
                );
              }
            )}
          </SidebarMenuItemWithSub>
        );
      }
    });
  };

  return <>{renderNavigation()}</>;
};

export { SidebarMenuMain };
