import axios, { AxiosResponse } from "axios";
import {
  EmployeeProfile,
  ProjectType,
  SearchObject,
  Product,
  SearchObjectProduct,
} from "../models/CuaHangDaiLyModels";

const API_PATH = process.env.REACT_APP_API_URL;

export const getCuaHang = (id: string) => {
  let url = API_PATH + "/organizations/" + id;
  // let url = API_PATH + "/employee/page";
  return axios.get(url);
};

export const getDSCuaHangDaiLyRoleAdmin = (searchObject: SearchObject) => {
  let config = {
    params: {
      pageIndex: searchObject?.pageIndex,
      pageSize: searchObject?.pageSize,
      keyword: searchObject?.keyword,
      type: searchObject?.type,
      level: searchObject?.level,
    },
  };
  let url = API_PATH + "/organizations/page";
  // let url = API_PATH + "/employee/page";
  return axios.get(url, config);
};

export const updateActiveStatusOrg = (flag: string, id: string) => {
  return axios.post(API_PATH + "/system/organizations/" + flag + "/" + id);
};

export const createCuaHangDaiLy = (data: any) => {
  return axios.post(API_PATH + "/system/organizations/set-up", data);
}

export const approveRegistrationCuaHangDaiLy = (id: string) => {
  return axios.post(API_PATH + "/system/organizations/registered/" + id);
}