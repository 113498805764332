import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { TableCustom } from "../../component/table-custom/TableCustom";
import { WelfareColumn } from "../components/WelfareColumn";
import { phucLoiArray } from "../const/WelfareConst";
import { IWelfare } from "../model/WelfareModel";
import WelfareDialog from "../components/WelfareDialog";
const WelfareList: FC = () => {
  const intl = useIntl();
  const [openWelfareDialog ,setOpenWelfareDialog]= useState<boolean>(false)
  const [welfareInfo,setWelfareInfo]= useState<IWelfare>({} as IWelfare)
   const handleOpenWelfareDialog = (info: any) => {
     setWelfareInfo(info.original);
     setOpenWelfareDialog(true);
    
   };
   const handleCloseWelfareDialog = () => {
     setOpenWelfareDialog(false);
     setWelfareInfo({} as IWelfare);
   };
   console.log(welfareInfo,'state')
  return (
    <>
      <div className="profile-title">
        <span className="fs-3 fw-bold">
          {intl.formatMessage({ id: "WELFARE.LIST" })}
          <i className="bi bi-three-dots-vertical"></i>
        </span>
        <button className="btn btn-primary btn-sm btn-sm" onClick={()=>setOpenWelfareDialog(true)}>
          {intl.formatMessage({ id: "BTN.ADD" })}
        </button>
      </div>
      <TableCustom<IWelfare> handleOpenInfoDialog={handleOpenWelfareDialog} columns={WelfareColumn} data={phucLoiArray} compact={false} />
      {openWelfareDialog && <WelfareDialog handleCLose={handleCloseWelfareDialog} welfareInfo={welfareInfo} />}
    </>
  );
};
export { WelfareList };
