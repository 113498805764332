import { IAppointment } from "../models/IAppointment";
export const appointments: IAppointment[] = [
  {
    MaNhanVien: "NV001",
    ten: "Nguyen Van A",
    DonViCongTac: "Phong Nhan Su",
    ViTriCongViec: "Nhan vien",
    DonViBoNhiem: "Phong Ke Toan",
    ViTriBoNhiem: "Nhan vien",
    SoQuyetDinh: "QD001",
    TrangThai: "Hoan tat",
    
  },
  {
    MaNhanVien: "NV002",
    ten: "Tran Thi B",
    DonViCongTac: "Phong Kinh Doanh",
    ViTriCongViec: "Nhan vien",
    DonViBoNhiem: "Phong Quan Tri",
    ViTriBoNhiem: "Truong phong",
    SoQuyetDinh: "QD002",
    TrangThai: "Chua hoan tat",
  },
];

