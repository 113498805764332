import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

type ICustomCheckBoxProps = {
    label: string
    // checked: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string
}

function CustomCheckbox({ label, onChange, className }: ICustomCheckBoxProps) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    return (
        <div className={className}>
            <Form.Check
                type="checkbox"
                label={label}
                checked={isChecked}
                onChange={(e) => {
                    setIsChecked(!isChecked)
                    onChange(e);
                }}
                style={{ display: 'none' }}
                id={`custom-checkbox-${label}`}
            />
            <label
                htmlFor={`custom-checkbox-${label}`}
                style={{
                    padding: '5px 10px',
                    border: isChecked ? '1px solid orange' : '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    backgroundColor: isChecked ? 'orange' : 'white',
                    color: isChecked ? 'white' : 'black',
                }}
            >
                {label}
            </label>
        </div>
    );
}

export default CustomCheckbox;
