import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { formatPrice } from "../../../utils/FunctionUtils";
import HoaDonDialog from "../../../don-hang/components/HoaDonDialog";
import {
  CODE_SUCCESS,
  ERROR_MESSAGE,
  LOCAL_STORAGE_KEYS,
  MESSAGE_SUCCESS,
  initialValue,
} from "../../../../../constants";
import { toast } from "react-toastify";
import {
  STATUS_PAY,
  STATUS_SUCCESS,
} from "../../../don-hang/const/OrderConst";
import { DEFAULT_CUSTOMER } from "../../const/PosConst";
import { addDonHang, getOrderById, updateDonHang } from "../../../don-hang/services/DonHangServices";
import { IDonHang } from "../../models/PosModels";
import { detailDonHang } from "../../../don-hang/reducer/DonHangSlice";
import CustomRadio from "../../../component/CustomRadio";
import { localStorageServices } from "../../../utils/LocalStorage";

type Props = {
  handleCloseDialog: () => void;
  totalPriceThuocTinh?: number;
  resetCartList?: () => void;
  orderId?: string;
};

const DonHangDialog = (props: Props) => {
  const { handleCloseDialog, resetCartList, orderId } = props;
  const dispatch = useAppDispatch();
  const subTotalReducer = useAppSelector((state) => state.pos.totalPrice);
  const cartListReducer = useAppSelector((state) => state.pos.cartList);
  const permissions = localStorageServices.getSessionItem(
    LOCAL_STORAGE_KEYS.AUTHORITIES
  );
  const [shouldOpenDialogHoaDon, setShouldOpenDialogHoaDon] = useState(false);
  const [donHang, setDonHang] = useState<IDonHang>();
  const [infoKhachHang, setInfoKhachHang] = useState({
    customerName: "",
    phoneNumber: "",
  });
  const [paymentName, setPaymentName] = useState("");

  const listIdProduct = cartListReducer.map((item) => {
    if (item?.attributeTypes?.length > 0) {
      const newAttributeTypes = item.attributeTypes.map((pItem) => {
        return {
          ...pItem,
          attributes: pItem?.attributes?.map((item) => {
            return {
              ...item,
              price: item?.totalPrice ? item.totalPrice : item?.price,
            }
          })
        }
      })
      return {
        productId: item.product.id,
        productAttributeType:
          newAttributeTypes?.length > 0 ? newAttributeTypes : [],
      };
    } else {
      return {
        productId: item.product.id,
        totalPrice: item.product.price,
        quantity: item.product.quantity,
      };
    }
  });

  const totalQuantity = cartListReducer.reduce((total, item) => {
    return total + item.product?.quantity;
  }, initialValue);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoKhachHang({ ...infoKhachHang, [e.target.name]: e.target.value });
  };

  const handleChangPaymentName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentName(e.target.value)
  }

  const handleThanhToan = async () => {

    const object: IDonHang = {
      lstProduct: listIdProduct,
      orderDto: {
        deliveryPrice: 0,
        orderType: 0,
        originalPrice: subTotalReducer,
        paymentMethod: "COD",
        paymentName: paymentName,
        phoneNumber: infoKhachHang.phoneNumber || "",
        price: subTotalReducer,
        promotionPrice: 0,
        quantity: totalQuantity,
        type: 0,
        status: STATUS_SUCCESS[0],
        statusId: 5,
        customerName: infoKhachHang.customerName
          ? infoKhachHang.customerName
          : DEFAULT_CUSTOMER.name,
        orgId: permissions.isRoleAdmin ? cartListReducer?.[0]?.product?.orgId : undefined
      },
    };

    if (paymentName) {
      orderId ? handleUpdateDonHang(orderId, object) :
        handleAddDonHang(object)
    }
    else {
      toast.warning("Vui lòng chọn trạng thái thanh toán")
    }

  };

  const handleAddDonHang = async (data: IDonHang) => {
    try {
      const result = await addDonHang(data);
      if (result?.data?.code === CODE_SUCCESS) {
        handleGetDonHangById(result?.data?.data?.orderDto?.id);
        toast.success(MESSAGE_SUCCESS.addDonHang);
        setShouldOpenDialogHoaDon(true);
      } else {
        toast.error(ERROR_MESSAGE);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  }

  const handleUpdateDonHang = async (id: string | number, data: IDonHang) => {
    try {
      const result = await updateDonHang(id, data);
      if (result?.data?.code === CODE_SUCCESS) {
        handleGetDonHangById(id);
        toast.success(MESSAGE_SUCCESS.updateDonHang);
        setShouldOpenDialogHoaDon(true);
      } else {
        toast.error(ERROR_MESSAGE);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  }

  const handleGetDonHangById = async (id: string | number) => {
    try {
      const result = await getOrderById(id);
      if (result?.data?.code === CODE_SUCCESS) {
        dispatch(detailDonHang(result?.data?.data));
        setDonHang(result?.data?.data);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleCloseDialogHoaDon = () => {
    handleCloseDialog();
    setShouldOpenDialogHoaDon(false);
    resetCartList && resetCartList();
  };


  return (
    <Modal show={true} centered onHide={handleCloseDialog}>
      <Modal.Header closeButton>
        <Modal.Title>Thanh toán</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="w-300px mb-3">
          <Col>
            <CustomRadio
              label={STATUS_PAY.UN_PAID.name}
              className=""
              selectedOption={paymentName}
              value={STATUS_PAY.UN_PAID.name}
              onChange={handleChangPaymentName} />
          </Col>

          <Col>
            <CustomRadio
              label={STATUS_PAY.PAID.name}
              className=""
              selectedOption={paymentName}
              value={STATUS_PAY.PAID.name}
              onChange={handleChangPaymentName} />
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Tên khách hàng</Form.Label>
          <Form.Control
            name="customerName"
            placeholder={DEFAULT_CUSTOMER.name}
            value={infoKhachHang.customerName}
            onChange={handleChangeValue}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Số điện thoại</Form.Label>
          <Form.Control
            name="phoneNumber"
            value={infoKhachHang.phoneNumber}
            onChange={handleChangeValue}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Tổng giá(₫)</Form.Label>
          <Form.Control
            defaultValue={formatPrice(
              props?.totalPriceThuocTinh
                ? props?.totalPriceThuocTinh
                : subTotalReducer
            )}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Loại</Form.Label>
          <Form.Select defaultValue={"1"}>
            <option value="1">Tiền</option>
            <option value="2">Thẻ</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer className="">
        <Button size="sm" variant="danger" onClick={handleCloseDialog}>
          Hủy
        </Button>
        <Button size="sm" onClick={handleThanhToan}>
          Xác nhận
        </Button>
      </Modal.Footer>

      {shouldOpenDialogHoaDon && (
        <HoaDonDialog
          donHang={donHang}
          handleCloseDialog={handleCloseDialogHoaDon}
        />
      )}
    </Modal>
  );
};

export default DonHangDialog;
