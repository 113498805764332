import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../../component/table-custom/columns/TableCustomCell";
import { useIntl } from "react-intl";
import { IAppointment } from "../models/IAppointment";
function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}
const AppointmentColumn
: ReadonlyArray<Column<IAppointment>> = [
 
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.CODE")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.CODE",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].MaNhanVien} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.NAME")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.NAME",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].ten} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.WORKUNINT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.WORKUNINT",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].DonViCongTac} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.JOBPOSITIION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.JOBPOSITIION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].ViTriCongViec} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.UNIT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.UNIT",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].DonViCongTac} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.POSITION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.POSITION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].ViTriBoNhiem} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.NUMBER")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.NUMBER",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].SoQuyetDinh} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IAppointment>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.STATUS")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.STATUS",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].TrangThai} />,
  },
  
];

export { AppointmentColumn
 };
