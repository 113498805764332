import {Suspense, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer, toast, Zoom} from 'react-toastify'
import { MenuProvider } from '../menuContext'
import { URL_MENU } from '../constants'

const App = () => {
  let initMenu: Object[] = [
    { index: 1, router: URL_MENU.POS, name: "Danh sách đại lý, tiệm giặt" },
    { index: 2, router: URL_MENU.PRODUCT, name: "Danh sách sản phẩm" },
    { index: 3, router: URL_MENU.ORDER, name: "Đơn hàng" },
  ];

  const [menu, setMenu] = useState(initMenu)
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <MenuProvider value={{ menu, setMenu }}>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
            <ToastContainer
              autoClose={3000}
              position={toast.POSITION.TOP_RIGHT}
              transition={Zoom}
              theme='colored'
              pauseOnHover={false}
            />
          </LayoutProvider>
        </MenuProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
