import React, {useContext} from 'react';
import DanhSachSanPhamContext from '../danh-sach-san-pham/utils/DanhSachSanPhamContext';

type Props = {
    data: string
    rowData?: any
}

const ClickableCell = ({
    data, rowData
}: Props) => {
    const {handleClickTableCell} = useContext(DanhSachSanPhamContext);
  return (
    <div
        onClick={() => handleClickTableCell ? handleClickTableCell(rowData) : {}}
    >{data}</div>
  )
}

export default ClickableCell