import React from "react";
import { Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import DSSanPham from "./component/DSSanPhamPos";
import GioHangPos from "./component/GioHangPos";

type Props = {};

const Pos = (props: Props) => {
  return (
    <Container className="m-0 px-10 min-w-100">
      <Row>
        <DSSanPham />

        <GioHangPos />
      </Row>
    </Container>
  );
};

export default Pos;
