import React, { ChangeEvent, ReactNode, useContext, useState } from 'react'
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap'
import { createSanPham, getDanhSachDonVi, updateSanPham, uploadImageSanPham } from '../../services/DanhSachSanPhamService'
import { SanPham } from '../../models/ISanPham'
import { useFormik } from 'formik'
import { Autocomplete } from '../../../component/Autocomplete';
import { CODE_SUCCESS, listLoaiHangHoa } from '../../../../../constants'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DEFAULT_IMG_URL, DELETE_STATUS, SERVICE_TYPE } from '../../constants'
import DanhSachSanPhamContext from '../../utils/DanhSachSanPhamContext'
import { useAppDispatch, useAppSelector } from '../../../../hook'
import PageLoading from '../../../component/page-loading/PageLoading'
import { setPageLoading } from '../../../component/page-loading/PageLoadingReducer'
import CustomUploadImage from '../../../component/CustomUploadImage'

type Props = {
    dataSanPham?: SanPham
    handleClose: () => void
    reRenderPage: () => void
}

const initialValues = {
    name: '',
    price: 0,
    productType: '',
    productTypeName: '',
    quantity: 0,
    description: '',
    logoUrl: ''
}

const validationSchema = yup.object({
    name: yup.string().trim().required("Trường này là bắt buộc"),
    price: yup.number().required("Trường này là bắt buộc"),
    quantity: yup.number().required("Trường này là bắt buộc"),
    donVi: yup.object().nullable().required("Trường này là bắt buộc"),
    loaiHangHoa: yup.object().nullable().required("Trường này là bắt buộc")
})

const SanPhamDialog = ({
    dataSanPham,
    handleClose,
    reRenderPage
}: Props) => {
    const {danhSachDonVi} = useContext(DanhSachSanPhamContext);
    const dispatch = useAppDispatch();
    const loading = useAppSelector((state) => state.pageLoading.loading);
    const formik = useFormik({
        initialValues: dataSanPham ? {
            ...dataSanPham,
            loaiHangHoa: {
                productType: dataSanPham?.productType as string,
                productTypeName: dataSanPham?.productTypeName as string
            },
            donVi: {
                id: dataSanPham?.orgId,
                name: danhSachDonVi?.find(item => item.id === dataSanPham?.orgId)?.name
            }
        } : {
            ...initialValues,
            loaiHangHoa: null,
            donVi: null
        },
        validationSchema,
        onSubmit: (values) => {
            const submitData: SanPham = {
                productType: values?.loaiHangHoa?.productType as string,
                productTypeName: values?.loaiHangHoa?.productTypeName,
                name: values?.name,
                price: values?.price,
                quantity: values?.quantity,
                description: values?.description,
                logoUrl: values?.logoUrl,
                orgId: values?.donVi?.id,
                deleted: DELETE_STATUS.NOT_DELETED,
                id: dataSanPham?.id
            }
            dataSanPham?.id
                ? handleUpdateSanPham(submitData)
                : handleCreateSanPham(submitData);
        }
    })

    const handleCreateSanPham = async (dataSanPham: SanPham) => {
        dispatch(setPageLoading(true));
        try {
            const res = await createSanPham(dataSanPham);
            if (res?.data?.code === CODE_SUCCESS) {
                handleClose();
                reRenderPage();
                toast.success("Thêm mới thành công");
            } else {
                toast.error("Có lỗi xảy ra");
            }
        } catch (err) {
            toast.error("Có lỗi xảy ra");
        }
        finally {
            dispatch(setPageLoading(false));
        };
    }

    const handleUpdateSanPham = async (dataSanPham: SanPham) => {
        dispatch(setPageLoading(true));
        try {
            const res = await updateSanPham(dataSanPham);
            if (res?.data?.code === CODE_SUCCESS) {
                handleClose();
                reRenderPage();
                toast.success("Cập nhật thành công");
            } else {
                toast.error("Có lỗi xảy ra");
            }
        } catch (err) {
            toast.error("Có lỗi xảy ra");
        }
        finally {
            dispatch(setPageLoading(false));
        };
    }

    return (
        <Modal show={true} size='lg'>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header className='p-4'>
                    <Modal.Title>
                        {dataSanPham ? 'Cập nhật mặt hàng' : 'Thêm mặt hàng mới'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Đơn vị</Form.Label>
                                    <Autocomplete
                                        name='donVi'
                                        options={[]}
                                        searchFunction={getDanhSachDonVi}
                                        searchObject={{type: SERVICE_TYPE.DAI_LY}}
                                        value={formik.values.donVi}
                                        onChange={(value) => formik.setFieldValue('donVi', value) }
                                        errors={formik.errors.donVi}
                                        touched={formik.touched.donVi}
                                    />
                                    {formik.touched.donVi && formik.errors.donVi && (
                                        <div className="invalid-feedback">{formik.errors.donVi as ReactNode}</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Loại sản phẩm</Form.Label>
                                    <Autocomplete
                                        name='loaiHangHoa'
                                        options={listLoaiHangHoa}
                                        getOptionLabel={option => option.productTypeName}
                                        value={formik.values.loaiHangHoa}
                                        onChange={(value) => formik.setFieldValue('loaiHangHoa', value)}
                                        errors={formik.errors.loaiHangHoa}
                                        touched={formik.touched.loaiHangHoa}
                                    />
                                    {formik.touched.loaiHangHoa && formik.errors.loaiHangHoa && (
                                        <div className="invalid-feedback">{formik.errors.loaiHangHoa as ReactNode}</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Tên sản phẩm</Form.Label>
                                    <Form.Control as='textarea' rows={2} size='lg' name='name'
                                        value={formik.values?.name || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.name && Boolean(formik.errors.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Giá</Form.Label>
                                    <Form.Control type="text" size='lg' name='price'
                                        value={formik.values?.price as number}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.price && Boolean(formik.errors.price)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.price}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Số lượng</Form.Label>
                                    <Form.Control type="number" size='lg' name='quantity'
                                        value={formik.values?.quantity as number}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.quantity}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs className='mt-4'>
                                <CustomUploadImage
                                    widthImg={275}
                                    heightImg={300}
                                    onChange={(imgUrl: string) => formik.setFieldValue('logoUrl', imgUrl)}
                                    id='select-product-image'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='mb-1'>Mô tả</Form.Label>
                                <Form.Control as='textarea' rows={4} size='lg' name='description'
                                    value={formik.values?.description || ''}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className='p-4'>
                    <Button variant="danger" onClick={handleClose}>
                        Hủy
                    </Button>
                    <Button variant="primary" type='submit'>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Form>
            {loading && <PageLoading />}
        </Modal>
    )
}

export default SanPhamDialog