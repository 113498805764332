import { Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import DateField from '../../component/input-field/DateField';
import SelectField from '../../component/input-field/SelectField';
import TextField from '../../component/input-field/TextField';
import TableComponentChild, { Column } from '../../component/table-component/TableComponentChild';
import { templateSelect } from '../../transition/const/transitionConst';
import { AppreciativeInFo, danhSachKhenThuongInfo } from '../model/appreciativeModel';
import { EmployeeAppreciativeDialog } from './EmployeeAppreciativeDialog';
import { GroupButton } from '../../component/GroupButton';

interface Props {
  handleOpenAppreciativeDialog: () => void,
  handleCloseAppreciativeDialog: () => void,
  show: boolean,
  appreciative: AppreciativeInFo;
  handleChangeSelect: (name: string, value: string) => void;
  handleChangeAppreciative: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const AppreciativeDialog = (props: any) => {
  const intl = useIntl();
  const [isView, setIsView] = useState<boolean>(false);
  const defaultAppreciative = {};
  const listColumnsEmployeeAppreciative: Column<danhSachKhenThuongInfo>[] = [
    {
      title: 'PROFILE.CODE',
      render: (item) => <td key={item.id} className="col-3">{item.id}</td>
    },
    {
      title: 'PROFILE.NAME',
      render: (item) => <td key={item.id} className="col-3">{item.nameText}</td>
    },
    {
      title: 'INPUT.WORKPROCESS.UNIT',
      render: (item) => <td key={item.id} className="col-3">{item.donViCongTacId}</td>
    },
    {
      title: 'INPUT.WORKPROCESS.ROLE',
      render: (item) => <td key={item.id} className="col-3">{item.viTriCongViecText}</td>
    },
    {
      title: 'ALLOWANCE.VALUE.BRAVO',
      render: (item) => <td key={item.id} className="col-3">{item.giaTriKhenThuong}</td>
    },
    {
      title: 'APPRECIATIVE.LYDO',
      render: (item) => <td key={item.id} className="col-3">{item.lyDo}</td>
    },
    {
      title: 'GENERAL.STATUS',
      render: (item) => <td key={item.id} className="col-3">{item.trangThaiText}</td>
    },
  ];
  const [title, setTitle] = useState("INFO.ADD")
  const [employeeAppreciative, setEmployeeAppreciative] = useState<danhSachKhenThuongInfo>({} as danhSachKhenThuongInfo);
  const [listEmployeeAppreciative, setListEmployeeAppreciative] = useState<danhSachKhenThuongInfo[]>([]);
  const [shouldOpenEmployeeAppreciativeDialog, setShouldOpenEmployeeAppreciativeDialog] = useState<boolean>(false);
  const { handleCloseAppreciativeDialog, appreciative, handleChangeAppreciative, handleChangeSelect } = props;
  const handleOpenEmployeeAppreciative = () => {
    setShouldOpenEmployeeAppreciativeDialog(true);
  };
  const handleCloseEmployeeAppreciative = () => {
    setShouldOpenEmployeeAppreciativeDialog(false);
  };

  const handleSubmit = () => {

  };
  const handleEditEmployeeAppreciative = () => {

  };
  const handleDeleteEmployeeAppreciative = () => {

  };
  const handleChangeEmployeeAppreciative = (data: danhSachKhenThuongInfo) => {
    setEmployeeAppreciative(data)
  }
  return (
    <Formik
      initialValues={defaultAppreciative}
      onSubmit={(values, formikHelpers) => handleSubmit()}
    >
      <>
      {!isView ?
        <div className="row header-action">
          <div className='col d-flex'>
            <h2 className='me-2'>
              {intl.formatMessage({ id: title })}
            </h2>
          </div>
          <div className='fixed-button'>
            <button
              className='btn btn-outline-secondary btn-sm btn-sm button-secondary' onClick={handleCloseAppreciativeDialog}
            >
              {intl.formatMessage({ id: 'BTN.CANCEL' })}
            </button>
            <button
              onClick={() => handleSubmit()}
              className='btn btn-primary btn-sm btn-sm ms-3'
            >
              {intl.formatMessage({ id: 'BTN.SAVE' })}
            </button>
          </div>
        </div>
        :
        <div className="row header-action">
          <div className='col d-flex'>
            <GroupButton type='btn-back' handleClose={handleCloseAppreciativeDialog} ></GroupButton>
          </div>
          <div className='fixed-button'>
            <button
              onClick={() => {
                setTitle('PROFILE.EDIT');
              }}
              className='btn btn-primary btn-sm btn-sm ms-3'
            >
              {intl.formatMessage({ id: 'BTN.EDIT' })}
            </button>
          </div>
        </div>
      }
      <div className="row frame pb-12">
        <div className='pt-9 ps-9'>
          <span className='text-title open-field' >
            {intl.formatMessage({ id: 'INFO.MAIN' })}
          </span>
          <div className="block-content">
            <div className="content">
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.NAME'
                  name='name'
                  value={appreciative.name}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.DONVIAPDUNGTEXT'
                  name='donViApDungId'
                  value={appreciative.donViApDungId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <DateField
                  label='APPRECIATIVE.DATE'
                  name='date'
                  value={appreciative.date}
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></DateField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.LOAIKHENTHUONGTEXT'
                  name='loaiKhenThuongId'
                  value={appreciative.loaiKhenThuongId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.SOQUYETDINH'
                  name='soQuyetDinh'
                  value={appreciative.soQuyetDinh}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.NGAYQUYETDINH'
                  name='ngayQuyetDinh'
                  value={appreciative.ngayQuyetDinh}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.NGUOIQUYETDINHTEXT'
                  name='nguoiQuyetDinhId'
                  value={appreciative.nguoiQuyetDinhId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.CHUCDANHNGUOIQUYETDINH'
                  name='chucDanhNguoiQuyetDinh'
                  value={appreciative.chucDanhNguoiQuyetDinh}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.HINHTHUCKHENTHUONGTEXT'
                  name='hinhThucKhenThuongId'
                  value={appreciative.hinhThucKhenThuongId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.TONGGIATRI'
                  name='tongGiaTri'
                  value={appreciative.tongGiaTri}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.DOITUONGKHENTHUONGTEXT'
                  name='doiTuongKhenThuongId'
                  value={appreciative.doiTuongKhenThuongId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.CHITIETDOITUONGTEXT'
                  name='chiTietDoiTuongId'
                  value={appreciative.chiTietDoiTuongId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.CAPKHENTHUONGTEXT'
                  name='capKhenThuongId'
                  value={appreciative.capKhenThuongId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>
              <div className="item-content">
                <TextField
                  label='APPRECIATIVE.LYDO'
                  name='lyDo'
                  value={appreciative.lyDo}
                  type='text'
                  isView={isView}
                  handleChange={handleChangeAppreciative}
                ></TextField>
              </div>
              <div className="item-content">
                <SelectField
                  label='APPRECIATIVE.TRANGTHAITEXT'
                  name='trangThaiId'
                  value={appreciative.trangThaiId}
                  Option={templateSelect}
                  isView={isView}
                  handleChange={handleChangeSelect}
                ></SelectField>
              </div>

            </div>
          </div>
          <span className='text-title open-field' >
            {intl.formatMessage({ id: 'GENERAL.APPRECIATIVE.LIST' })}
          </span>
          {listEmployeeAppreciative.length !== 0 && <TableComponentChild<danhSachKhenThuongInfo>
            listColumns={listColumnsEmployeeAppreciative}
            listData={listEmployeeAppreciative}
            handleEdit={handleEditEmployeeAppreciative}
            handleDelete={handleDeleteEmployeeAppreciative}
          />}
          <span className='text-add-field mt-5 mb-10' onClick={handleOpenEmployeeAppreciative}>
            <div className="bi bi-plus-lg"></div>
            {intl.formatMessage({ id: 'INFO.ADD' })}
          </span>
          <span className='text-title open-field' >
            {intl.formatMessage({ id: 'GENERAL.INFO.ATTACHMENTS' })}
          </span>

          <span className='text-add-field mt-5 mb-10' onClick={handleOpenEmployeeAppreciative}>
            <div className="bi bi-plus-lg"></div>
            {intl.formatMessage({ id: 'INFO.ADD' })}
          </span>
        </div>
        {shouldOpenEmployeeAppreciativeDialog && <EmployeeAppreciativeDialog
          shouldOpenEmployeeAppreciative={shouldOpenEmployeeAppreciativeDialog}
          handleOpenEmployeeAppreciative={handleOpenEmployeeAppreciative}
          handleCloseEmployeeAppreciative={handleCloseEmployeeAppreciative}
          employeeAppreciative={employeeAppreciative}
          handleChangeEmployeeAppreciative={handleChangeEmployeeAppreciative}
        ></EmployeeAppreciativeDialog>}
        </div>
      </>
    </Formik>
  );
};

export default AppreciativeDialog;
