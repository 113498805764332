import { Form } from "react-bootstrap"

type ICustomRadioProps = {
    label: string
    selectedOption: any
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string
    value:any
}


function CustomRadio({ label, selectedOption, onChange, className, value }: ICustomRadioProps) {
    const checked = selectedOption === value;
    return (
        <div className={className}>
            <Form.Check
                type="radio"
                label={label}
                checked={checked}
                onChange={onChange}
                style={{ display: 'none' }}
                value={value}
                id={`custom-radio-${label}`}
            />
            <label
                htmlFor={`custom-radio-${label}`}
                style={{
                    padding: '5px 10px',
                    border: checked ? '1px solid orange' : '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    backgroundColor: checked ? 'orange' : 'white', 
                    color: checked ? 'white' : 'black'
                }}
            >
                {label}
            </label>
        </div>
    );
}

export default CustomRadio;