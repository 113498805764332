import React, { ReactNode } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import { Autocomplete } from '../../../component/Autocomplete';
import * as yup from 'yup'
import CustomUploadImage from '../../../component/CustomUploadImage';
import { createCuaHangDaiLy } from '../../services/CuaHangDaiLyServices';
import { toast } from 'react-toastify';
import { SUCCESS_CODE } from '../../../don-hang/const/OrderConst';
import { ERROR_MESSAGE, MESSAGE_SUCCESS } from '../../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hook';
import { setPageLoading } from '../../../component/page-loading/PageLoadingReducer';
import PageLoading from '../../../component/page-loading/PageLoading';

const orgType = [
    { value: 0, name: 'Đại lý' },
    { value: 1, name: 'Tiệm giặt' },
]

type Props = {
    handleClose: () => void
    reRenderPage: () => void
}

const initialValues = {
    viewUrl: "",
    organization: {
        name: "",
        type: null,
        website: "",
    },
    person: {
        address: "",
        firstName: "",
        lastName: "",
        imagePath: "",
    },
    user: {
        password: "",
        confirmPassword: "",
        phone: "",
        email: ""
    }
}

const validationSchema = yup.object({
    organization: yup.object({
        name: yup.string().trim().required("Trường này là bắt buộc"),
        type: yup.object().nullable().required("Trường này là bắt buộc")
    }),
    person: yup.object({
        address: yup.string().trim().required("Trường này là bắt buộc"),
        firstName: yup.string().trim().required("Trường này là bắt buộc"),
        lastName: yup.string().trim().required("Trường này là bắt buộc"),
    }),
    user: yup.object({
        password: yup.string().trim().required("Trường này là bắt buộc").min(6, 'Mật khẩu phải chứa ít nhất 6 ký tự'),
        confirmPassword: yup.string().trim().required("Trường này là bắt buộc")
            .oneOf([yup.ref('password'), null], 'Mật khẩu xác nhận không trùng khớp')
            .min(6, 'Mật khẩu phải chứa ít nhất 6 ký tự'),
        phone: yup.string().trim().required("Trường này là bắt buộc"),
        email: yup.string().trim().required("Trường này là bắt buộc")
    })
})

const CuaHangDaiLyDialog = ({
    handleClose,
    reRenderPage
}: Props) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector((state) => state.pageLoading.loading);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const submitData = {
                ...values,
                organization: {
                    ...values.organization,
                    type: (values.organization.type as any)?.value,
                },
                person: {
                    ...values.person,
                    personAddress: [{
                        address: values.person.address
                    }],
                    email: values.user.email,
                    phoneNumber: values.user.phone,
                    displayName: values.person.lastName + ' ' + values.person.firstName,
                },
                user: {
                    ...values.user,
                    username: values.user.email,
                }
            };
            // console.log(submitData);
            handleCreateCuaHangDaiLy(submitData);
        }
    })

    const handleCreateCuaHangDaiLy = async (data: any) => {
        dispatch(setPageLoading(true));
        try {
            const res = await createCuaHangDaiLy(data);
            if(res?.data?.code === SUCCESS_CODE) {
                toast.success(MESSAGE_SUCCESS.createCuaHangDaiLy);
                handleClose();
                reRenderPage();
            } else {
                toast.error(ERROR_MESSAGE);
            }
        } catch (err) {
            toast.error(ERROR_MESSAGE);
        }
        finally {
            dispatch(setPageLoading(false));
        };
    }

    return (
        <Modal show={true} size='xl'>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Header className='p-4'>
                    <Modal.Title>
                        Đăng ký tiệm giặt mới
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='h-700px overflow-auto'>
                    <Container>
                        <Row>
                            <h4>Thông tin cơ sở</h4>
                            <Col xs={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Loại cơ sở</Form.Label>
                                    <Autocomplete
                                        name='organization.type'
                                        options={orgType}
                                        value={formik.values.organization?.type}
                                        onChange={(value) => formik.setFieldValue('organization.type', value)}
                                        errors={formik.errors.organization?.type}
                                        touched={formik.touched.organization?.type}
                                    />
                                    {formik.touched.organization?.type && formik.errors.organization?.type && (
                                        <div className="invalid-feedback">{formik.errors.organization?.type as ReactNode}</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Tên cơ sở</Form.Label>
                                    <Form.Control as='textarea' rows={2} size='lg' name='organization.name'
                                        value={formik.values?.organization?.name || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.organization?.name && Boolean(formik.errors.organization?.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.organization?.name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='mb-1'>Địa chỉ</Form.Label>
                                    <Form.Control as='textarea' rows={3} size='lg' name='person.address'
                                        value={formik.values?.person.address || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.person?.address && Boolean(formik.errors.person?.address)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.person?.address}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs>
                                <CustomUploadImage
                                    widthImg={300}
                                    heightImg={300}
                                    onChange={(imgUrl: string) => formik.setFieldValue('viewUrl', imgUrl)}
                                    type='roundedCircle'
                                    id='select-logo'
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29799.311289074187!2d105.80076609999999!3d20.996087749999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1svi!2s!4v1693911253953!5m2!1svi!2s" width="600" height="450"  allowFullScreen loading="lazy"></iframe>
                        </Row> */}
                        <Row className='mt-4'>
                            <h4>Thông tin chủ cơ sở</h4>
                            <Col xs>
                                <CustomUploadImage
                                    widthImg={250}
                                    heightImg={250}
                                    onChange={(imgUrl: string) => formik.setFieldValue('person.imagePath', imgUrl)}
                                    type='roundedCircle'
                                    id='select-avatar'
                                />
                            </Col>
                            <Col xs={8}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='mb-1'>Họ</Form.Label>
                                            <Form.Control type="text" size='lg' name='person.lastName'
                                                value={formik.values?.person?.lastName}
                                                onChange={formik.handleChange}
                                                isInvalid={formik.touched.person?.lastName && Boolean(formik.errors.person?.lastName)}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.person?.lastName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='mb-1'>Tên</Form.Label>
                                            <Form.Control type="text" size='lg' name='person.firstName'
                                                value={formik.values?.person?.firstName}
                                                onChange={formik.handleChange}
                                                isInvalid={formik.touched.person?.firstName && Boolean(formik.errors.person?.firstName)}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.person?.firstName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col xs>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='mb-1'>Số điện thoại</Form.Label>
                                        <Form.Control type="text" size='lg' name='user.phone'
                                            value={formik.values?.user?.phone}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.user?.phone && Boolean(formik.errors.user?.phone)}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.user?.phone}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='mb-1'>Email</Form.Label>
                                        <Form.Control type="text" size='lg' name='user.email'
                                            value={formik.values?.user?.email}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.user?.email && Boolean(formik.errors.user?.email)}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.user?.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='mb-1'>Mật khẩu</Form.Label>
                                            <Form.Control type="password" size='lg' name='user.password'
                                                value={formik.values?.user?.password}
                                                onChange={formik.handleChange}
                                                isInvalid={formik.touched.user?.password && Boolean(formik.errors.user?.password)}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.user?.password}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='mb-1'>Xác nhận mật khẩu</Form.Label>
                                            <Form.Control type="password" size='lg' name='user.confirmPassword'
                                                value={formik.values?.user?.confirmPassword}
                                                onChange={formik.handleChange}
                                                isInvalid={formik.touched.user?.confirmPassword && Boolean(formik.errors.user?.confirmPassword)}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.user?.confirmPassword}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className='p-4'>
                    <Button variant="danger" onClick={handleClose}>
                        Hủy
                    </Button>
                    <Button variant="primary" type='submit'>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Form>
            {loading && <PageLoading />}
        </Modal>
    )
}

export default CuaHangDaiLyDialog