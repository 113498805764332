import { useContext } from 'react';
import DanhSachSanPhamContext from '../utils/DanhSachSanPhamContext';
import StatusColor from '../../component/status-color/StatusColor';


function DonVi(props: { orgId: string }) {
    const {danhSachDonVi} = useContext(DanhSachSanPhamContext);
    const result = (danhSachDonVi as any[]).find(donVi => donVi?.id === props?.orgId)?.name;
    return (
        <StatusColor 
            type='pending'
            children={result}
        />
        // <>{result}</>
    )
}

export default DonVi;