import { createSlice } from "@reduxjs/toolkit";

export type IDonHang = {};

export type IInitialState = {
    loading: boolean;
};

const initialState: IInitialState = {
    loading: false,
};

const PageLoadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        setPageLoading: (state, action: {type: string, payload: boolean}) => {
            // console.log(state, action);
            state.loading = action.payload;
        }
    },
});

export default PageLoadingSlice.reducer;
export const { setPageLoading } = PageLoadingSlice.actions;
