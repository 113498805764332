import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../../component/table-custom/columns/TableCustomCell";
import { useIntl } from "react-intl";
import { IWelfare } from "../model/WelfareModel";
function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}
const WelfareColumn: ReadonlyArray<Column<IWelfare>> = [
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("WELFARE.NAME")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "WELFARE.NAME",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].tenPhucLoi} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("WELFARE.GROUP")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "WELFARE.GROUP",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].nhomPhucLoi} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("APPLIED.UNIT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPLIED.UNIT",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].donViApDung} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("START.DATE")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "START.DATE",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].ngayBatDau} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("END.DATE")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "END.DATE",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].ngayKetThuc} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("LOCATION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "LOCATION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].diaDiemToChuc} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("OBJECTIVE")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "OBJECTIVE",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].mucDichToChuc} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("IMPLEMENTATION.FORM")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "IMPLEMENTATION.FORM",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].hinhThucThucHien} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("EXPECTED.ATTENDEES")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "EXPECTED.ATTENDEES",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].soNguoiDuKien} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IWelfare>
        tableProps={props}
        title={useCustomIntl("STATUS")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "STATUS",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].trangThai} />,
  },
  
];

export { WelfareColumn };
