import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useCustomIntl } from "../utils/FunctionUtils";
import "../styles/index.scss";
type ConfirmDialogProps = {
  view: boolean;
  onYesClick: () => void;
  onCancelClick: () => void;
  Title?: string;
  Description: string;
};
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  view,
  onYesClick,
  onCancelClick,
  Title = "Xác nhận",
  Description,
}) => {
  return (
    <Modal
      show={view}
      onHide={onCancelClick}
      size="sm"
      centered
      dialogClassName="confirm-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>{Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-8 px-4">
        <span className="fw-semibold fs-5">{Description}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" size="sm" onClick={onCancelClick}>
          {useCustomIntl("BTN.CANCEL")}
        </Button>

        <Button variant="primary" size="sm" onClick={onYesClick}>
          {useCustomIntl("BTN.YES")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmDialog;
