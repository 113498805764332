import { Column } from 'react-table';
import { TableCustomHeader } from '../../component/table-custom/columns/TableCustomHeader';
import { TableCustomCell } from '../../component/table-custom/columns/TableCustomCell';
import { useIntl } from 'react-intl';
import { ResignInfo } from '../models/IResign';

function useCustomIntl(messageId: string) {
    const intl = useIntl();
    return intl.formatMessage({ id: messageId });
}
const ResignColumn: ReadonlyArray<Column<ResignInfo>> = [
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('RESIGN.EMPLOYEECODE')}
                className='text-center text-light min-w-125px '
            />
        ),
        id: 'RESIGN.EMPLOYEECODE',
        Cell: ({ ...props }) => {
            let employeeCode = props.data[props.row.index].employeeCode;
            return (
                <TableCustomCell
                    data={employeeCode ? employeeCode : '-'}
                    action={true}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('GENERAL.FULLNAME')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'GENERAL.FULLNAME',
        Cell: ({ ...props }) => {
            let tenNguoiLaoDong = props.data[props.row.index].tenNguoiLaoDong;
            return (
                <TableCustomCell
                    data={tenNguoiLaoDong ? tenNguoiLaoDong : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('GENERAL.CURRENT_ORGANIZATION')}
                className='text-center text-light min-w-150px'
            />
        ),
        id: 'GENERAL.CURRENT_ORGANIZATION',
        Cell: ({ ...props }) => {
            let donViCongTac = props.data[props.row.index].donViCongTac;
            return (
                <TableCustomCell
                    data={donViCongTac ? donViCongTac : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('GENERAL.EMPLOYEE.POSITION')}
                className='text-center text-light min-w-150px'
            />
        ),
        id: 'GENERAL.EMPLOYEE.POSITION',
        Cell: ({ ...props }) => {
            let viTriCongViecText =
                props.data[props.row.index].viTriCongViecText;
            return (
                <TableCustomCell
                    data={viTriCongViecText ? viTriCongViecText : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('RESIGN.NEW_ORGANIZATION')}
                className='text-center text-light min-w-150px'
            />
        ),
        id: 'RESIGN.NEW_ORGANIZATION',
        Cell: ({ ...props }) => {
            let donViCongTacMoi =
                props.data[props.row.index].donViCongTacMoi;
            return (
                <TableCustomCell
                    data={donViCongTacMoi ? donViCongTacMoi : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('RESIGN.NEWPOSITION')}
                className='text-center text-light min-w-150px'
            />
        ),
        id: 'RESIGN.NEWPOSITION',
        Cell: ({ ...props }) => {
            let viTriCongViecMoiText =
                props.data[props.row.index].viTriCongViecMoiText;
            return (
                <TableCustomCell
                    data={viTriCongViecMoiText ? viTriCongViecMoiText : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('INPUT.WORKPROCESS.DECISION.NUMBER')}
                className='text-center text-light min-w-125px'
            />
        ),
        id: 'INPUT.WORKPROCESS.DECISION.NUMBER',
        Cell: ({ ...props }) => {
            let soQuyetDinh =
                props.data[props.row.index].soQuyetDinh;
            return (
                <TableCustomCell
                    data={soQuyetDinh ? soQuyetDinh : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<ResignInfo>
                tableProps={props}
                title={useCustomIntl('RESIGN.STATUS')}
                className='text-center text-light min-w-125px'
            />
        ),
        id: 'RESIGN.STATUS',
        Cell: ({ ...props }) => {
            let trangThai =
                props.data[props.row.index].trangThai;
            return (
                <TableCustomCell
                    data={trangThai ? trangThai : '-'}
                />
            );
        }
    }
];

export { ResignColumn };
