import { Column } from 'react-table';
import { TableCustomHeader } from '../../component/table-custom/columns/TableCustomHeader';
import { TableCustomCell } from '../../component/table-custom/columns/TableCustomCell';
import { useIntl } from 'react-intl';
import { IssueEmployees } from '../models/IIssue';

function useCustomIntl(messageId: string) {
    const intl = useIntl();
    return intl.formatMessage({ id: messageId });
}
const IssueEmployeesColumn: ReadonlyArray<Column<IssueEmployees>> = [
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('GENERAL.FULLNAME')}
                className='text-center text-light min-w-175px '
            />
        ),
        id: 'GENERAL.FULLNAME',
        Cell: ({ ...props }) => {
            let hoVaTen = props.data[props.row.index].hoVaTen;
            return (
                <TableCustomCell data={!!hoVaTen ? hoVaTen : '-'} />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('INPUT.CODE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'INPUT.CODE',
        Cell: ({ ...props }) => {
            let maNhanVien = props.data[props.row.index].maNhanVien;
            return (
                <TableCustomCell
                    data={!!maNhanVien ? maNhanVien : '-'}
                    action={true}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('GENERAL.CURRENT_ORGANIZATION')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'GENERAL.CURRENT_ORGANIZATION',
        Cell: ({ ...props }) => {
            let donViCongTac = props.data[props.row.index].donViCongTac;
            return (
                <TableCustomCell
                    data={!!donViCongTac ? donViCongTac : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('GENERAL.EMPLOYEE.POSITION')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'GENERAL.EMPLOYEE.POSITION',
        Cell: ({ ...props }) => {
            let viTriCongViec =
                props.data[props.row.index].viTriCongViec;
            return (
                <TableCustomCell
                    data={!!viTriCongViec ? viTriCongViec : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('ISSUE.FORM_HANDLING')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.FORM_HANDLING',
        Cell: ({ ...props }) => {
            let hinhThucXuLy = props.data[props.row.index].hinhThucXuLy;
            return (
                <TableCustomCell
                    data={!!hinhThucXuLy ? hinhThucXuLy.title : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('ISSUE.COMPENSATION_VALUE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.COMPENSATION_VALUE',
        Cell: ({ ...props }) => {
            let giaTriPhaiBoiThuong =
                props.data[props.row.index].giaTriPhaiBoiThuong;
            return (
                <TableCustomCell
                    data={!!giaTriPhaiBoiThuong ? giaTriPhaiBoiThuong : ''}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('ISSUE.COMPENSATED_VALUE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.COMPENSATED_VALUE',
        Cell: ({ ...props }) => {
            let giaTriDuocBoiThuong = props.data[props.row.index].giaTriDuocBoiThuong;
            return (
                <TableCustomCell data={!!giaTriDuocBoiThuong ? giaTriDuocBoiThuong : '-'} />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('GENERAL.ATTACHMENT')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'GENERAL.ATTACHMENT',
        Cell: ({ ...props }) => {
            let tepDinhKem = props.data[props.row.index].tepDinhKem;
            return <TableCustomCell data={!!tepDinhKem ? tepDinhKem : '-'} />;
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueEmployees>
                tableProps={props}
                title={useCustomIntl('ISSUE.STATUS')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.STATUS',
        Cell: ({ ...props }) => {
            let trangThai = props.data[props.row.index].trangThai;
            return <TableCustomCell data={!!trangThai ? trangThai.title : '-'} />;
        }
    }
];

export { IssueEmployeesColumn };
