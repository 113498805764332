import { IDismissed } from "../model/IDismissed";
export  const dismissed: IDismissed[] = [
    {
        manhanvien: "NV001",
        hovaten: "Nguyễn Văn A",
        DonViCongTac: "Đơn vị A",
        vitricongviec: "Vị trí A",
        DonViCongTacmoi: "Đơn vị B",
        vitricongviecmoi: "Vị trí B",
        soquyetdinh: "123456",
        trangthai: "Đã quyết định",
        tepdinhkem: "file1.pdf"
    },
    {
        manhanvien: "NV002",
        hovaten: "Nguyễn Văn B",
        DonViCongTac: "Đơn vị X",
        vitricongviec: "Vị trí X",
        DonViCongTacmoi: "Đơn vị Y",
        vitricongviecmoi: "Vị trí Y",
        soquyetdinh: "789012",
        trangthai: "Chưa quyết định",
        tepdinhkem: "file2.pdf"
    },
];







