import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../../component/table-custom/columns/TableCustomCell";
import { useIntl } from "react-intl";
import { IDismissed } from "../model/IDismissed";
function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}
const DismissedColumn: ReadonlyArray<Column<IDismissed>> = [
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.CODE")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.CODE",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].hovaten} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.NAME")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.NAME",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].hovaten} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.WORKUNINT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.WORKUNINT",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].DonViCongTac} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.JOBPOSITIION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.JOBPOSITIION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].vitricongviec} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("DISMISSED.NEWJOBPOSITION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "DISMISSED.NEWJOBPOSITION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].vitricongviecmoi} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("DISMISSED.WORKUNINT")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "DISMISSED.WORKUNINT",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].DonViCongTacmoi} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.NUMBER")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.NUMBER",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].soquyetdinh} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<IDismissed>
        tableProps={props}
        title={useCustomIntl("APPOINTMENT.STATUS")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "APPOINTMENT.STATUS",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].trangthai} />,
  },
];

export { DismissedColumn };
