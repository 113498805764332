import { createContext } from "react";

interface DanhSachSanPhamContextProps {
    danhSachDonVi: any[]
    handleClickTableCell: (rowData: any) => void
    handleOpenSanPhamDialog: (rowData: any) => void
    handleOpenConfirmDialog: (rowData: any) => void
}

const DanhSachSanPhamContext = createContext < Partial<DanhSachSanPhamContextProps>>({});

export default DanhSachSanPhamContext;