import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import StatusColor from "../../component/status-color/StatusColor";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { formatPrice, totalPriceService } from "../../utils/FunctionUtils";
import { CODE_SUCCESS, ERROR_MESSAGE, MESSAGE_SUCCESS, initialValue } from "../../../../constants";
import { LIST_TYPE_POS, STATUS_PAY, STATUS_PROCESSING, STATUS_SUCCESS, TYPE_ORDER } from "../const/OrderConst";
import { DEFAULT_IMG_URL, PRODUCT_TYPE } from "../../danh-sach-san-pham/constants";
import HoaDonDialog from "./HoaDonDialog";
import { getListSanPham } from "../../pos/services/PosServices";
import { toast } from "react-toastify";
import { IAttributeType, IDonHang } from "../../pos/models/PosModels";
import SanPham from "../../pos/component/SanPham";
import { ISanPham, SanPham as SanPhamModel } from "../../danh-sach-san-pham/models/ISanPham";
import ThongTinSanPhamDialog from "../../danh-sach-san-pham/components/dialogs/ThongTinSanPhamDialog";
import DonHangDialog from "../../pos/component/Dialog/DonHangDialog";
import { deleteCartItem, resetCartList, totalPrice } from "../../pos/reducer/PosSlice";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { getOrderById, updateDonHang } from "../services/DonHangServices";
import { detailDonHang } from "../reducer/DonHangSlice";
import ConfirmDialog from "../../component/ConfirmDialog";
import { TablePagination } from "../../component/pagination-custom/TablePagination";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_PAGES } from "../../category-personnel/const/PersonnelConst";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { DEFAULT_CUSTOMER, TYPE_POS } from "../../pos/const/PosConst";

type Props = {};

const DetailDonHang = (props: Props) => {
  const dispatch = useAppDispatch();
  const { type } = useParams();
  const detailDonHangReducer = useAppSelector(
    (state) => state.donHang.detailDonHang
  );
  const cartListReducer = useAppSelector(
    (state) => state.pos.cartList
  );
  const subTotalReducer = useAppSelector((state) => state.pos.totalPrice);
  const isHidden =
    detailDonHangReducer.lstProduct?.[0]?.attributeTypes === null;
  const [shouldOpenDialogHoaDon, setShouldOpenDialogHoaDon] =
    useState<boolean>(false);
  const [listSanPham, setListSanPham] = useState([]);
  const [sanPham, setSanPham] = useState<Partial<{ product: ISanPham & SanPhamModel, attributeTypes: any[] }>>();
  const [shouldOpenDialogSanPham, setShouldOpenDialogSanPham] = useState(false);
  const [shouldOpenDialogDonHang, setShouldOpenDialogDonHang] = useState(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [paymentName, setPaymentName] = useState("");

  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowPerPage, setRowPerPage] = useState<number>(3);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES);
  const [totalElements, setTotalElements] =
    useState<number>(DEFAULT_TOTAL_PAGES);
  const date = moment(new Date()).format("DD/MM/YYYY hh:mm");

  const listIdProduct = cartListReducer.map((item) => {
    if (item?.attributeTypes?.length > 0) {
      const newAttributeTypes = item.attributeTypes.map((pItem) => {
        return {
          ...pItem,
          attributes: pItem?.attributes?.map((item) => {
            return {
              ...item,
              price: item?.totalPrice ? item.totalPrice : item?.price,
            }
          })
        }
      })
      return {
        productId: item.product.id,
        productAttributeType:
          newAttributeTypes?.length > 0 ? newAttributeTypes : [],
      };
    } else {
      return {
        productId: item.product.id,
        totalPrice: item.product.price,
        quantity: item.product.quantity,
      };
    }
  });

  const totalPriceProduct = cartListReducer?.reduce(
    (total: number, item: any) => {
      return total + item?.product?.totalPrice
    },
    initialValue
  );

  const totalQuantity = cartListReducer.reduce((total, item) => {
    return total + item.product?.quantity;
  }, initialValue);

  useEffect(() => {
    dispatch(totalPrice());
    reloadData && handleGetListSanPham()
    detailDonHangReducer?.orderDto?.paymentName &&
      setPaymentName(detailDonHangReducer?.orderDto?.paymentName === STATUS_PAY.PAID.name
        ? STATUS_PAY.PAID.id : STATUS_PAY.UN_PAID.id)
  }, [cartListReducer, reloadData, pageIndex, rowPerPage]);

  const handleShouldOpenDialogHoaDon = () => {
    handleGetDonHangById(detailDonHangReducer?.orderDto?.id)
    setShouldOpenDialogHoaDon(true);
  };

  const handleGetDonHangById = async (id: string | number) => {
    try {
      const result = await getOrderById(id);
      if (result?.data?.code === CODE_SUCCESS) {
        dispatch(detailDonHang(result?.data?.data));
        // setDonHang(result?.data?.data);
        dispatch(resetCartList(result?.data?.data?.lstProduct))
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleCloseDialogHoaDon = () => {
    setShouldOpenDialogHoaDon(false);
  };

  const handleChangPaymentName = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentName(e.target.value)
    handleThanhToan(e.target.value)
  }

  const handleThanhToan = async (paymentId: string) => {

    const object: IDonHang = {
      lstProduct: listIdProduct,
      orderDto: {
        deliveryPrice: 0,
        orderType: 0,
        originalPrice: subTotalReducer,
        paymentMethod: "COD",
        paymentName: paymentId === STATUS_PAY.PAID.id ? STATUS_PAY.PAID.name : STATUS_PAY.UN_PAID.name,
        phoneNumber: detailDonHangReducer?.orderDto?.phoneNumber || "",
        price: subTotalReducer,
        promotionPrice: 0,
        quantity: totalQuantity,
        type: 0,
        status: STATUS_SUCCESS[0],
        statusId: 5,
        customerName: detailDonHangReducer?.orderDto?.customerName
          ? detailDonHangReducer?.orderDto?.customerName
          : DEFAULT_CUSTOMER.name,
      },
    };

    handleUpdateDonHang(detailDonHangReducer?.orderDto?.id, object)

  };


  const handleUpdateDonHang = async (id: string | number, data: IDonHang) => {
    try {
      const result = await updateDonHang(id, data);
      if (result?.data?.code === CODE_SUCCESS) {
        handleGetDonHangById(id);
        toast.success(MESSAGE_SUCCESS.updateDonHang);
        setShouldOpenDialogHoaDon(true);
      } else {
        toast.error(ERROR_MESSAGE);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  }

  const handleEditDonHang = () => {
    setReloadData(true)
  }

  const handleGetListSanPham = async () => {
    const searchObject = {
      pageIndex: pageIndex,
      pageSize: rowPerPage,
      orgId: detailDonHangReducer?.orderDto?.orgId,
      // name: debounceSearch,
      productType: detailDonHangReducer?.lstProduct?.[0]?.product?.orgDto?.type === TYPE_POS.TIEM_GIAT.id
        ? PRODUCT_TYPE.DICH_VU : PRODUCT_TYPE.HANG_HOA

    };
    try {
      const result = await getListSanPham(searchObject);
      if (result?.data?.code === CODE_SUCCESS) {
        setListSanPham(result?.data?.data?.content);
        setTotalPages(result?.data?.data?.totalPages);
        setTotalElements(result?.data?.data?.totalElements);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleOpenInfoProduct = (item: any, name?: string) => {
    setShouldOpenDialogSanPham(true);
    setSanPham({ ...sanPham, product: item });
  };

  const handleCloseDialogSanPham = () => {
    setShouldOpenDialogSanPham(false);
  };

  const handleShouldOpenDialogDonHang = () => {
    cartListReducer.length > 0
      ? setShouldOpenDialogDonHang(true)
      : toast.warning("Vui lòng thêm sản phẩm");
  };

  const handleCloseDialogDonHang = () => {
    setShouldOpenDialogDonHang(false);
  };

  const handleHuyThaoTacSua = () => {
    handleGetDonHangById(detailDonHangReducer?.orderDto?.id)
    // setListSanPham([])
    setReloadData(false);
    setShouldOpenConfirmDialog(false);
    toast.success("Hủy thành công")
  }

  const handleShouldOpenConfirmDialog = () => {
    setShouldOpenConfirmDialog(true);
  }

  return (
    <Container className="px-8">
      <Row>
        <span className="ps-0">
          <Link
            to={
              !LIST_TYPE_POS.includes(type as string)
                ? `/don-hang/${type}`
                : `/pos/${type}/don-hang`
            }
            className="fs-4 fw-semibold"
          >
            <i className="bi bi-box-arrow-left text-primary fs-4"></i> Quay lại
          </Link>
        </span>
      </Row>

      <Row className="bg-white rounded p-4 pe-0 mt-2">
        <Row className="pb-4">
          <Row className=" align-items-center">
            <ul className="list-unstyled d-flex align-items-center">
              <li>
                <span className="pe-3 fw-bold fs-3">
                  Đơn hàng #{detailDonHangReducer?.orderDto?.code}
                </span>
              </li>

              <li>
                <span className="px-3">
                  {detailDonHangReducer?.orderDto?.paymentName ? <StatusColor
                    children={detailDonHangReducer?.orderDto?.paymentName}
                    type={`${detailDonHangReducer?.orderDto?.paymentName === STATUS_PAY.PAID.name ? "success" : "cancel"}`}
                  /> : ""}
                </span>
              </li>

              <li>
                <span className="px-3">
                  {detailDonHangReducer?.orderDto?.status ? <StatusColor
                    children={detailDonHangReducer?.orderDto?.status}
                    type={`${detailDonHangReducer?.orderDto?.status === STATUS_SUCCESS[0]
                      ? "success"
                      : detailDonHangReducer?.orderDto?.status === STATUS_PROCESSING
                        ? "pending"
                        : "cancel"
                      }`}
                  /> : ""}
                </span>
              </li>

              <li>
                <span className="px-3 text-secondary fw-semibold">
                  <i className="fa-regular fa-calendar-days"></i> {date}
                </span>
              </li>
            </ul>
          </Row>

          <Row className="align-items-center">
            <ul className=" list-unstyled d-flex align-items-center">
              <li className="d-flex me-20">
                <li className="px-4 ps-0">
                  <a
                    href="#"
                    className="fs-5"
                    onClick={handleShouldOpenDialogHoaDon}
                  >
                    <i className="fa-solid fa-print"></i> In hóa đơn
                  </a>
                </li>

                <li className="fs-5 px-4 fw-semibold">
                  <span>
                    <i className="fa-solid fa-store text-dark"></i> Cửa hàng:{" "}
                  </span>
                  <span>
                    <StatusColor
                      children={
                        detailDonHangReducer?.lstProduct?.[0]?.product?.orgDto
                          ?.name
                      }
                      type="name"
                    />
                  </span>
                </li>

                <li>
                  <Form.Select value={paymentName} onChange={handleChangPaymentName}>
                    <option value={STATUS_PAY.PAID.id}>{STATUS_PAY.PAID.name}</option>
                    <option value={STATUS_PAY.UN_PAID.id}>{STATUS_PAY.UN_PAID.name}</option>
                  </Form.Select>
                </li>
              </li>
            </ul>
          </Row>
        </Row>

        <Row>
          <Col xs="8" className="border rounded me-3 bg-white shadow-sm">
            <Row className="border-bottom p-3">
              <Col>
                <Row className="pb-3 border-bottom">
                  <Col className="ps-0 fs-4 fw-semibold">Thông tin đơn hàng</Col>

                  <Col className="text-end">
                    <Button size="sm" className="fs-6" onClick={handleEditDonHang}>
                      <i className="fa-solid fa-pen"></i> Chỉnh sửa đơn hàng
                    </Button>
                  </Col>
                </Row>

                <Row className="pt-2 text-secondary fw-semibold border-bottom mb-5">
                  <Col xs="5" className="ps-0">Ghi chú: </Col>

                  <Col xs className="text-end">
                    <div className="mb-2 text-secondary fw-semibold">
                      Phương thức thanh toán:{" "}
                      {detailDonHangReducer?.orderDto?.paymentMethod}
                    </div>

                    <div className="mb-2 text-secondary fw-semibold">
                      Đơn hàng loại: {detailDonHangReducer?.orderDto?.orderType === TYPE_ORDER.ONLINE.id
                        ? TYPE_ORDER.ONLINE.value
                        : TYPE_ORDER.OFFLINE.value}
                    </div>
                  </Col>
                </Row>

                {reloadData &&
                  <Row className="p-4 pb-8 border-bottom mb-5 min-h-300px justify-content-center">
                    {listSanPham.map((item, index) => {
                      return (
                        <SanPham
                          key={index}
                          item={item}
                          handleOpenInfoProduct={handleOpenInfoProduct}
                        />
                      );
                    })}
                    <TablePagination
                      handlePagesChange={handlePagesChange}
                      handleRowsPerPageChange={handleRowsPerPageChange}
                      rowsPerPage={rowPerPage}
                      rowsForPage={rowsForPage}
                      page={pageIndex}
                      setPage={setPageIndex}
                      setRowsPerPage={setRowPerPage}
                      totalPages={totalPages}
                      totalElements={totalElements}
                    />
                  </Row>}

                <Row className="px-0">
                  <Table responsive>
                    <thead className="border">
                      <tr>
                        <th className="text-center">Ảnh</th>
                        <th className="text-center">Menu Dịch vụ</th>
                        {isHidden && <th className="text-center">Số lượng</th>}
                        <th className="text-center min-w-30px">Giá</th>
                        {reloadData && <th className="text-center min-w-50px">Thao tác</th>}
                      </tr>
                    </thead>

                    <tbody className="border">
                      {cartListReducer?.map(
                        (product, index: number) => {
                          return (
                            <tr key={index} className="border-bottom">
                              <td className="text-center">
                                <img
                                  className="w-60px h-60px rounded-circle"
                                  src={
                                    product?.product?.logoUrl || DEFAULT_IMG_URL
                                  }
                                  alt=""
                                />
                              </td>
                              <td>
                                <div>
                                  {product?.product?.name}{" "}
                                  <p>
                                    {product?.attributeTypes?.length > 0
                                      ? product?.attributeTypes?.map(
                                        (item: any, index: number) => {
                                          return item?.attributes?.map(
                                            (itemAtt: any, index: number) => {
                                              return (
                                                <p className="mb-1 text-secondary">
                                                  {itemAtt?.quantity ? (itemAtt?.quantity + ' ' + itemAtt?.unit) : itemAtt?.name}:{" "}
                                                  <span className="fw-semibold ">
                                                    {formatPrice(
                                                      itemAtt?.totalPrice || itemAtt?.price
                                                    )}{" "}
                                                  </span>
                                                </p>
                                              );
                                            }
                                          );
                                        }
                                      )
                                      : ""}
                                  </p>
                                </div>
                              </td>
                              {isHidden && (
                                <td className="text-center">
                                  <span>{product?.product?.quantity}</span>
                                </td>
                              )}
                              <td className="text-end pe-2">
                                <span>
                                  {formatPrice(
                                    product?.attributeTypes?.reduce(
                                      (accumulator: any, currentItem: IAttributeType) => {
                                        const prices = currentItem?.attributes?.map(item => item?.totalPrice ? item?.totalPrice : item?.price)
                                        return (
                                          accumulator + prices?.reduce((total: number, item) => { return total + +item }, initialValue)

                                        );
                                      },
                                      initialValue
                                    ) || (product?.product?.totalPrice ? product?.product?.totalPrice : product?.product?.price)
                                  )}{" "}
                                  ₫
                                </span>
                              </td>

                              {reloadData &&
                                <td>
                                  <div className="d-flex justify-content-center">
                                    {/* <CustomIconButton variant="edit" onClick={() => handleOpenInfoProduct(product, "edit")
                                    } /> */}
                                    <CustomIconButton variant="delete" onClick={() => dispatch(deleteCartItem(product?.product))
                                    } />
                                  </div>
                                </td>}
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Row>
              </Col>
            </Row>

            <Row className="text-end p-4">
              <Col></Col>
              <Col>
                <Row className="mb-1">
                  <Col className="fw-semibold ">Xả vải:</Col>
                  <Col className=" text-secondary">0 ₫</Col>
                </Row>

                <Row className="mb-1">
                  <Col className="fw-semibold ">Chiết khấu:</Col>
                  <Col className=" text-secondary">- 0 ₫</Col>
                </Row>

                <Row className="mb-1">
                  <Col className="fw-semibold ">Mã giảm giá:</Col>
                  <Col className=" text-secondary">- 0 ₫</Col>
                </Row>

                <Row className="mb-1">
                  <Col className="fw-semibold ">Phí giao hàng:</Col>
                  <Col className=" text-secondary">+ 0 ₫</Col>
                </Row>

                <Row className="mb-3">
                  <Col className="fw-semibold fs-4">Tổng cộng:</Col>
                  <Col className="fs-4 fw-semibold">
                    {formatPrice(
                      totalPriceService(cartListReducer) ? totalPriceService(cartListReducer) : totalPriceProduct
                    )}{" "}
                    ₫
                  </Col>
                </Row>

                {reloadData && <Row>
                  <Col>
                    <Button variant="danger" size="sm" onClick={handleShouldOpenConfirmDialog}>
                      Hủy
                    </Button>
                  </Col>
                  <Col>
                    <Button size="sm" onClick={handleShouldOpenDialogDonHang}>Lưu</Button>
                  </Col>
                </Row>}
              </Col>
            </Row>
          </Col>

          <Col xs className="border rounded bg-white shadow-sm h-500px">
            <Row className="p-3 pb-4 border-bottom">
              <Col className="ps-0 fs-4 fw-semibold">Khách hàng</Col>
            </Row>

            <Row className="p-3">
              <Col>
                <Row className="pb-4 border-bottom align-items-center">
                  <Col xs="3" className="w-50px h-50px p-0 me-3">
                    <Card.Img
                      className="rounded-circle"
                      src="http://goodhere.oceantech.com.vn/public/assets/admin/img/160x160/img1.jpg"
                    />
                  </Col>

                  <Col xs>
                    <Row className="fw-semibold">
                      {detailDonHangReducer?.orderDto?.customerName}
                    </Row>
                    <Row className="fw-semibold">
                      {detailDonHangReducer?.orderDto?.phoneNumber}
                    </Row>
                  </Col>
                </Row>

                <Row className="py-4">
                  <Col>
                    <Row className="pb-3">
                      <Col className="ps-0">
                        <span className="fw-semibold fs-5">
                          Thông tin giao hàng
                        </span>
                      </Col>

                      <Col className="text-end text-primary cursor-pointer">
                        <span>
                          <i className="fa-solid fa-pen text-primary"></i> Chỉnh sửa
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <ul className="ps-0 list-unstyled">
                        <li className="fw-semibold text-secondary mb-2">
                          Tên:{" "}
                          <span className="text-dark">
                            {detailDonHangReducer?.orderDto?.customerName}
                          </span>
                        </li>
                        <li className="fw-semibold text-secondary mb-2">
                          Liên hệ:{" "}
                          <span className="text-dark">
                            {detailDonHangReducer?.orderDto?.phoneNumber}
                          </span>
                        </li>
                        <li className="fw-semibold text-secondary mb-2">Tầng: </li>
                        <li className="fw-semibold text-secondary mb-2">Đường: </li>
                        <li className="fw-semibold text-secondary mb-2">Nhà: </li>
                        <li className="fw-semibold text-secondary mb-2">
                          <a href="#">Địa chỉ</a>
                        </li>
                      </ul>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>

      {shouldOpenDialogHoaDon && (
        <HoaDonDialog
          donHang={detailDonHangReducer}
          handleCloseDialog={handleCloseDialogHoaDon}
        />
      )}

      {shouldOpenDialogSanPham && (
        <ThongTinSanPhamDialog
          dataSanPham={sanPham?.product}
          handleClose={handleCloseDialogSanPham}
        />
      )}

      {shouldOpenDialogDonHang && (
        <DonHangDialog
          resetCartList={() => setReloadData(false)}
          handleCloseDialog={handleCloseDialogDonHang}
          totalPriceThuocTinh={totalPriceService(cartListReducer)}
          orderId={detailDonHangReducer?.orderDto?.id}
        />
      )}

      {shouldOpenConfirmDialog &&
        <ConfirmDialog
          Description="Bạn có chắc chắn muốn hủy không?"
          view={shouldOpenConfirmDialog}
          onYesClick={handleHuyThaoTacSua}
          onCancelClick={() => setShouldOpenConfirmDialog(false)}
        />}
    </Container>
  );
};

export default DetailDonHang;
