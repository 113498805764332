import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { TableCustom } from "../component/table-custom/TableCustom";
import { AppreciativeInFo } from "./model/appreciativeModel";
import { AppreciativeColumn } from "./component/AppreciativeColumn";
import AppreciativeDialog from "./component/AppreciativeDialog";
type Props = {};

const Appreciative = (props: Props) => {
  const intl = useIntl();
  const appreciativeInfoArray: AppreciativeInFo[] = [
    {
      id: "1",
      name: "Nguyễn Văn A",
      donViApDungId: "1",
      donViApDungText: "Đơn vị A",
      date: new Date(),
      loaiKhenThuongId: "1",
      loaiKhenThuongText: "Loại khen thưởng A",
      soQuyetDinh: "QD001",
      ngayQuyetDinh: new Date(),
      nguoiQuyetDinhId: "1",
      nguoiQuyetDinhText: "Người quyết định A",
      chucDanhNguoiQuyetDinh: "Chức danh A",
      hinhThucKhenThuongId: "1",
      hinhThucKhenThuongText: "Hình thức khen thưởng A",
      tongGiaTri: "1000000",
      doiTuongKhenThuongId: "1",
      doiTuongKhenThuongText: "Đối tượng khen thưởng A",
      chiTietDoiTuongId: "1",
      chiTietDoiTuongText: "Chi tiết đối tượng A",
      capKhenThuongId: "1",
      capKhenThuongText: "Cấp khen thưởng A",
      lyDo: "Lý do khen thưởng",
      trangThaiId: "1",
      trangThaiText: "Trạng thái A",
    },
    {
      id: "2",
      name: "Trần Thị B",
      donViApDungId: "2",
      donViApDungText: "Đơn vị B",
      date: new Date(),
      loaiKhenThuongId: "2",
      loaiKhenThuongText: "Loại khen thưởng B",
      soQuyetDinh: "QD002",
      ngayQuyetDinh: new Date(),
      nguoiQuyetDinhId: "2",
      nguoiQuyetDinhText: "Người quyết định B",
      chucDanhNguoiQuyetDinh: "Chức danh B",
      hinhThucKhenThuongId: "2",
      hinhThucKhenThuongText: "Hình thức khen thưởng B",
      tongGiaTri: "2000000",
      doiTuongKhenThuongId: "2",
      doiTuongKhenThuongText: "Đối tượng khen thưởng B",
      chiTietDoiTuongId: "2",
      chiTietDoiTuongText: "Chi tiết đối tượng B",
      capKhenThuongId: "2",
      capKhenThuongText: "Cấp khen thưởng B",
      lyDo: "Lý do khen thưởng",
      trangThaiId: "2",
      trangThaiText: "Trạng thái B",
    },
  ];

  const [appreciative, setAppreciative] = useState<AppreciativeInFo>(
    {} as AppreciativeInFo
  );
  const [listAppreciative, setListAppreciative] = useState<AppreciativeInFo[]>(
    appreciativeInfoArray
  );
  const [shouldOpenAppreciativeDialog, setShouldOpenAppreciativeDialog] =
    useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const handleCloseDialog = () => {
    setShouldOpenAppreciativeDialog(false);
  };
  const handleOpenDialog = () => {
    setShouldOpenAppreciativeDialog(true);
  };
  const handleDeleteAppreciative = () => {};
  const handleChangeAppreciative = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = event.target;
    setAppreciative({ ...appreciative, [name]: value });
  };
  const handleChangeSelect = (name: string, value: string) => {
    setAppreciative({ ...appreciative, [name]: value });
  };
  return !shouldOpenAppreciativeDialog ? (
    <>
      <div className="profile-title">
        <span className="fs-3 fw-bold">
          {intl.formatMessage({ id: "GENERAL.APPRECIATIVE.LIST" })}
          <i className="bi bi-three-dots-vertical"></i>
        </span>
        <button
          onClick={handleOpenDialog}
          className="btn btn-primary btn-sm btn-sm"
        >
          {intl.formatMessage({ id: "BTN.ADD" })}
        </button>
      </div>
      <TableCustom<AppreciativeInFo>
        data={listAppreciative}
        columns={AppreciativeColumn}
        compact={false}
        handleOpenInfoDialog={handleOpenDialog}
        // handleDelete={handleDeleteAppreciative}
      />
    </>
  ) : (
    <>
      <AppreciativeDialog
        handleOpenAppreciativeDialog={handleOpenDialog}
        handleCloseAppreciativeDialog={handleCloseDialog}
        show={shouldOpenAppreciativeDialog}
        appreciative={appreciative}
        handleChangeSelect={handleChangeSelect}
        handleChangeAppreciative={handleChangeAppreciative}
      ></AppreciativeDialog>
    </>
  );
};

export default Appreciative;
