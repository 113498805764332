import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table-custom/columns/TableCustomHeader";
import { TableCustomCell } from "../../component/table-custom/columns/TableCustomCell";
import { useIntl } from "react-intl";
import { ICompainon } from "../model/WelfareModel";
function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}
const ListCompanionColumn: ReadonlyArray<Column<ICompainon>> = [
  {
    Header: (props) => (
      <TableCustomHeader<ICompainon>
        tableProps={props}
        title={useCustomIntl("FULL.NAME")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "FULL.NAME",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].hoVaTen} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<ICompainon>
        tableProps={props}
        title={useCustomIntl("COMPANION.RELATION")}
        className="text-center text-light min-w-175px"
      />
    ),
    id: "COMPANION.RELATION",
    Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].quanHe} />,
  },


];

export { ListCompanionColumn };
