import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { InputCustom } from '../../component/input-field/InputCustom';
import { templateSelect } from '../const/transitionConst';
import { TransitionInFo } from '../models/transitionModel';
interface Props {
  handleOpenTransitionDialog: () => void,
  handleCloseTransitionDialog: () => void,
  show: boolean,
  transition: TransitionInFo;
}

export const TransitionDialog = (props: Props) => {
  const intl = useIntl();
  const { show, handleCloseTransitionDialog, transition, } = props;
  const [isView, setIsView] = useState<boolean>(false);
  const defaultTransition: any = {
    fullName: '',
    code: '',
    donViCongTac: '',
    viTriCongViec: '',
    donViCongTacMoiId: '',
    viTriCongTacMoiId: '',
    soQuyetDinh: '',
    transferStatus: '',
    fileName: '',
  };
  return (
    <Modal
      show={show}
      size='lg'
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseTransitionDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title"
          className='heading-5'
        >
          {intl.formatMessage({ id: 'INFO.DEGREE' })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={transition?.id ? transition : defaultTransition}
        onSubmit={(values, formikHelpers) =>
        {
          let obj2 = Object.assign({}, values);
          obj2.donViCongTacMoiId = values.donViCongTacMoiId.value;
        }

        }
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) =>
        (<Form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="block-content">
              <div className="content-two-col">
                <InputCustom
                  required={true}
                  type='text'
                  title="INPUT.CODE"
                  name='code'
                  values={values.code || ''}
                  readOnly={isView}
                  handleChange={handleChange}
                />
                <InputCustom
                  type='text'
                  title="INPUT.FULLNAME"
                  name='fullName'
                  values={values.fullName || ''}
                  readOnly={isView}
                  handleChange={handleChange}
                />
                <InputCustom
                  type='text'
                  title="INPUT.WORKPROCESS.UNIT"
                  name='donViCongTac'
                  values={values.donViCongTac || ''}
                  readOnly={isView}
                  handleChange={handleChange}
                />
                <InputCustom
                  type='text'
                  title="INPUT.WORKPROCESS.ROLE"
                  name='viTriCongViec'
                  values={values.viTriCongViec || ''}
                  readOnly={isView}
                  handleChange={handleChange}
                />
                <InputCustom
                  type='select'
                  title="INPUT.WORKPROCESS.UNIT.NEW"
                  name='donViCongTacMoiId'
                  values={values.donViCongTacMoiId || ''}
                  readOnly={isView}
                  handleChange={(selectedOptions:any) => {
                    setFieldValue(
                      'donViCongTacMoiId',
                    selectedOptions
                    )
                  }}
                  options={templateSelect}
                  optionLabel='label'
                  optionValue='value'
                />
                <InputCustom
                  type='select'
                  title="INPUT.WORKPROCESS.ROLE.NEW"
                  name='viTriCongTacMoiId'
                  values={values.viTriCongTacMoiId || ''}
                  readOnly={isView}
                  handleChange={(selectedOptions: any) => {
                    setFieldValue(
                      'viTriCongTacMoiId',
                      selectedOptions
                    );
                  }}
                  options={templateSelect}
                  optionLabel='label'
                  optionValue='value'
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex-center">
            <Button variant="outline-secondary" className='button-secondary btn-sm' onClick={() => handleCloseTransitionDialog()} > {intl.formatMessage({ id: 'BTN.CANCEL' })}</Button>
            <Button variant="outline-primary btn-sm" className='button-low-primary'> {intl.formatMessage({ id: 'BTN.ADDANDSAVE' })}</Button>
            <Button variant="primary" className='button-primary btn-sm' type='submit'> {intl.formatMessage({ id: 'BTN.SAVE' })}</Button>
          </Modal.Footer>
        </Form>)}
      </Formik>
    </Modal>
  );
};