import { createSlice } from "@reduxjs/toolkit";
import { ISanPham } from "../../danh-sach-san-pham/models/ISanPham";
import { toast } from "react-toastify";
import {
  DELETE_SUCCESS,
  MESSAGE_SUCCESS,
  initialValue,
} from "../../../../constants";
import { IProductItem } from "../models/PosModels";

type IInitialState = {
  cartList: IProductItem[];
  totalPrice: number;
};

const initialState: IInitialState = {
  cartList: [],
  totalPrice: 0,
};

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const product = {
        ...action.payload,
        quantity: action.payload.quantity ? action.payload.quantity : 1,
      };
      state.cartList.push(product);
      toast.success(MESSAGE_SUCCESS.addProductToCart);
    },

    decreaseQuantity: (state, action) => {
      const productIndex = state.cartList.findIndex(
        (item) => item?.product?.id === action.payload?.id
      );

      if (state.cartList[productIndex]?.product?.quantity > 1) {
        state.cartList[productIndex].product!.quantity -= 1;
        if (state.cartList[productIndex]?.product?.totalPrice) {
          state.cartList[productIndex].product!.price -= state.cartList[
            productIndex
          ]?.product?.subTotal;
        }
      }
    },

    increaseQuantity: (state, action) => {
      const productIndex = state.cartList.findIndex(
        (item) => item?.product?.id === action.payload?.id
      );

      if (state.cartList[productIndex].product?.quantity >= 1) {
        state.cartList[productIndex].product!.quantity += 1;
        if (state.cartList[productIndex]?.product?.totalPrice) {
          state.cartList[productIndex].product!.price += state.cartList[
            productIndex
          ]?.product?.subTotal;
        }
      }
    },

    resetCartList: (state, action) => {
      state.cartList = action.payload;
    },

    deleteCartItem: (state, action) => {
      const newCartList = state.cartList.filter(
        (item) => item?.product?.id !== action.payload.id
      );
      state.cartList = newCartList;
      toast.success(DELETE_SUCCESS);
    },

    totalPrice: (state) => {
      const totalPrice = state.cartList.reduce((total, product) => {
        return total + product.product?.price;
      }, initialValue);

      state.totalPrice = totalPrice;
    },
  },
});

export const {
  addToCart,
  totalPrice,
  deleteCartItem,
  resetCartList,
  increaseQuantity,
  decreaseQuantity,
} = posSlice.actions;
export default posSlice.reducer;
