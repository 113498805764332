import { useState } from "react";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { formatPrice } from "../../../utils/FunctionUtils";
import ConfirmDialog from "../../../component/ConfirmDialog";
import { deleteCartItem } from "../../reducer/PosSlice";
import { useAppDispatch } from "../../../../hook";
import {
  IAttribute,
  IAttributeType,
  IProductItem,
} from "../../models/PosModels";
import { initialValue } from "../../../../../constants";

type Props = {
  sanPham: IProductItem;
};

const CartDichVu = ({ sanPham }: Props) => {
  const dispatch = useAppDispatch();
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState(false);

  const handleOpenConfirmDialog = () => {
    setShouldOpenConfirmDialog(true);
  };

  const handleDeleteCartItem = () => {
    dispatch(deleteCartItem(sanPham.product));
    setShouldOpenConfirmDialog(false);
  };
  return (
    <tr className="border-bottom">
      <td className="px-2">
        <div>
          {sanPham?.product?.name}{" "}
          <p className="ps-2">
            {sanPham?.attributeTypes?.length > 0
              ? sanPham?.attributeTypes?.map((item: IAttributeType) => {
                  return item?.attributes?.map(
                    (itemAtt: IAttribute, index: number) => {
                      return (
                        <p key={index} className="mb-1 text-secondary">
                          {itemAtt?.quantity
                            ? `${itemAtt?.quantity} ${itemAtt?.unit}`
                            : itemAtt?.name}
                          :{" "}
                          <span className="fw-semibold ">
                            {formatPrice(+itemAtt?.price)}
                            {" ₫"}
                          </span>
                        </p>
                      );
                    }
                  );
                })
              : ""}
          </p>
        </div>
      </td>
      <td className="text-end px-2">
        <span>
          {formatPrice(
            sanPham?.attributeTypes?.reduce(
              (totalPrice: number, currentItem: IAttributeType) => {
                const prices = currentItem?.attributes?.map(item => item?.price)
                return (
                  totalPrice + prices?.reduce((total: number, item) => { return total + +item }, initialValue)

                );
              },
              initialValue
            )
          )}
          {" ₫"}
        </span>
      </td>
      <td className="text-center pe-2">
        <CustomIconButton variant="delete" onClick={handleOpenConfirmDialog} />
      </td>
      {shouldOpenConfirmDialog && (
        <ConfirmDialog
          view={shouldOpenConfirmDialog}
          onYesClick={handleDeleteCartItem}
          onCancelClick={() => setShouldOpenConfirmDialog(false)}
          Description="Bạn có chắc chắn muốn xóa không?"
        />
      )}
    </tr>
  );
};

export default CartDichVu;
