import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { TableCustom } from "../component/table-custom/TableCustom";
import { IAppointment } from "./models/IAppointment";
import { AppointmentColumn } from "./components/AppointmentColumn";
import { appointments } from "./const/AppointmentConst";
import { AppointmentDialog } from "./components/AppointmentDialog";
const AppointmentList: FC = () => {
  const intl = useIntl();
  const [listAppointment, setListAppointment] = useState<IAppointment[]>(appointments);
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState<boolean>();
  const [appointmentInfo, setAppointmentInfo] = useState<IAppointment>({} as IAppointment);
  const handleOpenAppointmentDialog = (info: any) => {
    setOpenAppointmentDialog(true);
    setAppointmentInfo(info.original);
  };
  const handleCLoseAppointmentDialog = () => {
    setOpenAppointmentDialog(false);
    setAppointmentInfo({} as IAppointment);
  };
  return (
    <>
      {" "}
      <div className="profile-title">
        <span className="fs-3 fw-bold">
          {intl.formatMessage({ id: "GENERAL.APPOINTMENT.LIST" })}
          <i className="bi bi-three-dots-vertical"></i>
        </span>
        <button
          className="btn btn-primary btn-sm btn-sm"
          onClick={() => {
            setOpenAppointmentDialog(true);
          }}
        >
          {intl.formatMessage({ id: "BTN.ADD" })}
        </button>
      </div>
      <TableCustom<IAppointment>
        columns={AppointmentColumn}
        handleOpenInfoDialog={handleOpenAppointmentDialog}
        data={listAppointment}
        compact={false}
      />
      {openAppointmentDialog && (
        <AppointmentDialog handleCLose={handleCLoseAppointmentDialog} appointmentInfo={appointmentInfo} />
      )}
    </>
  );
};
export { AppointmentList };
