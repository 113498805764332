import { Formik } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { GroupButton } from '../../component/GroupButton';
import {
    IssueEmployees,
    IssueInfo,
    initEmployee,
    initIssue
} from '../models/IIssue';
import { InputCustom } from '../../component/input-field/InputCustom';
import { TableCustom } from '../../component/table-custom/TableCustom';
import { issuePlaceOpt, issueReasonOpt, issueStatusOpt, issueTypeOpt } from '../const/IssueConst';
import { IssueEmployeesColumn } from './IssueEmployeesColumn';
import { EmployeeDialog } from './dialog/EmployeeDialog';
import { inputTypeList } from '../../constant';
interface IProps {
    view: boolean;
    handleOpenUpdateDialog: () => void;
    handleCloseDialog: () => void;
    handleCloseUpdateDialog: () => void;
    issueInfo: IssueInfo;
}
const AddNewIssue: FC<IProps> = (props) => {
    const {
        handleCloseDialog,
        view,
        handleOpenUpdateDialog,
        issueInfo,
        handleCloseUpdateDialog,
    } = props;

    const intl = useIntl();

    const [listEmployees, setListEmployees] = useState<IssueEmployees[]>([
        {
            id: '1',
            hoVaTen: 'thanh vũ',
            maNhanVien: '0123456',
            donViCongTac: 'đơn vị 1',
            viTriCongViec: 'công việc 1',
            hinhThucXuLy: { value: "1", title: "Cảnh cáo" },
            giaTriPhaiBoiThuong: '1000000',
            giaTriDuocBoiThuong: 'có cái nịt',
            tepDinhKem: '',
            trangThai: { value: "1", title: "Chưa thực hiện" },
        },
        {
            id: '2',
            hoVaTen: 'cao hoàn',
            maNhanVien: '789',
            donViCongTac: 'đơn vị 12',
            viTriCongViec: 'công việc 2',
            hinhThucXuLy: { value: "2", title: "Đình chỉ công tác" },
            giaTriPhaiBoiThuong: '2000000',
            giaTriDuocBoiThuong: '1 đấm',
            tepDinhKem: '',
            trangThai: { value: "2", title: "Đang thực hiện", },
        },
        {
            id: '3',
            hoVaTen: 'minh nghĩa',
            maNhanVien: '101112',
            donViCongTac: 'đơn vị 3',
            viTriCongViec: 'công việc 3',
            hinhThucXuLy: { value: "5", title: "Bồi thường bằng tiền mặt" },
            giaTriPhaiBoiThuong: '3000000',
            giaTriDuocBoiThuong: '1 bả',
            tepDinhKem: '',
            trangThai: { value: "3", title: "Đã hoàn thành", }
        }
    ])

    const [shouldOpenAddContract, setShouldOpenAddContract] = useState<boolean>(true);
    const [shouldOpenEmployeeDialog, setShouldOpenEmployeeDialog] = useState(false)
    const [employee, setEmployee] = useState<IssueEmployees>()
    const handleOpenEmployeeDialog = (): void => {
        setShouldOpenEmployeeDialog(true);
    };

    const handleEditEmployee = (item: any) => {
        setEmployee(item.original);
        handleOpenEmployeeDialog();
    };
    const handleDeleteEmployee = (id: string) => {
        //    xử lý xóa employee
    };

    const handleCloseEmployeeDialog = (): void => {
        setShouldOpenEmployeeDialog(false);
        setEmployee(initEmployee)
    };

    const issueValidationSchema = Yup.object().shape({
        tenSuCo: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        loaiSuCo: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        trangThai: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        donViLienQuan: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
    });

    return (
        <>
            {shouldOpenAddContract && (
                <Formik
                    initialValues={!!issueInfo ? issueInfo : initIssue}
                    validationSchema={issueValidationSchema}
                    onSubmit={async (values) => {
                        handleCloseDialog();
                    }}
                >
                    {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <Col>
                                <Row className='contract-header'>
                                    <Col xs={12}>
                                        <div className='profile-title'>
                                            {view && (
                                                <>
                                                    <GroupButton
                                                        type='btn-back'
                                                        handleClose={
                                                            handleCloseDialog
                                                        }
                                                    />
                                                    <GroupButton
                                                        type='btn-edit'
                                                        handleSaveEdit={
                                                            handleOpenUpdateDialog
                                                        }
                                                    />
                                                </>
                                            )}
                                            {!issueInfo.id && !view && (
                                                <>
                                                    <span className='fs-3 fw-bold'>
                                                        {intl.formatMessage({
                                                            id: 'CONTRACT.ADD'
                                                        })}
                                                        <i className='bi bi-three-dots-vertical'></i>
                                                    </span>
                                                    <GroupButton
                                                        type='btn-save'
                                                        handleClose={
                                                            handleCloseDialog
                                                        }
                                                    />
                                                </>
                                            )}
                                            {issueInfo.id && !view && (
                                                <>
                                                    <GroupButton
                                                        type='btn-back'
                                                        handleClose={
                                                            handleCloseUpdateDialog
                                                        }
                                                    />
                                                    <GroupButton
                                                        type='btn-save'
                                                        handleClose={
                                                            handleCloseUpdateDialog
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-4 bg-light contract-info'>
                                    <Col xs={12}>
                                        <span className='fs-3 fw-bold'>
                                            {intl.formatMessage({
                                                id: 'INFO.MAIN'
                                            })}
                                        </span>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <InputCustom
                                                    required={true}
                                                    name='tenSuCo'
                                                    title='ISSUE.NAME'
                                                    type={inputTypeList.text}
                                                    readOnly={view}
                                                    values={
                                                        !!values.tenSuCo ? values.tenSuCo : ''
                                                    }
                                                    errors={
                                                        errors.tenSuCo
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <InputCustom
                                                    required={true}
                                                    name='loaiSuCo'
                                                    title='ISSUE.TYPE'
                                                    type={inputTypeList.select}
                                                    readOnly={view}
                                                    values={!!values.loaiSuCo ? values.loaiSuCo : ''}
                                                    errors={errors.loaiSuCo}
                                                    options={issueTypeOpt}
                                                    optionLabel="title"
                                                    handleChange={(selectedOptions:any) => {
                                                      setFieldValue(
                                                        'loaiSuCo',
                                                      selectedOptions
                                                      )
                                                    }}
                                                    optionValue='value'
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <InputCustom
                                                    name='ngayXayRa'
                                                    title='ISSUE.DATE'
                                                    type={inputTypeList.date}
                                                    readOnly={view}
                                                    values={
                                                        !!values.ngayXayRa
                                                            ? values.ngayXayRa
                                                            : ''
                                                    }
                                                    errors={
                                                        errors.ngayXayRa
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <InputCustom
                                                    name='noiXayRa'
                                                    title='ISSUE.PLACE'
                                                    type={inputTypeList.select}
                                                    readOnly={view}
                                                    values={!!values.noiXayRa ? values.noiXayRa : ''}
                                                    errors={errors.noiXayRa}
                                                    options={
                                                        issuePlaceOpt
                                                    }
                                                    optionLabel="title"
                                                    handleChange={(selectedOptions:any) => {
                                                      setFieldValue(
                                                        'noiXayRa',
                                                      selectedOptions
                                                      )
                                                    }}
                                                    optionValue='value'
                                                />
                                            </Col>
                                        </Row>

                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <InputCustom
                                                    name='nguyenNhan'
                                                    title='ISSUE.REASON'
                                                    type={inputTypeList.select}
                                                    readOnly={view}
                                                    values={
                                                        !!values.nguyenNhan
                                                            ? values.nguyenNhan
                                                            : ''
                                                    }
                                                    errors={
                                                        errors.nguyenNhan
                                                    }
                                                    options={issueReasonOpt}
                                                    optionLabel="title"
                                                    handleChange={(selectedOptions:any) => {
                                                      setFieldValue(
                                                        'nguyenNhan',
                                                      selectedOptions
                                                      )
                                                    }}
                                                    optionValue='value'
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <InputCustom
                                                    name='moTa'
                                                    title='ISSUE.DESCRIPTION'
                                                    type={inputTypeList.text}
                                                    readOnly={view}
                                                    values={
                                                        !!values.moTa
                                                            ? values.moTa
                                                            : ''
                                                    }
                                                    errors={
                                                        errors.moTa
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>

                                            <Col xs={6}>
                                                <InputCustom
                                                    name='giaTriThietHai'
                                                    title='ISSUE.DAMAGE_VALUE'
                                                    type={inputTypeList.text}
                                                    readOnly={view}
                                                    values={
                                                        !!values.giaTriThietHai
                                                            ? values.giaTriThietHai
                                                            : ''
                                                    }
                                                    errors={
                                                        errors.giaTriThietHai
                                                    }
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <InputCustom
                                                    required={true}
                                                    name='trangThai'
                                                    title='ISSUE.STATUS'
                                                    type={inputTypeList.select}
                                                    readOnly={view}
                                                    values={
                                                        !!values.trangThai ? values.trangThai : ''
                                                    }
                                                    errors={
                                                        errors.trangThai
                                                    }
                                                    optionLabel="title"
                                                    handleChange={(selectedOptions:any) => {
                                                      setFieldValue(
                                                        'trangThai',
                                                      selectedOptions
                                                      )
                                                    }}
                                                    optionValue='value'
                                                    options={issueStatusOpt}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='ps-8 pt-8'>
                                            <Col xs={6}>
                                                <InputCustom
                                                    required={true}
                                                    name='donViLienQuan'
                                                    title='ISSUE.RELATE_UNIT'
                                                    type={inputTypeList.text}
                                                    readOnly={view}
                                                    values={!!values.donViLienQuan ? values.donViLienQuan : ''}
                                                    errors={errors.donViLienQuan}
                                                    handleChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mt-4 bg-light contract-info'>
                                    <Col xs={12}>
                                        <span className='text-header'>
                                            {intl.formatMessage({ id: 'ISSUE.LIST_EMPLOYEES' })}
                                        </span>
                                        <div>
                                            {listEmployees.length !== 0 && <TableCustom<IssueEmployees>
                                                data={listEmployees}
                                                columns={IssueEmployeesColumn}
                                                compact={false}
                                                handleOpenInfoDialog={handleEditEmployee}
                                            />}
                                            <span className='text-add-field' onClick={handleOpenEmployeeDialog}>
                                                <div className="bi bi-plus-lg"></div>
                                                {intl.formatMessage({ id: 'INFO.EMPLOYEE.ADD' })}
                                            </span>
                                        </div>
                                        {shouldOpenEmployeeDialog &&
                                            <EmployeeDialog
                                                employee={employee}
                                                shouldOpenEmployeeDialog={shouldOpenEmployeeDialog}
                                                handleOpenEmployeeDialog={handleOpenEmployeeDialog}
                                                handleCloseEmployeeDialog={handleCloseEmployeeDialog}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
export { AddNewIssue };