import { FC } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { IDismissed } from "../model/IDismissed";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "../../component/input-field/TextField";
interface Iprops {
  handleCLose: () => void;
  dismissedInfo: IDismissed;
}
const DismissedDialog: FC<Iprops> = (props) => {
  const { handleCLose, dismissedInfo } = props;
  const intl = useIntl();
  const validationSchema = Yup.object().shape({
    hovaten: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
  });
  return (
    <>
      <Formik
        initialValues={dismissedInfo}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => {
          console.log(values);

          return (
            <Modal show={true} onHide={handleCLose} size="xl" centered>
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {intl.formatMessage({
                      id: !dismissedInfo.hovaten ? "DISMISSED.ADD" : "DISMISSED.EDIT",
                    })}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className=" pt-8">
                    <Col xs={4}>
                      <TextField
                        name="hovaten"
                        label="APPOINTMENT.NAME"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.hovaten || ""}
                        isView={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="manhanvien"
                        label="APPOINTMENT.CODE"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.manhanvien || ""}
                        isView={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="vitricongviec"
                        label="APPOINTMENT.JOBPOSITIION"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.vitricongviec || ""}
                        isView={true}
                      />
                    </Col>
                  </Row>
                  <Row className=" pt-8">
                    <Col xs={4}>
                      <TextField
                        name="DonViCongTac"
                        label="APPOINTMENT.WORKUNINT"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.DonViCongTac || ""}
                        isView={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="DonViCongTac"
                        label="DISMISSED.NEWJOBPOSITION"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.vitricongviecmoi || ""}
                        isView={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="DonViCongTac"
                        label="DISMISSED.WORKUNINT"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.DonViCongTacmoi || ""}
                        isView={true}
                      />
                    </Col>
                  </Row>
                  <Row className=" pt-8">
                    <Col xs={4}>
                      <TextField
                        name="DonViCongTac"
                        label="APPOINTMENT.NUMBER"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.DonViCongTacmoi || ""}
                        isView={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="DonViCongTac"
                        label="APPOINTMENT.STATUS"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.DonViCongTacmoi || ""}
                        isView={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="DonViCongTac"
                        label="CONTRACT.ATTACHMENTS"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.DonViCongTacmoi || ""}
                        isView={true}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                  <Button className="btn btn-secondary  btn-sm" onClick={handleCLose}>
                    {intl.formatMessage({
                      id: "BTN.CANCEL",
                    })}
                  </Button>
                  <Button className="btn btn-primary btn-sm" type="submit">
                    {intl.formatMessage({ id: "BTN.SAVE" })}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
export { DismissedDialog };
