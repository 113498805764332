import { createSlice } from "@reduxjs/toolkit";

export type IInitialState = {
  detailDonHang: any;
};

const initialState: IInitialState = {
  detailDonHang: {},
};

const DonHangSlice = createSlice({
  name: "donHang",
  initialState,
  reducers: {
    detailDonHang: (state, action) => {
      state.detailDonHang = action.payload;
    },
  },
});

export default DonHangSlice.reducer;
export const { detailDonHang } = DonHangSlice.actions;
