export const navigation : any[] = [
    {
        to: '/dashboard',
        title: 'Bảng điều khiển',
        fontIcon: 'bi-archive',
        icon: '/media/icons/duotune/general/gen008.svg',
    },
    {
        to: '/pos',
        title: 'POS Tiệm giặt',
        fontIcon: 'bi-archive',
        icon: '/media/icons/washing.svg',
        children: [
            {
                to: '/pos/tiem-giat',
                title: 'POS',
                fontIcon: 'bi-archive',
            },
            {
                to: '/pos/tiem-giat/don-hang',
                title: 'Đơn hàng',
                fontIcon: 'bi-archive',
            },
        ]
    },
    {
        to: '/pos',
        title: 'POS Đại lý',
        fontIcon: 'bi-archive',
        icon: '/media/icons/shopify.svg',
        children: [
            {
                to: '/pos/dai-ly',
                title: 'POS',
                fontIcon: 'bi-archive',
            },
            {
                to: '/pos/dai-ly/don-hang',
                title: 'Đơn hàng',
                fontIcon: 'bi-archive',
            },
        ]
    },
    {
        to: '/cua-hang/dai-ly',
        title: 'Danh mục cửa hàng, đại lý',
        fontIcon: 'bi-archive',
        icon: '/media/icons/shop-solid.svg',
    },
    {
        to: '/product',
        title: 'Danh mục sản phẩm',
        fontIcon: 'bi-archive',
        icon: '/media/icons/duotune/general/gen022.svg',
        children: [
            {
                to: '/product/hang-hoa',
                title: 'Hàng hóa',
                fontIcon: 'bi-archive',
                icon: '/media/icons/duotune/general/gen022.svg',
            },
            {
                to: '/product/dich-vu',
                title: 'Dịch vụ giặt ủi, sấy...',
                fontIcon: 'bi-archive',
                icon: '/media/icons/duotune/general/gen022.svg',
            }
        ]
    },
    {
        to: '/don-hang',
        title: 'Đơn hàng',
        fontIcon: 'bi-archive',
        icon: '/media/icons/cart.svg',
        children: [
            {
                to: '/don-hang/cho-xac-nhan',
                title: 'Chờ xác nhận',
                fontIcon: 'bi-archive',
            },
            {
                to: '/don-hang/da-xac-nhan',
                title: 'Mới tạo/Tiệm giặt đã xác nhận',
                fontIcon: 'bi-archive',
            },
            {
                to: '/don-hang/dang-xu-ly',
                title: 'Đang xử lý',
                fontIcon: 'bi-archive',
            },
            {
                to: '/don-hang/da-huy',
                title: 'Đã hủy',
                fontIcon: 'bi-archive',
            },
            {
                to: '/don-hang/hoan-thanh',
                title: 'Hoàn thành',
                fontIcon: 'bi-archive',
            },
            {
                to: '/don-hang/loi',
                title: 'Lỗi',
                fontIcon: 'bi-archive',
            }
        ]
    },
]