import { Image } from "react-bootstrap"
import { DEFAULT_IMG_URL } from "../../danh-sach-san-pham/constants"

type Props = {
    url: string | null
}

const Logo = (props: Props) => {
    return (
        <Image
            src={props?.url || DEFAULT_IMG_URL}
            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
            roundedCircle
            alt="logo"
        />
    )
}

export default Logo