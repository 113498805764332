import axios from "axios";
import { SearchParams } from "../models/IParams";
import { SanPham } from "../models/ISanPham";

const API_PATH = process.env.REACT_APP_API_URL;

export const getDanhSachSanPham = (params: SearchParams) => {
    return axios.get(API_PATH + "/product/search", { params });
}

export const getDanhSachDonVi = (params: SearchParams) => {
    return axios.get(API_PATH + "/organizations/page", { params });
}

export const getThongTinSanPhamById = (id: string) => {
    return axios.get(API_PATH + "/product/" + id);
}

export const uploadImageSanPham = (formData: any) => {
    return axios.post(API_PATH + "/file/upload/PRD/new", formData);
}

export const createSanPham = (data: SanPham) => {
    return axios.post(API_PATH + "/product", data);
}

export const updateSanPham = (data: SanPham) => {
    return axios.put(API_PATH + "/product/" + data?.id, data);
}

// export const deleteSanPham = (id: string) => {
//     return axios.delete(API_PATH + "/product/" + id);
// }

export const getDetailProduct = (id: string) => {
    return axios.get(API_PATH + "/product/detail/" + id);
}


export const getListHuongXaVai = (params: any) => {
    let config = {
        params
    }
    return axios.get(API_PATH + "/product/fabric-softener-fragrance", config);
}