import { FC } from "react";
import { Modal ,Button, Row, Col} from "react-bootstrap";
import { useIntl } from "react-intl";
import { IAppointment } from "../models/IAppointment";
import { Formik,Form } from "formik";
import * as Yup from 'yup'
import SelectField from "../../component/input-field/SelectField";
import TextField from "../../component/input-field/TextField";
interface Iprops {
  handleCLose: () => void;
  appointmentInfo:IAppointment
}
const AppointmentDialog:FC<Iprops>=(props)=>{
    const { handleCLose, appointmentInfo } = props;
    const intl= useIntl()
     const validationSchema = Yup.object().shape({
       hovaten: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
     });

    return (
      <>
        <Formik
          initialValues={appointmentInfo}
          validationSchema={validationSchema}
          onSubmit={(values) => { console.log(values)}}
        >
           {({ values, errors, handleChange, handleSubmit }) => {
            console.log(values);
            
            return (
              <Modal show={true} onHide={handleCLose} size="xl" centered>
                <Form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {intl.formatMessage({
                        id: !appointmentInfo.MaNhanVien ? "APPOINTMENT.ADD" : "APPOINTMENT.EDIT",
                      })}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className=" pt-8">
                      <Col xs={4}>
                        <TextField
                          isView={false}
                          name="ten"
                          label="APPOINTMENT.NAME"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.ten || ""}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextField
                          name="MaNhanVien"
                          isView={false}
                          label="APPOINTMENT.CODE"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.MaNhanVien || ""}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextField
                          name="ViTriCongViec"
                          label="APPOINTMENT.JOBPOSITIION"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.ViTriCongViec || ""}
                          isView={false}
                        />
                      </Col>
                    </Row>
                    <Row className=" pt-8">
                      <Col xs={4}>
                        <TextField
                          name="DonViCongTac"
                          label="APPOINTMENT.WORKUNINT"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.DonViCongTac || ""}
                          isView={false}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectField
                          name="DonViBoNhiem"
                          label="APPOINTMENT.UNIT"
                          handleChange={handleChange}
                          value={values.DonViBoNhiem || ""}
                          Option={[]}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectField
                          name="ViTriBoNhiem"
                          label="APPOINTMENT.POSITION"
                          handleChange={handleChange}
                          value={values.ViTriBoNhiem || ""}
                          Option={[]}
                        />
                      </Col>
                    </Row>
                    <Row className=" pt-8">
                      <Col xs={4}>
                        <TextField
                          name="soquyetdinh"
                          label="APPOINTMENT.NUMBER"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.SoQuyetDinh || ""}
                         isView={false}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectField
                          name="TrangThai"
                          label="APPOINTMENT.STATUS"
                          handleChange={handleChange}
                          value={values.TrangThai || ""}
                          Option={[]}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextField
                          name="file"
                          label="CONTRACT.ATTACHMENTS"
                          type={"text"}
                          handleChange={handleChange}
                          value={""}
                          isView={false}
                        />
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-secondary  btn-sm" onClick={handleCLose}>
                      {intl.formatMessage({
                        id: "BTN.CANCEL",
                      })}
                    </Button>
                    <Button className="btn btn-primary btn-sm" type="submit">
                      {intl.formatMessage({ id: "BTN.SAVE" })}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            );
           }}
      
        </Formik>
      </>
    );
}
export {AppointmentDialog}