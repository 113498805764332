import { FC, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SelectField from "../../component/input-field/SelectField";
import TextField from "../../component/input-field/TextField";
import { IListJoin, IWelfare } from "./../model/WelfareModel";
import "../welfare.scss";
import { TableCustom } from "../../component/table-custom/TableCustom";
import { ListJoinColumn } from "./ListJoinColumn";
import { thamGiaArray } from "../const/WelfareConst";
import ListJoinDialog from "./ListJoinDialog";
interface IProps {
  handleCLose: () => void;
  welfareInfo: IWelfare;
}
const WelfareDialog: FC<IProps> = (props) => {
  const { handleCLose, welfareInfo } = props;
  const intl = useIntl();
  const [openListJoinDialog, setOpenListJoinDialog] = useState<boolean>(false);
  const [joinInfo, setJoinInfo] = useState<IListJoin>({} as IListJoin);
  const handleOpenListJoinDialog = (info: any) => {
    setJoinInfo(info.original);
    setOpenListJoinDialog(true);
  };
  const handleCloseListJoinDialog = () => {
    setOpenListJoinDialog(false);
    setJoinInfo({} as IListJoin);
  };
  const validationSchema = Yup.object().shape({
    tenphucloi: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
  });
  return (
    <>
      {!openListJoinDialog && (
        <Formik
          initialValues={welfareInfo}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit }) => {
            console.log(values);

            return (
              <Modal show={true} onHide={handleCLose} size="xl" centered>
                <Form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {intl.formatMessage({
                        id: !welfareInfo?.tenPhucLoi ? "WELFARE.ADD" : "WELFARE.EDIT",
                      })}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="pt-4">
                      <Col xs={4}>
                        <TextField
                          isView={false}
                          name="tenPhucLoi"
                          label="WELFARE.NAME"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.tenPhucLoi || ""}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectField
                          name="nhomPhucLoi"
                          label="WELFARE.GROUP"
                          handleChange={handleChange}
                          value={values.nhomPhucLoi || ""}
                          Option={[]}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectField
                          name="donViApDung"
                          label="APPLIED.UNIT"
                          handleChange={handleChange}
                          value={values.donViApDung || ""}
                          Option={[]}
                        />
                      </Col>
                    </Row>
                    <Row className=" pt-8">
                      <Col xs={4}>
                        <TextField
                          isView={false}
                          name="ngayBatDau"
                          label="START.DATE"
                          type={"date"}
                          handleChange={handleChange}
                          value={values.ngayBatDau || ""}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextField
                          name="ngayKetThuc"
                          label="END.DATE"
                          type={"date"}
                          handleChange={handleChange}
                          value={values.ngayKetThuc || ""}
                          isView={false}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextField
                          name="diaDiemToChuc"
                          label="LOCATION"
                          type="text"
                          handleChange={handleChange}
                          value={values.diaDiemToChuc || ""}
                          isView={false}
                        />
                      </Col>
                    </Row>
                    <Row className=" pt-8">
                      <Col xs={4}>
                        <TextField
                          isView={false}
                          name="mucDichToChuc"
                          label="OBJECTIVE"
                          type={"text"}
                          handleChange={handleChange}
                          value={values.mucDichToChuc || ""}
                        />
                      </Col>
                      <Col xs={4}>
                        <SelectField
                          name="hinhThucThucHien"
                          label="IMPLEMENTATION.FORM"
                          Option={[]}
                          handleChange={handleChange}
                          value={values.hinhThucThucHien || ""}
                        />
                      </Col>
                      <Col xs={4}>
                        <TextField
                          name="soNguoiDuKien"
                          label="EXPECTED.ATTENDEES"
                          handleChange={handleChange}
                          value={values.soNguoiDuKien || ""}
                          type={"text"}
                          isView={false}
                        />
                      </Col>
                    </Row>
                    <Row className=" pt-8">
                      <Col xs={4}>
                        <SelectField
                          name="trangThai"
                          label="STATUS"
                          Option={[]}
                          handleChange={handleChange}
                          value={values.trangThai || ""}
                        />
                      </Col>
                    </Row>
                    <Row className="pt-8">
                      <Col xs={12} className="cursor-pointer py-4">
                        <span className="fs-3 fw-bold">
                          {intl.formatMessage({ id: "LIST.JOIN" })}
                        </span>
                        <i
                          className="bi bi-plus-circle-fill fs-4 px-2  text-primary"
                          onClick={() => {
                            setOpenListJoinDialog(true);
                          }}
                        ></i>
                      </Col>
                      <Col xs={12}>
                        <TableCustom<IListJoin>
                          columns={ListJoinColumn}
                          handleOpenInfoDialog={handleOpenListJoinDialog}
                          data={thamGiaArray}
                          compact={false}
                        />
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-secondary  btn-sm" onClick={handleCLose}>
                      {intl.formatMessage({
                        id: "BTN.CANCEL",
                      })}
                    </Button>
                    <Button className="btn btn-primary btn-sm" type="submit">
                      {intl.formatMessage({ id: "BTN.SAVE" })}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            );
          }}
        </Formik>
      )}
      {openListJoinDialog && (
        <ListJoinDialog handleCLose={handleCloseListJoinDialog} joinInfo={joinInfo} />
      )}
    </>
  );
};
export default WelfareDialog;
