import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { ResignInfo } from "./models/IResign";
import { TableCustom } from "../component/table-custom/TableCustom";
import { ResignColumn } from "./components/ResignColumn";
import { AddNewResign } from "./components/AddNewResign";
import { DEFAULT_PAGE_INDEX } from "../category-personnel/const/PersonnelConst";

const Resign: FC = () => {
  const intl = useIntl();
  const [shouldOpenAddNewResign, setShouldOpenAddNewResign] =
    useState<boolean>(false);
  const [viewInfoResign, setViewInfoResign] = useState<boolean>(false);
  const [resignInfo, setResignInfo] = useState<ResignInfo>({} as ResignInfo);
  const [resigns, setResigns] = useState<ResignInfo[]>([
    // tạm thời set cứng vì chưa có api gọi dữ liệu
    {
      id: "2",
      employeeId: "uuid",
      viTriCongViecId: "1",
      viTriCongViecText: "công việc 1",
      donViCongTac: "ĐỊnh Hải",
      donViCongTacMoi: "ĐỊnh tân",
      viTriCongViecMoiText: "công việc mới 2",
      tenNguoiLaoDong: "trịnh cao hoàn",
      employeeCode: "33634",
      soQuyetDinh: "số quyết định 1",
      trangThai: "chap nhan",
    },
    {
      id: "3",
      employeeId: "uuid",
      viTriCongViecId: "1",
      donViCongTac: "ĐỊnh Tiến",
      donViCongTacMoi: "ĐỊnh Long",
      viTriCongViecText: "công việc 1",
      viTriCongViecMoiText: "công việc mới 3",
      tenNguoiLaoDong: "nguyễn thanh vũ",
      employeeCode: "33633",
      soQuyetDinh: "số quyết định 2",
      trangThai: "từ chối",
    },
    {
      id: "4",
      employeeId: "uuid",
      viTriCongViecId: "1",
      donViCongTac: "ĐỊnh Liên",
      donViCongTacMoi: "ĐỊnh Hưng",
      viTriCongViecText: "công việc 1",
      viTriCongViecMoiText: "công việc mới 1",
      tenNguoiLaoDong: "trịnh minh nghĩa",
      employeeCode: "33635",
      soQuyetDinh: "số quyết định 3",
      trangThai: "chờ duyệt",
    },
  ]);
  const [totalPage, setTotalPage] = useState<number>(0);

  const loadResign = useCallback(async () => {
    // Xử lý gọi danh sách
  }, [DEFAULT_PAGE_INDEX]);

  const handleCloseDialog = () => {
    setShouldOpenAddNewResign(false);
    setViewInfoResign(false);
    setResignInfo({} as ResignInfo);
  };
  const handleOpenInfoDialog = (row: any) => {
    setViewInfoResign(true);
    setShouldOpenAddNewResign(true);
    setResignInfo(row.original);
  };
  const handleOpenAddNewContract = () => {
    setShouldOpenAddNewResign(true);
  };
  const handleOpenUpdateDialog = () => {
    setViewInfoResign(false);
  };
  const handleCloseUpdateDialog = () => {
    setViewInfoResign(true);
  };

  const handleDeleteResign = async (listDeleteID: string[]) => {
    // Xử lý xóa item nghỉ việc
  };

  return (
    <>
      {!shouldOpenAddNewResign && (
        <>
          <div className="profile-title">
            <span className="fs-3 fw-bold">
              {intl.formatMessage({ id: "RESIGN.ALL" })}
              <i className="bi bi-three-dots-vertical"></i>
            </span>
            <button
              onClick={handleOpenAddNewContract}
              className="btn btn-primary btn-sm btn-sm"
            >
              {intl.formatMessage({ id: "BTN.ADD" })}
            </button>
          </div>
          {resigns.length > 0 && (
            <TableCustom<ResignInfo>
              data={resigns}
              columns={ResignColumn}
              compact={false}
              handleOpenInfoDialog={handleOpenInfoDialog}
              handleDelete={handleDeleteResign}
            />
          )}
        </>
      )}
      {shouldOpenAddNewResign && (
        <AddNewResign
          view={viewInfoResign}
          employeeId="1"
          resignInfo={resignInfo}
          mutateResign={loadResign}
          handleCloseDialog={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
        />
      )}
    </>
  );
};
export { Resign };
