import moment from "moment";
import { useIntl } from "react-intl";
import { localStorageServices } from "./LocalStorage";
import { LOCAL_STORAGE_KEYS, ROLES, initialValue } from "../../../constants";
import { IProductItem } from "../pos/models/PosModels";

export const checkTypeOf = (value: any) => {
  return Object.prototype.toString.call(value).slice(8, -1);
};
export const covertDateToString = (value: any) => {
  return value ? moment(value).format('YYYY-MM-DD') : '';
};
export function transformArray(arr: any[]) {
  return arr.map(item => {
    return {
      value: item.id,
      label: item.value
    };
  });
}
export function transformArrayByName(name: string, arr: any[]) {
  return arr?.map(item => {
    return {
      value: item.id,
      label: item[name]
    };
  });
}
export function transformArrayByNameForLocation(name: string, arr: any[]) {
  return arr?.map(item => {
    return {
      ...item,
      value: item.id,
      label: item[name]
    };
  });
}
export function useCustomIntl(messageId: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId });
}

export function formatPrice(amount: number | undefined): string {
  const formatter = new Intl.NumberFormat('vi-VN', {
    // style: 'currency',
    // currency: 'VND',
    minimumFractionDigits: 0,
  });

  const result = formatter.format(amount as number).replace('.', ',');

  return result;
}

export function formatExportDate(date: Date | string) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateHoaDon(date: Date | string) {
  return moment(date).format('HH:mm:ss DD/MM/YYYY');
}

export const totalPriceService = (listService: IProductItem[]) => {
  const totalPriceThuocTinh = listService?.reduce(
    (total, item) => {
      const totalPrice = item?.attributeTypes?.reduce(
        (total, item) => {
          const prices = item?.attributes?.map((attr) => attr?.totalPrice ? +attr?.totalPrice : +attr?.price);
          return (
            total +
            prices.reduce((total, price) => {
              return total + price;
            }, initialValue)
          );
        },
        initialValue
      );
      return total + totalPrice;
    },
    initialValue
  );
  return (totalPriceThuocTinh);
}