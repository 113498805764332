export const listModule = [
  { id: 0, name: "Đại lý" },
  { id: 1, name: "Tiệm giặt" },
];

export const DEFAULT_CUSTOMER = { id: 0, name: "Khách lẻ" };

export const ROLES = {
  ADMIN: "ROLE_ADMIN",
  DAI_LY: "ROLE_AGENCY",
  TIEM_GIAT: "ROLE_LAUNDRY",
};

export const TYPE_POS = {
  DAI_LY: { id: 0, name: "dai-ly" },
  TIEM_GIAT: { id: 1, name: "tiem-giat" },
};
