export const localStorageServices = (() => {
  const getLocalStorageItem = (key: string | undefined) => {
    const item = key ? localStorage.getItem(key) : null;
    return item ? JSON.parse(item) : null;
  };

  const setLocalStorageItem = (key: string | undefined, value: any): void => {
    if (key) {
      let jsonData = JSON.stringify(value);
      localStorage.setItem(key, jsonData);
    }
  };

  const removeLocalStorageItem = (key: string | undefined): void => {
    if (key) {
      localStorage.removeItem(key);
    }
  };

  const setSessionItem = (key: string, value: any) => {
    const data = JSON.stringify(value);
    sessionStorage.setItem(key, data);
  };

  const getSessionItem = (key: string) => {
    const result = sessionStorage.getItem(key);
    return JSON.parse(result as string);
  };

  const removeSessionItem = (key: string) => {
    sessionStorage.removeItem(key);
  };
  return {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
    setSessionItem,
    getSessionItem,
    removeSessionItem,
  };
})();
