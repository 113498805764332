import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Column, Row, useRowSelect, useTable } from "react-table";
import { KTCardBody } from "../../../../_metronic/helpers";
import { TableCustomHeaderColumn } from "./columns/TableCustomHeaderColumn";
import { TableCustomToolbar } from "./columns/TableToolbar";
import "../../styles/index.scss";
interface tableProps<T extends object> {
  data: T[];
  columns: ReadonlyArray<Column<T>>;
  compact?: boolean;
  handleSearchByPage?: (...params: any) => Promise<void>;
  handleChangeValueInput?: (event: React.ChangeEvent<HTMLInputElement>) => {};
  handleOpenProfileDialog?: (row: any) => void;
  handleOpenInfoDialog?: (row: any) => void;
  handleDelete?: (...params: any) => Promise<void>;
  shouldUnSelectedRows?: () => boolean;
  minHeight?: number;
  maxHeight?: number;
}

function TableCustom<T extends object>(props: tableProps<T>) {
  const {
    data,
    columns,
    handleSearchByPage,
    handleChangeValueInput,
    compact,
    handleOpenInfoDialog,
    handleOpenProfileDialog,
    handleDelete,
    shouldUnSelectedRows,
    minHeight,
    maxHeight,
  } = props;
  const [selectedRows, setSelectedRows] = useState<Row<T>[]>([]);
  const [shouldOpenFilterSearch, setShouldOpenFilterSearch] =
    useState<boolean>(false);
  const intl = useIntl();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    rows,
  } = useTable(
    {
      columns,
      data: data,
    },
    useRowSelect,
    (hooks) => {
      if (compact)
        hooks.allColumns.push((columns: any) => [
          {
            id: "selection",

            Header: ({ getToggleAllRowsSelectedProps }: any) => {
              let allProps = getToggleAllRowsSelectedProps();
              allProps.indeterminate = "false";
              return (
                <input
                  type="checkbox"
                  className="check-box-row"
                  {...allProps}
                  onClick={(e) => {
                    toggleAllRowsSelected();
                  }}
                />
              );
            },
            Cell: ({ row }: any) => {
              let rowProps = row.getToggleRowSelectedProps();
              rowProps.indeterminate = "false";
              return (
                <input
                  type="checkbox"
                  className="check-box-row"
                  {...rowProps}
                  onClick={() => {
                    toggleRowSelection(row);
                  }}
                />
              );
            },
          },
          ...columns,
        ]);
    }
  );

  const toggleRowSelection = (row: any) => {
    row.toggleRowSelected();
    const isSelected = row.isSelected;
    if (!isSelected) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow: Row<T>) => selectedRow.id !== row.id)
      );
    }
  };
  const toggleAllRowsSelected = () => {
    const allRowsSelected = rows.every((row: any) => row.isSelected);
    setSelectedRows(allRowsSelected ? [] : rows);
  };

  const handleUnSelectedRows = useCallback(() => {
    rows.map((row: any) => {
      if (row.isSelected) row.toggleRowSelected();
      return row;
    });
    setSelectedRows([]);
  }, [rows]);

  useEffect(() => {
    if (shouldUnSelectedRows) {
      handleUnSelectedRows();
    }
  }, [handleUnSelectedRows, shouldUnSelectedRows]);
  return (
    <KTCardBody className="pt-3">
      {compact && (
        <TableCustomToolbar
          handleUnSelectedRows={handleUnSelectedRows}
          selectedRows={selectedRows}
          allColumns={allColumns}
          setShouldOpenFilterSearch={setShouldOpenFilterSearch}
          shouldOpenFilterSearch={shouldOpenFilterSearch}
          handleDelete={handleDelete}
        />
      )}
      <div
        className="table-responsive"
        style={{ minHeight: minHeight, maxHeight: maxHeight }}
      >
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead className="header-sticky">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="text-muted fs-7 text-uppercase gs-0 bg-pri"
                style={{ height: "20px !important" }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCustomHeaderColumn<T>
                    handleChangeValueInput={handleChangeValueInput}
                    handleSearchByPage={handleSearchByPage}
                    key={column.id}
                    column={column}
                    shouldOpenFilterSearch={shouldOpenFilterSearch}
                  />
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="text-gray-600 bg-white" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: any, i) => {
                prepareRow(row);
                return (
                  <tr
                    className={clsx({
                      "selected-row": row.isSelected,
                    })}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          onClick={() => {
                            if (index && handleOpenInfoDialog)
                              handleOpenInfoDialog(row);
                            if (index && handleOpenProfileDialog)
                              handleOpenProfileDialog(row);
                          }}
                          {...cell.getCellProps()}
                          className="p-3"
                        >
                          {cell.render("Cell") as ReactNode}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    {intl.formatMessage({ id: "TABLE.DATA.EMPTY" })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  );
}

export { TableCustom };
