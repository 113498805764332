import axios, { AxiosResponse } from "axios";
import { ISearchDonHang } from "../models/OrderModels";
const API_URL = process.env.REACT_APP_API_URL;
const API_ODER = API_URL + "/order";

export const getOrderById = (id: string | number) => {
  return axios.get(`${API_URL}/order/${id}`);
};

export const getListDonHang = (searchObject: ISearchDonHang) => {
  let config = {
    params: {
      pageIndex: searchObject?.pageIndex,
      pageSize: searchObject?.pageSize,
      code: searchObject?.code,
      statusId: searchObject?.statusId,
      type: searchObject?.type,
      orgId: searchObject?.orgId
    },
  };
  let url = API_URL + "/order/search";
  // let url = API_PATH + "/employee/page";
  return axios.get(url, config);
};

export const getTotalElementsStatusByType = (objectSearch: any) => {
  const config = {
    params: {
      type: objectSearch.type,
      orgId: objectSearch.orgId,
    },
  };
  return axios.get(`${API_URL}/order/order-status`, config);
};

export const addDonHang = (searchObject: any) => {
  return axios.post(API_URL + "/order", searchObject);
};

export const updateDonHang = (id: string | number, searchObject: any) => {
  return axios.put(API_URL + "/order/" + id, searchObject);
};

export const deleteDonHang = (object: any) => {
  const config = {
    params: {
      id: object.id,
      isDeleted: object.isDeleted
    }
  }
  return axios.put(API_URL + `/order/delete-status`, "", config);
}
