import { Column } from 'react-table';
import { TableCustomHeader } from '../../component/table-custom/columns/TableCustomHeader';
import { TableCustomCell } from '../../component/table-custom/columns/TableCustomCell';
import { useIntl } from 'react-intl';
import { IssueInfo } from '../models/IIssue';

function useCustomIntl(messageId: string) {
    const intl = useIntl();
    return intl.formatMessage({ id: messageId });
}
const IssueColumn: ReadonlyArray<Column<IssueInfo>> = [
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.NAME')}
                className='text-center text-light min-w-175px '
            />
        ),
        id: 'ISSUE.NAME',
        Cell: ({ ...props }) => {
            let tenSuCo = props.data[props.row.index].tenSuCo;
            return (
                <TableCustomCell data={!!tenSuCo ? tenSuCo : '-'} />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.TYPE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.TYPE',
        Cell: ({ ...props }) => {
            let loaiSuCo = props.data[props.row.index].loaiSuCo;
            return (
                <TableCustomCell
                    data={!!loaiSuCo ? loaiSuCo.title : '-'}
                    action={true}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.DATE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.DATE',
        Cell: ({ ...props }) => {
            let ngayXayRa = props.data[props.row.index].ngayXayRa;
            return (
                <TableCustomCell
                    data={!!ngayXayRa ? ngayXayRa : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.DAMAGE_VALUE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.DAMAGE_VALUE',
        Cell: ({ ...props }) => {
            let giaTriThietHai =
                props.data[props.row.index].giaTriThietHai;
            return (
                <TableCustomCell
                    data={!!giaTriThietHai ? giaTriThietHai : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.RELATE_UNIT')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.RELATE_UNIT',
        Cell: ({ ...props }) => {
            let donViLienQuan =
                props.data[props.row.index].donViLienQuan;
            return (
                <TableCustomCell
                    data={!!donViLienQuan ? donViLienQuan : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.STATUS')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.STATUS',
        Cell: ({ ...props }) => {
            let trangThai = props.data[props.row.index].trangThai;
            return (
                <TableCustomCell
                    data={!!trangThai ? trangThai.title : '-'}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.PLACE')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.PLACE',
        Cell: ({ ...props }) => {
            let noiXayRa =
                props.data[props.row.index].noiXayRa;
            return (
                <TableCustomCell
                    data={!!noiXayRa ? noiXayRa.title : ''}
                />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.REASON')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.REASON',
        Cell: ({ ...props }) => {
            let nguyenNhan = props.data[props.row.index].nguyenNhan;
            return (
                <TableCustomCell data={!!nguyenNhan ? nguyenNhan.title : '-'} />
            );
        }
    },
    {
        Header: (props) => (
            <TableCustomHeader<IssueInfo>
                tableProps={props}
                title={useCustomIntl('ISSUE.DESCRIPTION')}
                className='text-center text-light min-w-175px'
            />
        ),
        id: 'ISSUE.DESCRIPTION',
        Cell: ({ ...props }) => {
            let moTa = props.data[props.row.index].moTa;
            return <TableCustomCell data={!!moTa ? moTa : '-'} />;
        }
    }
];

export { IssueColumn };
