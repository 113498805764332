import { ATTRIBUTE_TYPE_NAME } from "../constants";

export const handleSortListAttributeType = (listAttributeTypes: any[]) => {
    const sortedListAttributeTypes = [
        ATTRIBUTE_TYPE_NAME.THIET_LAP_GIA,
        ATTRIBUTE_TYPE_NAME.DICH_VU_PHU_THEM,
        ATTRIBUTE_TYPE_NAME.HUONG_XA_VAI
    ]
    listAttributeTypes.sort((a, b) => {
        const indexA = sortedListAttributeTypes.indexOf(a.name);
        const indexB = sortedListAttributeTypes.indexOf(b.name);
        return indexA - indexB;
    });
}
