import { FC, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SelectField from "../../component/input-field/SelectField";
import TextField from "../../component/input-field/TextField";
import { ICompainon, IListJoin, IWelfare } from "./../model/WelfareModel";
import "../welfare.scss";
import { TableCustom } from "../../component/table-custom/TableCustom";
import { ListJoinColumn } from "./ListJoinColumn";
import { dikemArray, thamGiaArray } from "../const/WelfareConst";
import { ListCompanionColumn } from "./ListCompanionColumn";
interface IProps {
  handleCLose: () => void;
  joinInfo: IListJoin;
}
const ListJoinDialog: FC<IProps> = (props) => {
  const { handleCLose, joinInfo } = props;
  const intl = useIntl();
  const validationSchema = Yup.object().shape({
    hovaten: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
  });
  const [openCompanionDialog, setOpenCompanionDialog] = useState<boolean>(false);
  const [companionInfo, setCompanionInfo] = useState<ICompainon>({} as ICompainon);
  const handleOpenCompanionDialog = (info: any) => {
      setOpenCompanionDialog(true);
    setCompanionInfo(info.original);
  };
  const handleCloseCompanionDialog = () => {
    setOpenCompanionDialog(false);
    setCompanionInfo({} as ICompainon);
  };
  return (
    <>
      <Formik
        initialValues={joinInfo}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => {
          console.log(values);
          return (
            <Modal show={true} onHide={handleCLose} size="xl" centered>
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {intl.formatMessage({
                      id: !joinInfo?.hoVaTen ? "LISTJOIN.ADD" : "LISTJOIN.EDIT",
                    })}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="pt-4">
                    <Col xs={4}>
                      <TextField
                        isView={false}
                        name="hoVaTen"
                        label="FULL.NAME"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.hoVaTen || ""}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        isView={false}
                        name="maNhanVien"
                        label="EMPLOYEE.ID"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.maNhanVien || ""}
                      />
                    </Col>
                    <Col xs={4}>
                      <SelectField
                        name="donViApDung"
                        label="APPLIED.UNIT"
                        handleChange={handleChange}
                        value={values.donViApDung || ""}
                        Option={[]}
                      />
                    </Col>
                  </Row>
                  <Row className=" pt-8">
                    <Col xs={4}>
                      <TextField
                        isView={false}
                        name="viTriCongViec"
                        label="JOB.POSITION"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.viTriCongViec || ""}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="soTienNhanVienDong"
                        label="EMPLOYEE.CONTRIBUTION"
                        type={"text"}
                        handleChange={handleChange}
                        value={values.soTienNhanVienDong || ""}
                        isView={false}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextField
                        name="soTienCongTyHoTro"
                        label="COMPANY.SUPPORT"
                        type="text"
                        handleChange={handleChange}
                        value={values.soTienCongTyHoTro || ""}
                        isView={false}
                      />
                    </Col>
                  </Row>
                  <Row className=" pt-8">
                    <Col xs={4}>
                      <SelectField
                        name="trangThai"
                        label="STATUS"
                        Option={[]}
                        handleChange={handleChange}
                        value={values.trangThai || ""}
                      />
                    </Col>
                  </Row>
                  <Row className="pt-8">
                    <Col xs={12} className="cursor-pointer py-4">
                      <span className="fs-3 fw-bold">
                        {intl.formatMessage({ id: "LIST.COMPANION" })}
                      </span>
                      <i
                        className="bi bi-plus-circle-fill fs-4 px-2  text-primary"
                        onClick={() => {
                          setOpenCompanionDialog(true);
                        }}
                      ></i>
                    </Col>
                    {openCompanionDialog && (
                      <Col xs={12}>
                        <Row>
                          <Col xs={4}>
                            <TextField
                              isView={false}
                              name="hoVaTen"
                              label="FULL.NAME"
                              type={"text"}
                              handleChange={handleChange}
                              value={companionInfo.hoVaTen || ""}
                            />
                          </Col>
                          <Col xs={4}>
                            <TextField
                              isView={false}
                              name="quanHe"
                              label="COMPANION.RELATION"
                              type={"text"}
                              handleChange={handleChange}
                              value={companionInfo.quanHe || ""}
                            />
                          </Col>
                          <Col xs={4}>
                            <div className="d-flex gap-4 pt-9">
                              <Button
                                className="btn btn-secondary btn-sm "
                                onClick={handleCloseCompanionDialog}
                              >
                                {intl.formatMessage({
                                  id: "BTN.CANCEL",
                                })}
                              </Button>
                              <Button className="btn btn-primary btn-sm " type="submit">
                                {intl.formatMessage({ id: "BTN.SAVE" })}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    )}

                    <Col xs={12}>
                      <TableCustom<ICompainon>
                        columns={ListCompanionColumn}
                        data={dikemArray}
                        compact={false}
                        handleOpenInfoDialog={handleOpenCompanionDialog}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                  <Button className="btn btn-secondary  btn-sm" onClick={handleCLose}>
                    {intl.formatMessage({
                      id: "BTN.CANCEL",
                    })}
                  </Button>
                  <Button className="btn btn-primary btn-sm" type="submit">
                    {intl.formatMessage({ id: "BTN.SAVE" })}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
export default ListJoinDialog;
