import { ErrorMessage, Field, FormikHandlers } from 'formik';
import React from 'react'
import { useIntl } from 'react-intl';
type Props = {
  label: string;
  name: string;
  isView: boolean;
  value: string | number;
  type: string | number;
  handleChange: (() => void) | FormikHandlers["handleChange"] | any
};

const TextField = (props: Props) => {
  const { label, name, isView, value, handleChange, type } = props
  const intl = useIntl();
  return (
    <>
      <div className='text-label'>
        {intl.formatMessage({ id: label })}
      </div>
      <Field
        style={{ height: "35px" }}
        className='form-control'
        name={name}
        type={type}
        readOnly={isView}
        value={value}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: label })}
      />
      <ErrorMessage name={name}>
        {(msg) => <div className='text-danger'>{msg}</div>}
      </ErrorMessage>
    </>
  )
}

export default TextField