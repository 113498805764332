import React, { ChangeEvent, useState } from 'react'
import { Form, Image, Row } from 'react-bootstrap'
import PageLoading from './page-loading/PageLoading';
import { DEFAULT_IMG_URL } from '../danh-sach-san-pham/constants';
import { uploadImageSanPham } from '../danh-sach-san-pham/services/DanhSachSanPhamService';

type Props = {
    id: string;
    widthImg: number;
    heightImg: number;
    onChange: (url: string) => void;
    type?: "rounded" | "roundedCircle" | "thumbnail"
}

const CustomUploadImage = (props: Props) => {
    const [imageUrl, setImageUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        formData.append('uploadFile', e.target?.files?.[0] as Blob);
        setLoading(true);
        uploadImageSanPham(formData)
            .then(res => {
                const url = res?.data?.filePath;
                setImageUrl(url);
                props?.onChange(url);
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Row className={`justify-content-center ${loading ? 'position-relative' : ''}`}>
            <Form.Group className="my-3 text-center">
                <Form.Label
                    htmlFor={props?.id}
                    className='mb-0 text-light cursor-pointer'>
                    {loading ? <div className={`w-${props?.widthImg}px h-${props?.heightImg}px`}>
                        <PageLoading />
                    </div> : <Image
                        className={`w-${props?.widthImg}px h-${props?.heightImg}px`}
                        style={{ objectFit: 'cover' }}
                            src={imageUrl || DEFAULT_IMG_URL}
                        {...{[props?.type ? props?.type : "rounded"]: true}}
                    />}
                </Form.Label>
                <Form.Control type="file" size="sm" id={props?.id} className='d-none'
                    onChange={handleUploadImage}
                />
            </Form.Group>
        </Row>
    )
}

export default CustomUploadImage